import {
  Button,
  Collapse,
  Form,
  Icon,
  Input,
  Row,
  Tooltip,
  notification,
  Modal,
} from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import React, { FC, FormEvent, useEffect, useState } from 'react';
import markdownToTxt from 'markdown-to-txt';
import { IScanLandingListProps } from './ScanLanding';
import { appComponents } from '../components';
import {
  ScanLandingComponentTypesEnum,
  ScanLandingMainComponentTypesEnum,
} from './enums';
import { IRow } from '../app/AppInterfaces';
import { INPUT_MAX_LENGTH } from './constants';
import * as api from '../api';
import ScanLandingMainComponent from './components/ScanLandingMainComponent';
import ScanLandingBlankComponent from './components/ScanLandingBlankComponent';
import ScanLandingImageComponent from './components/ScanLandingImageComponent';
import ScanLandingVideoComponent from './components/ScanLandingVideoComponent';
import ScanLandingReviewComponent from './components/ScanLandingReviewComponent';
import ScanLandingValidationModal from './components/ScanLandingValidationModal';
import ScanLandingImageBannerComponent from './components/ScanLandingImageBannerComponent';
import ScanLandingVideoBannerComponent from './components/ScanLandingVideoBannerComponent';
import ScanLandingConsumablePlatformSelector from './components/ScanLandingConsumablePlatformSelector';
import ScanLandingPreview from './components/ScanLandingPreview';
import apiPaths from '../apiPaths';
import {
  HandleOnChangeFunction,
  HandleValidateMarkdownInputFunction,
} from './components/interface';

export interface ScanLandingApiModel {
  id?: string;
  status: boolean;
  name: string;
  consumablesByPlatform?: Array<{ consumables: number[]; platforms: string[] }>;
  components?: Array<{ type: string; position: number; model: IRow }>;
}
type OnSuccessCallback = (response: any) => void | Promise<void>;
type OnErrorCallback = (error: any) => void;

export type ScanLandingFormProps = FormComponentProps & IScanLandingListProps;

const validateConsumablesByPlatforms = (
  consumablesByPlatform: ScanLandingApiModel['consumablesByPlatform'],
) =>
  (consumablesByPlatform ?? []).reduce((prev: boolean, curr: any) => {
    const platformsLength = curr?.platforms?.length ?? 0;
    const consumablesLength = curr?.consumables?.length ?? 0;
    const bothFilled = platformsLength > 0 && consumablesLength > 0;
    const bothEmpty = platformsLength === 0 && consumablesLength === 0;
    return prev && (bothEmpty || bothFilled);
  }, true);

const transformFormDataToApiModel = (
  apiValues: IRow,
  formValues: IRow,
): ScanLandingApiModel => {
  const payload: ScanLandingApiModel = {
    id: apiValues.id,
    status: apiValues.status,
    name: formValues.name,
    consumablesByPlatform: formValues.consumablesByPlatform?.filter(
      (row: IRow) => row?.platforms?.length > 0 && row?.consumables?.length > 0,
    ),
    components: apiValues.components?.map((valuesComponent: IRow) => {
      const formComponent = formValues.components[valuesComponent.position];
      let model = formComponent?.model ?? valuesComponent.model;
      switch (valuesComponent.type) {
        case ScanLandingComponentTypesEnum.MAIN:
          model = {
            ...model,
            type:
              formValues.components.length > 1
                ? ScanLandingMainComponentTypesEnum.IMAGE
                : ScanLandingMainComponentTypesEnum.PRODUCT,
          };
          break;
      }
      return {
        type: valuesComponent.type,
        position: valuesComponent.position,
        model,
      };
    }),
  };
  return payload;
};

const ScanLandingForm: FC<ScanLandingFormProps> = ({
  intl,
  form,
  token,
  values,
  params,
  targetId,
  disabled,
  formHasChanged,
  platformsCombo,
  consumablesCombo,
  setFormData,
  getTableData,
  setSelectedRow,
  setFormStateFlag,
}) => {
  // Constants
  const { formatMessage } = intl;
  const { getFieldDecorator } = form;
  const isFieldsTouched = form.isFieldsTouched();
  const componentsData =
    values &&
    values['components'] &&
    Array.isArray(values['components']) &&
    values['components'].length
      ? values['components'].sort((a, b) => a.position - b.position)
      : null;

  const [loading, setLoading] = useState(false);

  // Handlers
  const handleAddConsumablePlatformRow = () => {
    const newValues = {
      ...values,
      consumablesByPlatform: [
        ...(values['consumablesByPlatform'] ?? []),
        { consumables: [], platforms: [] },
      ],
    };
    setFormData({ componentId: params.componentId, values: newValues });
  };

  const handleAddVideoByRoleRow = (componentIndex: number, device: string) => {
    const newRowValues = [
      ...(values['components'][componentIndex]?.model[device]?.videoByRoles ??
        []),
      { role: '', url: '' },
    ];
    const newComponentValue = {
      ...values['components'][componentIndex],
      model: {
        ...values['components'][componentIndex].model,
        [device]: {
          ...values['components'][componentIndex].model[device],
          videoByRoles: newRowValues,
        },
      },
    };
    const newComponentsValues = [...values['components']];
    newComponentsValues.splice(componentIndex, 1, newComponentValue);
    const newValues = { ...values, components: newComponentsValues };
    setFormData({ componentId: params.componentId, values: newValues });
  };

  const handleDeleteConsumablePlatformRow = (row: number) => {
    const newRowValues = [...form.getFieldValue('consumablesByPlatform')];
    const deletedRow = newRowValues.splice(row, 1);
    form.setFieldsValue({ consumablesByPlatform: newRowValues });
    const newValues = { ...values, consumablesByPlatform: newRowValues };
    setFormData({ componentId: params.componentId, values: newValues });
    // sets formHasChanged to true when a non-empty row is deleted
    if (!formHasChanged) {
      const deletedRowWithValues = Object.values(deletedRow[0]).some(
        (field: any) => !!field?.length,
      );
      if (deletedRowWithValues) {
        setFormStateFlag({ componentId: targetId, formHasChanged: true });
      }
    }
  };

  const handleDeleteVideoByRoleRow = (
    componentIndex: number,
    device: string,
    row: number,
  ) => {
    const newRowValues = [
      ...form.getFieldValue(
        `components[${componentIndex}].model.${device}.videoByRoles`,
      ),
    ];
    const deletedRow = newRowValues.splice(row, 1);
    const newComponentValue = {
      ...values['components'][componentIndex],
      model: {
        ...values['components'][componentIndex].model,
        [device]: {
          ...values['components'][componentIndex].model[device],
          videoByRoles: newRowValues,
        },
      },
    };
    const newComponentsValues = [...values['components']];
    newComponentsValues.splice(componentIndex, 1, newComponentValue);
    form.setFieldsValue({ components: newComponentsValues });
    const newValues = { ...values, components: newComponentsValues };
    setFormData({ componentId: params.componentId, values: newValues });
    // sets formHasChanged to true when a non-empty row is deleted
    if (!formHasChanged) {
      const deletedRowWithValues = Object.values(deletedRow[0]).some(
        (field) => !!field,
      );
      if (deletedRowWithValues) {
        setFormStateFlag({ componentId: targetId, formHasChanged: true });
      }
    }
  };

  const handleOnChange: HandleOnChangeFunction = (
    fieldName,
    value,
    forceUpdateFormState,
  ) => {
    form.setFieldsValue({ [fieldName]: value });
    if (!formHasChanged && forceUpdateFormState) {
      setFormStateFlag({ componentId: targetId, formHasChanged: true });
    }
  };

  const handleValidateMarkdownInput: HandleValidateMarkdownInputFunction = (
    maxLength,
    value,
  ) => {
    const inputLength = markdownToTxt(value).length;
    return inputLength <= maxLength;
  };

  const handleSuccess: OnSuccessCallback = async (response) => {
    setSelectedRow({
      componentId: targetId,
      selectedRow: response.data,
    });
    setFormStateFlag({ componentId: targetId, formHasChanged: false });
    form.resetFields();
    await getTableData({
      dataPath: appComponents[targetId].path,
      componentId: targetId,
    });
  };

  const handleSubmitApiCall = async (
    payload: ScanLandingApiModel,
    onSuccess?: OnSuccessCallback,
    onError?: OnErrorCallback,
  ) => {
    const callOptions = {
      data: payload,
      dataPath: appComponents[params.componentId].path,
      callConfig: {},
    };
    try {
      let response;
      if (payload.id) {
        response = await api.putDataCall(callOptions);
      } else {
        response = await api.postDataCall(callOptions);
      }
      onSuccess && (await onSuccess(response));
    } catch (error) {
      if (error.response) {
        notification.error({ message: error.response.data.message });
      }
      onError && onError(error);
    }
  };

  const handleValidateApiCall = async ({
    payload,
    onOk,
    onCancel,
  }: {
    payload: ScanLandingApiModel;
    onOk: () => Promise<void>;
    onCancel?: () => void;
  }) => {
    try {
      const callOptions = {
        data: payload,
        dataPath: apiPaths.CONSUMABLES.SCAN_LANDING_VALIDATE,
        callConfig: {},
      };
      const response = await api.postDataCall(callOptions);
      if (response.data?.repeatedConsumablePlatforms?.length) {
        Modal.confirm({
          title: formatMessage({ id: 'scanLanding.validation.modal.title' }),
          content: (
            <ScanLandingValidationModal
              intl={intl}
              repeatedConsumablePlatforms={
                response.data.repeatedConsumablePlatforms
              }
              consumablesCombo={consumablesCombo}
            />
          ),
          okText: formatMessage({
            id: 'scanLanding.validation.modal.ok-button',
          }),
          cancelText: formatMessage({
            id: 'scanLanding.validation.modal.cancel-button',
          }),
          onOk,
          onCancel,
          maskClosable: true,
          width: '75%',
        });
      } else {
        await onOk();
      }
    } catch (error) {
      if (error.response) {
        notification.error({ message: error.response.data.message });
      } else {
        notification.error({ message: error.message });
      }
    }
  };

  const handleValidateFields = async (errors: any, formValues: IRow) => {
    const areConsumablesByPlatformValuesValid = validateConsumablesByPlatforms(
      formValues.consumablesByPlatform,
    );
    if (!errors && areConsumablesByPlatformValuesValid) {
      const payload = transformFormDataToApiModel(values, formValues);
      await handleValidateApiCall({
        payload,
        onOk: async () => await handleSubmitApiCall(payload, handleSuccess),
      });
    } else {
      notification.error({
        message: formatMessage({ id: 'ScanLanding.form.validation-error' }),
      });
    }
  };

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    if (disabled) return;
    setLoading(true);
    form.validateFieldsAndScroll(handleValidateFields);
    setLoading(false);
  };

  const scanLandingHasVideoBannerComponent = !!componentsData?.find(
    (c) => c.type === ScanLandingComponentTypesEnum.VIDEO_BANNER,
  );

  // Side effects
  useEffect(() => {
    if (isFieldsTouched) {
      setFormStateFlag({ componentId: targetId, formHasChanged: true });
    }
  }, [isFieldsTouched, targetId, setFormStateFlag]);

  return (
    <Form
      form={form}
      onSubmit={handleSubmit}
      layout="vertical"
      style={{ margin: '0 16px' }}>
      <Row type="flex" justify="start" style={{ marginBottom: '24px' }}>
        <Button
          htmlType="submit"
          type="primary"
          icon="save"
          loading={loading}
          disabled={disabled}>
          {formatMessage({ id: 'scanLanding.form.save' })}
        </Button>
        {values?.consumablesByPlatform ? (
          <ScanLandingPreview
            platformsConsumablesSelected={values.consumablesByPlatform}
            platformsCombo={platformsCombo}
            consumablesCombo={consumablesCombo}
            disabled={formHasChanged}
            hasRole={scanLandingHasVideoBannerComponent}
          />
        ) : null}
      </Row>
      <Form.Item
        label={formatMessage({ id: 'scanLanding.name' })}
        wrapperCol={{
          xs: { span: 24 },
          sm: { span: 11 },
        }}>
        {getFieldDecorator('name', {
          initialValue: values ? values['name'] : '',
          rules: [
            {
              required: true,
              message: formatMessage({
                id: 'scanLanding.name.required-error',
              }),
            },
          ],
        })(
          <Input
            maxLength={INPUT_MAX_LENGTH}
            disabled={disabled}
            placeholder={formatMessage({
              id: 'scanLanding.name.placeholder',
            })}
          />,
        )}
      </Form.Item>
      <ScanLandingConsumablePlatformSelector
        form={form}
        disabled={disabled}
        values={
          values && Array.isArray(values['consumablesByPlatform'])
            ? values['consumablesByPlatform']
            : []
        }
        platformsCombo={platformsCombo}
        consumablesCombo={consumablesCombo}
        handleAddRow={handleAddConsumablePlatformRow}
        handleRemoveRow={handleDeleteConsumablePlatformRow}
      />
      {componentsData ? (
        <Collapse
          style={{
            fontSize: '18px',
            backgroundColor: '#f0efef',
            marginBottom: '16px',
          }}
          activeKey={componentsData.map((component) => component.position)}>
          {componentsData.map(({ type, position, model }) => {
            const commonProps = {
              form,
              token,
              disabled,
              values: model,
              index: position,
              handleOnChange,
              handleValidateMarkdownInput,
            };
            const commonPanelProps = {
              key: position,
              showArrow: false,
              header: formatMessage({
                id: `scanLanding.component.${type}.header`,
              }),
            };
            switch (type) {
              case ScanLandingComponentTypesEnum.MAIN:
                return (
                  <Collapse.Panel {...commonPanelProps}>
                    <ScanLandingMainComponent {...commonProps} />
                  </Collapse.Panel>
                );
              case ScanLandingComponentTypesEnum.IMAGE:
                return (
                  <Collapse.Panel {...commonPanelProps}>
                    <ScanLandingImageComponent {...commonProps} />
                  </Collapse.Panel>
                );
              case ScanLandingComponentTypesEnum.IMAGE_BANNER:
                return (
                  <Collapse.Panel
                    {...commonPanelProps}
                    header={
                      <span
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: '16px',
                        }}>
                        {formatMessage({
                          id: `scanLanding.component.image-banner.header`,
                        })}
                        <Tooltip
                          title={formatMessage({
                            id: 'scanLanding.component.image-banner.header.tooltip',
                          })}
                          placement="right">
                          <Icon type="question-circle" />
                        </Tooltip>
                      </span>
                    }>
                    <ScanLandingImageBannerComponent {...commonProps} />
                  </Collapse.Panel>
                );
              case ScanLandingComponentTypesEnum.VIDEO:
                return (
                  <Collapse.Panel {...commonPanelProps}>
                    <ScanLandingVideoComponent {...commonProps} />
                  </Collapse.Panel>
                );
              case ScanLandingComponentTypesEnum.VIDEO_BANNER:
                return (
                  <Collapse.Panel {...commonPanelProps}>
                    <ScanLandingVideoBannerComponent
                      {...{
                        ...commonProps,
                        handleAddVideoByRoleRow,
                        handleDeleteVideoByRoleRow,
                      }}
                    />
                  </Collapse.Panel>
                );
              case ScanLandingComponentTypesEnum.REVIEW:
                return (
                  <Collapse.Panel {...commonPanelProps}>
                    <ScanLandingReviewComponent {...commonProps} />
                  </Collapse.Panel>
                );
              case ScanLandingComponentTypesEnum.RESULTS:
              case ScanLandingComponentTypesEnum.HOW_TO_USE:
                return (
                  <Collapse.Panel {...commonPanelProps}>
                    <ScanLandingBlankComponent
                      explanation={formatMessage({
                        id: `scanLanding.component.${type}.explanation`,
                      })}
                    />
                  </Collapse.Panel>
                );
              default:
                return null;
            }
          })}
        </Collapse>
      ) : null}
    </Form>
  );
};

export default Form.create<ScanLandingFormProps>({ name: 'scan_landing_form' })(
  ScanLandingForm,
);
