import React, { FC } from 'react';
import {
  ScanLandingComponentProps,
  ScanLandingComponentUploadProps,
} from './interface';
import { useIntl } from 'react-intl';
import { Collapse, Form, Input } from 'antd';
import ScanLandingMarkdownInput from './ScanLandingMarkdown';
import UploadComponent from './UploadComponent';
import { INPUT_MAX_LENGTH } from '../constants';
import ScanLandingLabelWithTooltip from './ScanLandingLabelWithTooltip';

interface ImageProps {
  url: string;
}
export interface ScanLandingImageBannerComponentModel {
  title?: string;
  description?: string;
  references?: string;
  desktop?: ImageProps;
  tablet?: ImageProps;
  mobile?: ImageProps;
}

type DeviceOptions = Array<
  keyof Pick<
    ScanLandingImageBannerComponentModel,
    'mobile' | 'tablet' | 'desktop'
  >
>;
const DEVICE_OPTIONS: DeviceOptions = ['mobile', 'tablet', 'desktop'];
const REQUIRED_DEVICES: DeviceOptions = ['mobile'];

const DESCRIPTION_MAX_LENGTH = 500;

const ScanLandingImageBannerComponent: FC<
  ScanLandingComponentProps<ScanLandingImageBannerComponentModel> &
    ScanLandingComponentUploadProps
> = ({
  index,
  form,
  values,
  token,
  disabled,
  handleOnChange,
  handleValidateMarkdownInput,
}) => {
  const { formatMessage } = useIntl();
  const { getFieldDecorator } = form;

  return (
    <div>
      <Form.Item
        label={formatMessage({
          id: 'scanLanding.component.image-banner.title',
        })}
        wrapperCol={{
          xs: { span: 24 },
          sm: { span: 12 },
        }}>
        {getFieldDecorator(`components[${index}].model.title`, {
          initialValue: values.title ?? '',
        })(
          <Input
            maxLength={INPUT_MAX_LENGTH}
            disabled={disabled}
            placeholder={formatMessage({
              id: 'scanLanding.component.image-banner.title.placeholder',
            })}
          />,
        )}
      </Form.Item>
      <Form.Item
        label={
          <ScanLandingLabelWithTooltip
            label={formatMessage({
              id: 'scanLanding.component.image-banner.description',
            })}
            tooltipTitle={formatMessage(
              {
                id: 'scanLanding.component.image-banner.description.tooltip',
              },
              { maxLength: DESCRIPTION_MAX_LENGTH },
            )}
          />
        }>
        {getFieldDecorator(`components[${index}].model.description`, {
          initialValue: values.description ?? '',
          rules: [
            {
              validator: (_: any, value: string) =>
                handleValidateMarkdownInput(DESCRIPTION_MAX_LENGTH, value),
              message: formatMessage({
                id: 'scanLanding.component.image-banner.description.max-length-error',
              }),
            },
          ],
        })(
          <ScanLandingMarkdownInput
            disabled={disabled}
            error={
              !!form.getFieldError(`components[${index}].model.description`)
                ?.length
            }
            value={form.getFieldValue(`components[${index}].model.description`)}
            onChange={(value) => {
              handleOnChange(`components[${index}].model.description`, value);
            }}
          />,
        )}
      </Form.Item>
      <Form.Item
        label={
          <ScanLandingLabelWithTooltip
            label={formatMessage({
              id: 'scanLanding.component.image-banner.references',
            })}
            tooltipTitle={formatMessage({
              id: 'scanLanding.component.image-banner.references.tooltip',
            })}
          />
        }>
        {getFieldDecorator(`components[${index}].model.references`, {
          initialValue: values.references ?? '',
        })(
          <ScanLandingMarkdownInput
            disabled={disabled}
            error={
              !!form.getFieldError(`components[${index}].model.references`)
                ?.length
            }
            value={form.getFieldValue(`components[${index}].model.references`)}
            onChange={(value) => {
              handleOnChange(`components[${index}].model.references`, value);
            }}
          />,
        )}
      </Form.Item>
      <Collapse
        bordered={false}
        style={{
          fontSize: '16px',
          backgroundColor: '#f7f7f7',
          marginTop: '8px',
        }}
        defaultActiveKey={REQUIRED_DEVICES.map((device) => `image.${device}`)}>
        {DEVICE_OPTIONS.map((device) => (
          <Collapse.Panel
            key={`image.${device}`}
            showArrow={false}
            header={formatMessage({
              id: `scanLanding.component.image-banner.${device}`,
            })}>
            <Form.Item
              label={formatMessage({
                id: `scanLanding.component.image-banner.url`,
              })}
              wrapperCol={{
                xs: { span: 24 },
                sm: { span: 12 },
              }}>
              {getFieldDecorator(`components[${index}].model.${device}.url`, {
                initialValue: values[device]?.url ?? '',
              })(
                <UploadComponent
                  {...{
                    value: values[device]?.url ?? '',
                    setValue: (value) => {
                      handleOnChange(
                        `components[${index}].model.${device}.url`,
                        value,
                        true,
                      );
                    },
                    fieldName: `components[${index}].model.${device}.url`,
                    token,
                    disabled,
                    format: 'image',
                  }}
                />,
              )}
            </Form.Item>
          </Collapse.Panel>
        ))}
      </Collapse>
    </div>
  );
};

export default ScanLandingImageBannerComponent;
