import React, { FC } from 'react';
import {
  ScanLandingComponentProps,
  ScanLandingComponentUploadProps,
} from './interface';
import { useIntl } from 'react-intl';
import {
  Button,
  Checkbox,
  Col,
  Collapse,
  Form,
  Icon,
  Input,
  Row,
  Select,
  Tooltip,
} from 'antd';
import { CheckboxProps } from 'antd/lib/checkbox';
import ScanLandingMarkdownInput from './ScanLandingMarkdown';
import UploadComponent from './UploadComponent';
import {
  UPLOAD_ACTION_VIDEO_MAIN,
  UPLOAD_ACTION_YOUTUBE_URL_MAIN,
} from '../../shared';
import { WrappedFormUtils } from 'antd/lib/form/Form';
import { IRow } from '../../app/AppInterfaces';
import { FormItemProps } from 'antd/lib/form';
import { INPUT_MAX_LENGTH, ScanLandingRoleEnum } from '../constants';
import ScanLandingLabelWithTooltip from './ScanLandingLabelWithTooltip';

type ScanLandingRoleEnum = keyof typeof ScanLandingRoleEnum;
const ScanLandingVideoBannerRoleOptions = Object.values(ScanLandingRoleEnum);

interface VideoByRoles {
  role: ScanLandingRoleEnum;
  url: string;
  thumbnail?: string;
}
interface VideoProps {
  url: string;
  vertical?: boolean;
  thumbnail?: string;
  videoByRoles: Array<VideoByRoles>;
}
export interface ScanLandingVideoBannerComponentModel {
  title: string;
  description: string;
  references?: string;
  autoplay?: boolean;
  controls?: boolean;
  mobile: VideoProps;
  tablet?: VideoProps;
  desktop?: VideoProps;
}

interface ScanLandingVideoBannerComponentAdditionalProps {
  handleAddVideoByRoleRow: (index: number, device: string) => void;
  handleDeleteVideoByRoleRow: (
    componentIndex: number,
    device: string,
    row: number,
  ) => void;
}

type DeviceOption = keyof Pick<
  ScanLandingVideoBannerComponentModel,
  'mobile' | 'tablet' | 'desktop'
>;
const DEVICE_OPTIONS: DeviceOption[] = ['mobile', 'tablet', 'desktop'];
const REQUIRED_DEVICES: DeviceOption[] = ['mobile'];

const DESCRIPTION_MAX_LENGTH = 500;

const StyledCheckbox = ({ children, ...restProps }: CheckboxProps) => (
  <Checkbox {...restProps} style={{ fontSize: '16px' }}>
    {children}
  </Checkbox>
);

const isFieldRequired = ({
  form,
  values,
  index,
  device,
  row,
  fieldName,
  errorMessage,
}: {
  form: WrappedFormUtils<any>;
  values: IRow;
  index: number;
  device: DeviceOption;
  row: number;
  fieldName: keyof Pick<VideoByRoles, 'role' | 'url'>;
  errorMessage: string;
}): Pick<FormItemProps, 'required' | 'validateStatus' | 'help'> => {
  const roleSelected = Boolean(
    form.getFieldValue(
      `components[${index}].model.${device}.videoByRoles[${row}].role`,
    ) ??
      (values && values[device]?.videoByRoles[row]['role']),
  );
  const urlFilled = Boolean(
    form.getFieldValue(
      `components[${index}].model.${device}.videoByRoles[${row}].url`,
    ) ??
      (values && values[device]?.videoByRoles[row]['url']),
  );

  if (
    (fieldName === 'role' && roleSelected) ||
    (fieldName === 'url' && urlFilled)
  ) {
    return { required: true };
  } else if (roleSelected || urlFilled) {
    return {
      required: true,
      validateStatus: 'error',
      help: errorMessage,
    };
  } else {
    return { required: false };
  }
};

const ScanLandingVideoBannerComponent: FC<
  ScanLandingComponentProps<ScanLandingVideoBannerComponentModel> &
    ScanLandingComponentUploadProps &
    ScanLandingVideoBannerComponentAdditionalProps
> = ({
  index,
  form,
  values,
  token,
  disabled,
  handleOnChange,
  handleAddVideoByRoleRow,
  handleDeleteVideoByRoleRow,
  handleValidateMarkdownInput,
}) => {
  const { formatMessage } = useIntl();
  const { getFieldDecorator } = form;

  const filteredRolesOptions = new Map<DeviceOption, string[]>();
  DEVICE_OPTIONS.forEach((device) => {
    const rolesSelected =
      form
        .getFieldValue(`components[${index}].model.${device}.videoByRoles`)
        ?.map((videoByRole: VideoByRoles) => videoByRole?.role) ?? [];
    filteredRolesOptions.set(
      device,
      ScanLandingVideoBannerRoleOptions.filter(
        (role) => !rolesSelected.includes(role),
      ),
    );
  });

  return (
    <div>
      <Form.Item
        label={formatMessage({
          id: 'scanLanding.component.video-banner.title',
        })}
        wrapperCol={{
          xs: { span: 24 },
          sm: { span: 12 },
        }}>
        {getFieldDecorator(`components[${index}].model.title`, {
          initialValue: values.title ?? '',
          rules: [
            {
              required: true,
              message: formatMessage({
                id: 'scanLanding.component.video-banner.title.required-error',
              }),
            },
          ],
        })(
          <Input
            maxLength={INPUT_MAX_LENGTH}
            disabled={disabled}
            placeholder={formatMessage({
              id: 'scanLanding.component.video-banner.title.placeholder',
            })}
          />,
        )}
      </Form.Item>
      <Form.Item
        label={
          <ScanLandingLabelWithTooltip
            label={formatMessage({
              id: 'scanLanding.component.video-banner.description',
            })}
            tooltipTitle={formatMessage(
              {
                id: 'scanLanding.component.video-banner.description.tooltip',
              },
              { maxLength: DESCRIPTION_MAX_LENGTH },
            )}
          />
        }>
        {getFieldDecorator(`components[${index}].model.description`, {
          initialValue: values.description ?? '',
          rules: [
            {
              required: true,
              message: formatMessage({
                id: 'scanLanding.component.video-banner.description.required-error',
              }),
            },
            {
              validator: (_: any, value: string) =>
                handleValidateMarkdownInput(DESCRIPTION_MAX_LENGTH, value),
              message: formatMessage({
                id: 'scanLanding.component.video-banner.description.max-length-error',
              }),
            },
          ],
        })(
          <ScanLandingMarkdownInput
            disabled={disabled}
            error={
              !!form.getFieldError(`components[${index}].model.description`)
                ?.length
            }
            value={form.getFieldValue(`components[${index}].model.description`)}
            onChange={(value) => {
              handleOnChange(`components[${index}].model.description`, value);
            }}
          />,
        )}
      </Form.Item>
      <Form.Item
        label={
          <ScanLandingLabelWithTooltip
            label={formatMessage({
              id: 'scanLanding.component.video-banner.references',
            })}
            tooltipTitle={formatMessage({
              id: 'scanLanding.component.video-banner.references.tooltip',
            })}
          />
        }>
        {getFieldDecorator(`components[${index}].model.references`, {
          initialValue: values.references ?? '',
        })(
          <ScanLandingMarkdownInput
            disabled={disabled}
            error={
              !!form.getFieldError(`components[${index}].model.references`)
                ?.length
            }
            value={form.getFieldValue(`components[${index}].model.references`)}
            onChange={(value) => {
              handleOnChange(`components[${index}].model.references`, value);
            }}
          />,
        )}
      </Form.Item>
      <Row gutter={32} style={{ paddingBottom: '8px', paddingTop: '8px' }}>
        <Col span={6}>
          {getFieldDecorator(`components[${index}].model.autoplay`, {
            initialValue: values.autoplay,
          })(
            <StyledCheckbox
              defaultChecked={values.autoplay}
              disabled={disabled}>
              <Tooltip
                title={formatMessage({
                  id: 'scanLanding.component.video-banner.autoplay.tooltip',
                })}
                placement="bottomLeft">
                {formatMessage({
                  id: 'scanLanding.component.video-banner.autoplay',
                })}
              </Tooltip>
            </StyledCheckbox>,
          )}
        </Col>
        <Col span={6}>
          {getFieldDecorator(`components[${index}].model.controls`, {
            initialValue: values.controls,
          })(
            <StyledCheckbox
              defaultChecked={values.controls}
              disabled={disabled}>
              <Tooltip
                title={formatMessage({
                  id: 'scanLanding.component.video-banner.controls.tooltip',
                })}
                placement="bottomLeft">
                {formatMessage({
                  id: 'scanLanding.component.video-banner.controls',
                })}
              </Tooltip>
            </StyledCheckbox>,
          )}
        </Col>
      </Row>
      <Collapse
        bordered={false}
        style={{
          fontSize: '16px',
          backgroundColor: '#f7f7f7',
          marginTop: '8px',
        }}
        defaultActiveKey={REQUIRED_DEVICES.map(
          (device) => `video-banner.${device}`,
        )}>
        {DEVICE_OPTIONS.map((device) => (
          <Collapse.Panel
            key={`video-banner.${device}`}
            showArrow={false}
            header={formatMessage({
              id: `scanLanding.component.video-banner.${device}`,
            })}>
            <Row style={{ paddingBottom: '16px' }}>
              <Col span={6}>
                {getFieldDecorator(
                  `components[${index}].model.${device}.vertical`,
                  { initialValue: values[device]?.vertical },
                )(
                  <StyledCheckbox
                    defaultChecked={values[device]?.vertical}
                    disabled={disabled}>
                    <Tooltip
                      title={formatMessage({
                        id: 'scanLanding.component.video-banner.vertical.tooltip',
                      })}
                      placement="right">
                      {formatMessage({
                        id: `scanLanding.component.video-banner.vertical`,
                      })}
                    </Tooltip>
                  </StyledCheckbox>,
                )}
              </Col>
            </Row>
            <Row gutter={32}>
              <Col sm={24} md={12}>
                <Form.Item
                  label={formatMessage({
                    id: `scanLanding.component.video-banner.url`,
                  })}>
                  {getFieldDecorator(
                    `components[${index}].model.${device}.url`,
                    {
                      initialValue: values[device]?.url ?? '',
                      rules: [
                        {
                          required: REQUIRED_DEVICES.includes(device),
                          message: formatMessage({
                            id: `scanLanding.component.video-banner.url.required-error`,
                          }),
                        },
                      ],
                    },
                  )(
                    <UploadComponent
                      {...{
                        value: values[device]?.url ?? '',
                        setValue: (value) => {
                          handleOnChange(
                            `components[${index}].model.${device}.url`,
                            value,
                            true,
                          );
                        },
                        fieldName: `components[${index}].model.${device}.url`,
                        token,
                        disabled,
                        format: 'video',
                        uploadActions: [
                          UPLOAD_ACTION_VIDEO_MAIN,
                          UPLOAD_ACTION_YOUTUBE_URL_MAIN,
                        ],
                      }}
                    />,
                  )}
                </Form.Item>
              </Col>
              <Col sm={24} md={12}>
                <Form.Item
                  label={formatMessage({
                    id: `scanLanding.component.video-banner.thumbnail`,
                  })}>
                  {getFieldDecorator(
                    `components[${index}].model.${device}.thumbnail`,
                    {
                      initialValue: values[device]?.thumbnail ?? '',
                    },
                  )(
                    <UploadComponent
                      {...{
                        value: values[device]?.thumbnail ?? '',
                        setValue: (value) => {
                          handleOnChange(
                            `components[${index}].model.${device}.thumbnail`,
                            value,
                            true,
                          );
                        },
                        fieldName: `components[${index}].model.${device}.thumbnail`,
                        token,
                        disabled,
                        format: 'image',
                      }}
                    />,
                  )}
                </Form.Item>
              </Col>
            </Row>
            <Collapse
              bordered={false}
              style={{
                fontSize: '16px',
                backgroundColor: '#efefef',
                marginTop: '8px',
              }}
              defaultActiveKey={
                values[device]?.videoByRoles?.length
                  ? `video-banner.${device}.videoByRoles`
                  : undefined
              }>
              <Collapse.Panel
                key={`video-banner.${device}.videoByRoles`}
                showArrow={false}
                header={formatMessage({
                  id: `scanLanding.component.video-banner.videoByRoles`,
                })}>
                {disabled ? null : (
                  <Row
                    gutter={16}
                    type="flex"
                    align="middle"
                    justify="start"
                    style={{ marginBottom: '8px' }}>
                    <Col>
                      <Button
                        disabled={
                          (values[device]?.videoByRoles?.length ?? 0) >=
                          ScanLandingVideoBannerRoleOptions.length
                        }
                        onClick={() => handleAddVideoByRoleRow(index, device)}>
                        <Icon type="plus" />
                      </Button>
                    </Col>
                    <Col>
                      <Tooltip
                        title={formatMessage({
                          id: 'scanLanding.component.video-banner.videoByRoles.add-button.tooltip',
                        })}
                        placement="right">
                        <Icon type="question-circle" />
                      </Tooltip>
                    </Col>
                  </Row>
                )}
                {values[device]?.videoByRoles?.map((videoByRole, row) => {
                  const commonProps = { form, values, index, device, row };
                  const roleFieldRequiredConfig = isFieldRequired({
                    ...commonProps,
                    fieldName: 'role',
                    errorMessage: formatMessage({
                      id: 'scanLanding.component.video-banner.videoByRoles.role.required-error',
                    }),
                  });
                  const urlFieldRequiredConfig = isFieldRequired({
                    ...commonProps,
                    fieldName: 'url',
                    errorMessage: formatMessage({
                      id: 'scanLanding.component.video-banner.videoByRoles.url.required-error',
                    }),
                  });
                  return (
                    <Row key={row} type="flex" justify="space-between">
                      <Col sm={24} md={22}>
                        <Row gutter={32}>
                          <Col sm={24} md={4}>
                            <Form.Item
                              label={formatMessage({
                                id: 'scanLanding.component.video-banner.videoByRoles.role',
                              })}
                              validateStatus={
                                roleFieldRequiredConfig.validateStatus
                              }
                              help={roleFieldRequiredConfig.help}>
                              {getFieldDecorator(
                                `components[${index}].model.${device}.videoByRoles[${row}].role`,
                                {
                                  initialValue: values
                                    ? videoByRole.role
                                    : null,
                                  rules: [
                                    {
                                      required:
                                        roleFieldRequiredConfig.required,
                                    },
                                  ],
                                },
                              )(
                                <Select
                                  key={`${row}-role-selector`}
                                  style={{ width: '100%' }}
                                  allowClear
                                  showArrow
                                  disabled={disabled}
                                  placeholder={formatMessage({
                                    id: 'scanLanding.component.video-banner.videoByRoles.role.placeholder',
                                  })}>
                                  {filteredRolesOptions
                                    .get(device)
                                    ?.map((role) => (
                                      <Select.Option key={role}>
                                        {role}
                                      </Select.Option>
                                    ))}
                                </Select>,
                              )}
                            </Form.Item>
                          </Col>
                          <Col sm={24} md={10}>
                            <Form.Item
                              label={formatMessage({
                                id: 'scanLanding.component.video-banner.videoByRoles.url',
                              })}
                              validateStatus={
                                urlFieldRequiredConfig.validateStatus
                              }
                              help={urlFieldRequiredConfig.help}>
                              {getFieldDecorator(
                                `components[${index}].model.${device}.videoByRoles[${row}].url`,
                                {
                                  initialValue: values ? videoByRole.url : '',
                                  rules: [
                                    {
                                      required: urlFieldRequiredConfig.required,
                                    },
                                  ],
                                },
                              )(
                                <UploadComponent
                                  {...{
                                    value: videoByRole?.url ?? '',
                                    setValue: (value) => {
                                      handleOnChange(
                                        `components[${index}].model.${device}.videoByRoles[${row}].url`,
                                        value,
                                        true,
                                      );
                                    },
                                    fieldName: `components[${index}].model.${device}.videoByRoles[${row}].url`,
                                    token,
                                    disabled,
                                    format: 'video',
                                    uploadActions: [
                                      UPLOAD_ACTION_VIDEO_MAIN,
                                      UPLOAD_ACTION_YOUTUBE_URL_MAIN,
                                    ],
                                    smallPreviewButton: true,
                                    initialUploadButtonSpan: videoByRole?.url
                                      ? 23
                                      : 24,
                                  }}
                                />,
                              )}
                            </Form.Item>
                          </Col>
                          <Col sm={24} md={10}>
                            <Form.Item
                              label={formatMessage({
                                id: `scanLanding.component.video-banner.videoByRoles.thumbnail`,
                              })}>
                              {getFieldDecorator(
                                `components[${index}].model.${device}.videoByRoles[${row}].thumbnail`,
                                {
                                  initialValue: videoByRole.thumbnail ?? '',
                                },
                              )(
                                <UploadComponent
                                  {...{
                                    value: videoByRole.thumbnail ?? '',
                                    setValue: (value) => {
                                      handleOnChange(
                                        `components[${index}].model.${device}.videoByRoles[${row}].thumbnail`,
                                        value,
                                        true,
                                      );
                                    },
                                    fieldName: `components[${index}].model.${device}.videoByRoles[${row}].thumbnail`,
                                    token,
                                    disabled,
                                    format: 'image',
                                    smallPreviewButton: true,
                                    initialUploadButtonSpan:
                                      videoByRole?.thumbnail ? 23 : 24,
                                  }}
                                />,
                              )}
                            </Form.Item>
                          </Col>
                        </Row>
                      </Col>
                      {disabled ? null : (
                        <Button
                          onClick={() =>
                            handleDeleteVideoByRoleRow(index, device, row)
                          }
                          style={{ marginTop: '32px' }}>
                          <Icon type="delete" />
                        </Button>
                      )}
                    </Row>
                  );
                })}
              </Collapse.Panel>
            </Collapse>
          </Collapse.Panel>
        ))}
      </Collapse>
    </div>
  );
};

export default ScanLandingVideoBannerComponent;
