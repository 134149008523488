import apiPaths from './apiPaths';

export const appComponents: any = {
  // FIXME: BORRAR category
  categorySearch: {
    type: 'search',
    path: '/category',
    targetId: 'categoryList',
  },
  categoryList: {
    type: 'table',
    path: '/category',
    targetsId: [],
  },
  categoryEdit: {
    type: 'edit',
    path: '/category',
    targetId: 'categoryList',
  },
  centercenterSearch: {
    type: 'search',
    path: '/center',
    targetId: 'centercenterList',
  },
  centercenterList: {
    type: 'table',
    path: '/center',
    targetsId: [],
  },
  centercenterEdit: {
    type: 'edit',
    path: '/center',
    targetId: 'centercenterList',
  },
  challengechallengeSearch: {
    type: 'search',
    path: '/challenge',
    targetId: 'challengechallengeList',
  },
  challengechallengeList: {
    type: 'table',
    path: '/challenge',
    targetsId: [
      { id: 'challengechallengeI18nList', path: 'idParent.idChallenge' },
      { id: 'challengechallengeL10nList', path: 'idParent.idChallenge' },
    ],
  },
  challengechallengeEdit: {
    type: 'edit',
    path: '/challenge',
    targetId: 'challengechallengeList',
  },
  challengechallengeI18nList: {
    type: 'table',
    path: '/challengei18n',
    targetsId: [],
  },
  challengechallengeL10nList: {
    type: 'table',
    path: '/challengel10n',
    targetsId: [],
  },
  challengeI18nchallengeI18nSearch: {
    type: 'search',
    path: '/challengei18n',
    targetId: 'challengeI18nchallengeI18nList',
  },
  challengeI18nchallengeI18nList: {
    type: 'table',
    path: '/challengei18n',
    targetsId: [],
  },
  challengeI18nchallengeI18nEdit: {
    type: 'edit',
    path: '/challengei18n',
    targetId: 'challengeI18nchallengeI18nList',
  },
  challengeL10nchallengeL10nSearch: {
    type: 'search',
    path: '/challengel10n',
    targetId: 'challengeL10nchallengeL10nList',
  },
  challengeL10nchallengeL10nList: {
    type: 'table',
    path: '/challengel10n',
    targetsId: [],
  },
  challengeL10nchallengeL10nEdit: {
    type: 'edit',
    path: '/challengel10n',
    targetId: 'challengeL10nchallengeL10nList',
  },
  challengeResourceTypechallengeResourceTypeSearch: {
    type: 'search',
    path: '/challengeresourcetype',
    targetId: 'challengeResourceTypechallengeResourceTypeList',
  },
  challengeResourceTypechallengeResourceTypeList: {
    type: 'table',
    path: '/challengeresourcetype',
    targetsId: [],
  },
  challengeResourceTypechallengeResourceTypeEdit: {
    type: 'edit',
    path: '/challengeresourcetype',
    targetId: 'challengeResourceTypechallengeResourceTypeList',
  },
  challengeRestrictionchallengeRestrictionSearch: {
    type: 'search',
    path: '/challengerestriction',
    targetId: 'challengeRestrictionchallengeRestrictionList',
  },
  challengeRestrictionchallengeRestrictionList: {
    type: 'table',
    path: '/challengerestriction',
    targetsId: [],
  },
  challengeRestrictionchallengeRestrictionEdit: {
    type: 'edit',
    path: '/challengerestriction',
    targetId: 'challengeRestrictionchallengeRestrictionList',
  },
  challengeTypechallengeTypeSearch: {
    type: 'search',
    path: '/challengetype',
    targetId: 'challengeTypechallengeTypeList',
  },
  challengeTypechallengeTypeList: {
    type: 'table',
    path: '/challengetype',
    targetsId: [
      {
        id: 'challengetypeResourcetypeList',
        path: 'idChallengeType.idChallengeType',
      },
      {
        id: 'challengeTypechallengeList',
        path: 'idChallengeType.idChallengeType',
      },
      {
        id: 'challengeTypechallengeTypeI18nList',
        path: 'idParent.idChallengeType',
      },
    ],
  },
  challengeTypechallengeTypeEdit: {
    type: 'edit',
    path: '/challengetype',
    targetId: 'challengeTypechallengeTypeList',
  },
  challengetypeResourcetypeList: {
    type: 'table',
    path: '/resourcetype/challengetypes',
    targetsId: [],
  },
  m2mchallentetyperesourcetypeSearch: {
    type: 'search',
    path: '/challengeResourceType',
    targetId: 'm2mchallentetyperesourcetypeList',
  },
  m2mchallentetyperesourcetypeList: {
    type: 'table',
    path: '/challengeResourceType',
    targetsId: [],
  },
  challengeTypechallengeList: {
    type: 'table',
    path: '/challenge',
    targetsId: [],
  },
  challengeTypechallengeTypeI18nList: {
    type: 'table',
    path: '/challengetypei18n',
    targetsId: [],
  },
  challengeTypeI18nchallengeTypeI18nSearch: {
    type: 'search',
    path: '/challengetypei18n',
    targetId: 'challengeTypeI18nchallengeTypeI18nList',
  },
  challengeTypeI18nchallengeTypeI18nList: {
    type: 'table',
    path: '/challengetypei18n',
    targetsId: [],
  },
  challengeTypeI18nchallengeTypeI18nEdit: {
    type: 'edit',
    path: '/challengetypei18n',
    targetId: 'challengeTypeI18nchallengeTypeI18nList',
  },
  countrycountrySearch: {
    type: 'search',
    path: '/country',
    targetId: 'countrycountryList',
  },
  countrycountryList: {
    type: 'table',
    path: '/country',
    targetsId: [{ id: 'countrylanguagesList', path: 'idCountry.idCountry' }],
  },
  countrycountryEdit: {
    type: 'edit',
    path: '/country',
    targetId: 'countrycountryList',
  },
  countrylanguagesList: {
    type: 'table',
    path: '/languages',
    targetsId: [],
  },
  exceptionPointsCenterexceptionPointsCenterSearch: {
    type: 'search',
    path: '/exceptionpointscenter',
    targetId: 'exceptionPointsCenterexceptionPointsCenterList',
  },
  exceptionPointsCenterexceptionPointsCenterList: {
    type: 'table',
    path: '/exceptionpointscenter',
    targetsId: [],
  },
  exceptionPointsCenterexceptionPointsCenterEdit: {
    type: 'edit',
    path: '/exceptionpointscenter',
    targetId: 'exceptionPointsCenterexceptionPointsCenterList',
  },
  exceptionPointsUsersexceptionPointsUsersSearch: {
    type: 'search',
    path: '/exceptionpointsusers',
    targetId: 'exceptionPointsUsersexceptionPointsUsersList',
  },
  exceptionPointsUsersexceptionPointsUsersList: {
    type: 'table',
    path: '/exceptionpointsusers',
    targetsId: [],
  },
  exceptionPointsUsersexceptionPointsUsersEdit: {
    type: 'edit',
    path: '/exceptionpointsusers',
    targetId: 'exceptionPointsUsersexceptionPointsUsersList',
  },
  languageslanguagesSearch: {
    type: 'search',
    path: '/languages',
    targetId: 'languageslanguagesList',
  },
  languageslanguagesList: {
    type: 'table',
    path: '/languages',
    targetsId: [],
  },
  languageslanguagesEdit: {
    type: 'edit',
    path: '/languages',
    targetId: 'languageslanguagesList',
  },
  menumenuSearch: {
    type: 'search',
    path: '/usersmenu',
    targetId: 'menumenuList',
  },
  menumenuList: {
    type: 'table',
    path: '/usersmenu',
    targetsId: [],
  },
  menumenuEdit: {
    type: 'edit',
    path: '/usersmenu',
    targetId: 'menumenuList',
  },
  permissionspermissionsSearch: {
    type: 'search',
    path: '/permissions',
    targetId: 'permissionspermissionsList',
  },
  permissionspermissionsList: {
    type: 'table',
    path: '/permissions',
    targetsId: [],
  },
  permissionspermissionsEdit: {
    type: 'edit',
    path: '/permissions',
    targetId: 'permissionspermissionsList',
  },
  permissionspermissionsI18nList: {
    type: 'table',
    path: '/permissionsi18n',
    targetsId: [],
  },
  permissionsI18npermissionsI18nSearch: {
    type: 'search',
    path: '/permissionsi18n',
    targetId: 'permissionsI18npermissionsI18nList',
  },
  permissionsI18npermissionsI18nList: {
    type: 'table',
    path: '/permissionsi18n',
    targetsId: [],
  },
  permissionsI18npermissionsI18nEdit: {
    type: 'edit',
    path: '/permissionsi18n',
    targetId: 'permissionsI18npermissionsI18nList',
  },
  resourceresourceSearch: {
    type: 'search',
    path: '/resource',
    targetId: 'resourceresourceList',
  },
  resourceresourceList: {
    type: 'table',
    path: '/resource',
    targetsId: [
      { id: 'resourceresourceDetailList', path: 'idResource.idResource' },
      { id: 'resourceresourceI18nList', path: 'idParent.idResource' },
      { id: 'resourcescoreList', path: 'idResource.idResource' },
    ],
  },
  resourceresourceEdit: {
    type: 'edit',
    path: '/resource',
    targetId: 'resourceresourceList',
  },
  resourceresourceDetailList: {
    type: 'table',
    path: '/resourcedetail',
    targetsId: [],
  },
  resourceresourceI18nList: {
    type: 'table',
    path: '/resourcei18n',
    targetsId: [],
  },
  resourcescoreList: {
    type: 'table',
    path: '/score',
    targetsId: [],
  },
  resourceDI18nresourceDI18nSearch: {
    type: 'search',
    path: '/resourcedi18n',
    targetId: 'resourceDI18nresourceDI18nList',
  },
  resourceDI18nresourceDI18nList: {
    type: 'table',
    path: '/resourcedi18n',
    targetsId: [],
  },
  resourceDI18nresourceDI18nEdit: {
    type: 'edit',
    path: '/resourcedi18n',
    targetId: 'resourceDI18nresourceDI18nList',
  },
  resourceDetailresourceDetailSearch: {
    type: 'search',
    path: '/resourcedetail',
    targetId: 'resourceDetailresourceDetailList',
  },
  resourceDetailresourceDetailList: {
    type: 'table',
    path: '/resourcedetail',
    targetsId: [
      { id: 'resourceDetailresourceDI18nList', path: 'idParent.idResourceD' },
    ],
  },
  resourceDetailresourceDetailEdit: {
    type: 'edit',
    path: '/resourcedetail',
    targetId: 'resourceDetailresourceDetailList',
  },
  resourceDetailresourceDI18nList: {
    type: 'table',
    path: '/resourcedi18n',
    targetsId: [],
  },
  resourceI18nresourceI18nSearch: {
    type: 'search',
    path: '/resourcei18n',
    targetId: 'resourceI18nresourceI18nList',
  },
  resourceI18nresourceI18nList: {
    type: 'table',
    path: '/resourcei18n',
    targetsId: [],
  },
  resourceI18nresourceI18nEdit: {
    type: 'edit',
    path: '/resourcei18n',
    targetId: 'resourceI18nresourceI18nList',
  },
  resourceRestrictionresourceRestrictionSearch: {
    type: 'search',
    path: '/resourcerestriction',
    targetId: 'resourceRestrictionresourceRestrictionList',
  },
  resourceRestrictionresourceRestrictionList: {
    type: 'table',
    path: '/resourcerestriction',
    targetsId: [],
  },
  resourceRestrictionresourceRestrictionEdit: {
    type: 'edit',
    path: '/resourcerestriction',
    targetId: 'resourceRestrictionresourceRestrictionList',
  },
  resourceTyperesourceTypeSearch: {
    type: 'search',
    path: '/resourcetype',
    targetId: 'resourceTyperesourceTypeList',
  },
  resourceTyperesourceTypeList: {
    type: 'table',
    path: '/resourcetype',
    targetsId: [
      {
        id: 'resourceTyperesourceTypeDetailList',
        path: 'idResourceType.idResourceType',
      },
      {
        id: 'resourceTyperesourceTypeI18nList',
        path: 'idParent.idResourceType',
      },
    ],
  },
  resourceTyperesourceTypeEdit: {
    type: 'edit',
    path: '/resourcetype',
    targetId: 'resourceTyperesourceTypeList',
  },
  resourceTyperesourceTypeDetailList: {
    type: 'table',
    path: '/resourcetypedetail',
    targetsId: [],
  },
  resourceTyperesourceTypeI18nList: {
    type: 'table',
    path: '/resourcetypei18n',
    targetsId: [],
  },
  resourceTypeDI18nresourceTypeDI18nSearch: {
    type: 'search',
    path: '/resourcetypedi18n',
    targetId: 'resourceTypeDI18nresourceTypeDI18nList',
  },
  resourceTypeDI18nresourceTypeDI18nList: {
    type: 'table',
    path: '/resourcetypedi18n',
    targetsId: [],
  },
  resourceTypeDI18nresourceTypeDI18nEdit: {
    type: 'edit',
    path: '/resourcetypedi18n',
    targetId: 'resourceTypeDI18nresourceTypeDI18nList',
  },
  resourceTypeDetailresourceTypeDetailSearch: {
    type: 'search',
    path: '/resourcetypedetail',
    targetId: 'resourceTypeDetailresourceTypeDetailList',
  },
  resourceTypeDetailresourceTypeDetailList: {
    type: 'table',
    path: '/resourcetypedetail',
    targetsId: [
      {
        id: 'resourceTypeDetailresourceTypeDI18nList',
        path: 'idParent.idResourceTypeD',
      },
    ],
  },
  resourceTypeDetailresourceTypeDetailEdit: {
    type: 'edit',
    path: '/resourcetypedetail',
    targetId: 'resourceTypeDetailresourceTypeDetailList',
  },
  resourceTypeDetailresourceTypeDI18nList: {
    type: 'table',
    path: '/resourcetypedi18n',
    targetsId: [],
  },
  resourceTypeI18nresourceTypeI18nSearch: {
    type: 'search',
    path: '/resourcetypei18n',
    targetId: 'resourceTypeI18nresourceTypeI18nList',
  },
  resourceTypeI18nresourceTypeI18nList: {
    type: 'table',
    path: '/resourcetypei18n',
    targetsId: [],
  },
  resourceTypeI18nresourceTypeI18nEdit: {
    type: 'edit',
    path: '/resourcetypei18n',
    targetId: 'resourceTypeI18nresourceTypeI18nList',
  },
  rolesrolesSearch: {
    type: 'search',
    path: '/roles',
    targetId: 'rolesrolesList',
  },
  rolesrolesList: {
    type: 'table',
    path: '/roles',
    targetsId: [{ id: 'rolesrolesI18nList', path: 'idParent.idRole' }],
  },
  rolesrolesEdit: {
    type: 'edit',
    path: '/roles',
    targetId: 'rolesrolesList',
  },
  rolesrolesI18nList: {
    type: 'table',
    path: '/rolesi18n',
    targetsId: [],
  },
  rolesI18nrolesI18nSearch: {
    type: 'search',
    path: '/rolesi18n',
    targetId: 'rolesI18nrolesI18nList',
  },
  rolesI18nrolesI18nList: {
    type: 'table',
    path: '/rolesi18n',
    targetsId: [],
  },
  rolesI18nrolesI18nEdit: {
    type: 'edit',
    path: '/rolesi18n',
    targetId: 'rolesI18nrolesI18nList',
  },
  rolesPermissionsrolesPermissionsSearch: {
    type: 'search',
    path: '/rolespermissions',
    targetId: 'rolesPermissionsrolesPermissionsList',
  },
  rolesPermissionsrolesPermissionsList: {
    type: 'table',
    path: '/rolespermissions',
    targetsId: [],
  },
  rolesPermissionsrolesPermissionsEdit: {
    type: 'edit',
    path: '/rolespermissions',
    targetId: 'rolesPermissionsrolesPermissionsList',
  },
  scorescoreSearch: {
    type: 'search',
    path: '/score',
    targetId: 'scorescoreList',
  },
  scorescoreList: {
    type: 'table',
    path: '/score',
    targetsId: [],
  },
  scorescoreEdit: {
    type: 'edit',
    path: '/score',
    targetId: 'scorescoreList',
  },
  templateChRetemplateChReSearch: {
    type: 'search',
    path: '/templatechre',
    targetId: 'templateChRetemplateChReList',
  },
  templateChRetemplateChReList: {
    type: 'table',
    path: '/templatechre',
    targetsId: [],
  },
  templateChRetemplateChReEdit: {
    type: 'edit',
    path: '/templatechre',
    targetId: 'templateChRetemplateChReList',
  },
  userResourceuserResourceSearch: {
    type: 'search',
    path: '/userresource',
    targetId: 'userResourceuserResourceList',
  },
  userResourceuserResourceList: {
    type: 'table',
    path: '/userresource',
    targetsId: [],
  },
  userResourceuserResourceEdit: {
    type: 'edit',
    path: '/userresource',
    targetId: 'userResourceuserResourceList',
  },
  usersusersSearch: {
    type: 'search',
    path: '/users',
    targetId: 'usersusersList',
  },
  usersusersList: {
    type: 'table',
    path: '/users',
    targetsId: [],
  },
  usersusersEdit: {
    type: 'edit',
    path: '/users',
    targetId: 'usersusersList',
  },
  usersusersSearchLiww: {
    type: 'search',
    path: '/users',
    targetId: 'usersusersListLiww',
  },
  usersusersListLiww: {
    type: 'table',
    path: '/users/liww',
    targetsId: [],
  },
  usersusersEditLiww: {
    type: 'edit',
    path: '/users',
    targetId: 'usersusersListLiww',
  },
  usersRolesusersRolesSearch: {
    type: 'search',
    path: '/usersroles',
    targetId: 'usersRolesusersRolesList',
  },
  usersRolesusersRolesList: {
    type: 'table',
    path: '/usersroles',
    targetsId: [],
  },
  usersRolesusersRolesEdit: {
    type: 'edit',
    path: '/usersroles',
    targetId: 'usersRolesusersRolesList',
  },
  bousersusersSearch: {
    type: 'search',
    path: '/bousers',
    targetId: 'bousersusersList',
  },
  bousersusersList: {
    type: 'table',
    path: '/bousers',
    targetsId: [{ id: 'bousersrolesList', path: 'idUser.idUser' }],
  },
  bousersusersEdit: {
    type: 'edit',
    path: '/bousers',
    targetId: 'bousersusersList',
  },
  bousersrolesList: {
    type: 'table',
    path: '/boroles/m2m',
    targetsId: [],
  },
  borolesrolesSearch: {
    type: 'search',
    path: '/boroles',
    targetId: 'borolesrolesList',
  },
  borolesrolesList: {
    type: 'table',
    path: '/boroles',
    targetsId: [{ id: 'borolespermissionsList', path: 'idRole.idRole' }],
  },
  borolesrolesEdit: {
    type: 'edit',
    path: '/boroles',
    targetId: 'borolesrolesList',
  },
  borolespermissionsList: {
    type: 'table',
    targetsId: [],
    path: '/bopermissions/m2m',
  },
  bopermissionspermissionsSearch: {
    type: 'search',
    path: '/bopermissions',
    targetId: 'bopermissionspermissionsList',
  },
  bopermissionspermissionsList: {
    type: 'table',
    path: '/bopermissions',
    targetsId: [],
  },
  bopermissionspermissionsEdit: {
    type: 'edit',
    path: '/bopermissions',
    targetId: 'bopermissionspermissionsList',
  },
  challengeTemplatechallengeTemplateSearch: {
    type: 'search',
    path: '/challengetemplate',
    targetId: 'challengeTemplatechallengeTemplateList',
  },
  challengeTemplatechallengeTemplateList: {
    type: 'table',
    path: '/challengetemplate',
    targetsId: [
      {
        id: 'challengetemplateResoucetypeList',
        path: 'idChallengeTemplate.idChallengeTemplate',
      },
    ],
  },
  challengeTemplatechallengeTemplateEdit: {
    type: 'edit',
    path: '/challengetemplate',
    targetId: 'challengeTemplatechallengeTemplateList',
  },
  challengetemplateResoucetypeList: {
    type: 'table',
    path: '/resourceType/templates',
  },
  m2mchallengetypeResourcetypeSearch: {
    type: 'search',
    path: '/templatechre',
    targetId: 'm2mchallengetypeResourcetypeList',
  },
  m2mchallengetypeResourcetypeList: {
    type: 'table',
    path: '/templatechre',
    targetsId: [],
  },
  m2musersrolesSearch: {
    type: 'search',
    path: '/bousersroles',
    targetId: 'm2musersrolesList',
  },
  m2musersrolesList: {
    type: 'table',
    path: '/bousersroles',
    targetsId: [],
  },
  m2mrolespermissionsSearch: {
    type: 'search',
    path: '/borolespermissions',
    targetId: 'm2mrolespermissionsList',
  },
  m2mrolespermissionsList: {
    type: 'table',
    path: '/borolespermissions',
    targetsId: [],
  },
  challengeTemplateDetailchallengeTemplateDetailSearch: {
    type: 'search',
    path: '/templatechre',
    targetId: 'challengeTemplateDetailchallengeTemplateDetailList',
  },
  challengeTemplateDetailchallengeTemplateDetailList: {
    type: 'table',
    path: '/templatechre',
    targetsId: [],
  },
  challengeTemplateDetailchallengeTemplateDetailEdit: {
    type: 'edit',
    path: '/templatechre',
    targetId: 'challengeTemplateDetailchallengeTemplateDetailList',
  },
  challengeusersSearch: {
    type: 'search',
    path: '/challenge/type/users', //`/challenge/${challengeType}/users`
    targetId: 'challengeusersList',
  },
  challengeusersList: {
    type: 'table',
    path: '/challenge/users/list/type',
    targetsId: [],
  },
  challengeUsersType: {
    type: 'table',
    path: '/challenge/users/list/type',
    targetsId: [],
  },
  limitexceptionsusersSearch: {
    type: 'search',
    path: apiPaths.CALL.CATALOG_LIMITS_USERS, //`/challenge/${challengeType}/users`
    targetId: 'limitexceptionsusersList',
  },
  limitexceptionsusersList: {
    type: 'table',
    path: apiPaths.CALL.CATALOG_LIMITS_USERS,
    targetsId: [],
  },
  disclaimersSearch: {
    type: 'search',
    path: '/disclaimer',
    targetId: 'disclaimersList',
  },
  disclaimersList: {
    type: 'table',
    path: '/disclaimer',
    targetsId: [{ id: 'disclaimersUrlsList', path: 'idDisclaimer' }],
  },
  disclaimersEdit: {
    type: 'edit',
    path: '/disclaimer',
    targetId: 'disclaimersList',
  },
  disclaimersUrlsList: {
    type: 'table',
    targetsId: [],
    path: '/disclaimer-url',
  },
  m2mpagesDisclaimersSearch: {
    type: 'search',
    path: '/disclaimer-url',
    targetId: 'm2mpagesDisclaimersList',
  },
  m2mpagesDisclaimersList: {
    type: 'table',
    path: '/disclaimer-url',
    targetsId: [],
  },
  listOfValuesSearch: {
    type: 'search',
    path: '/list',
    targetId: 'listOfValuesList',
  },
  listOfValuesList: {
    type: 'table',
    path: '/list',
    targetsId: [],
  },
  listOfValuesEdit: {
    type: 'edit',
    path: '/list',
    targetId: 'listOfValuesList',
  },
  bomenumenuSearch: {
    type: 'search',
    path: '/menu',
    targetId: 'bomenumenuList',
  },
  bomenumenuList: {
    type: 'table',
    path: '/menu',
    targetsId: [],
  },
  bomenumenuEdit: {
    type: 'edit',
    path: '/menu',
    targetId: 'bomenumenuList',
  },
  specialtyspecialtySearch: {
    type: 'search',
    path: '/specialty',
    targetId: 'specialtyspecialtyList',
  },
  specialtyspecialtyList: {
    type: 'table',
    path: '/specialty',
    targetsId: [],
  },
  specialtyspecialtyEdit: {
    type: 'edit',
    path: '/specialty',
    targetId: 'specialtyspecialtyList',
  },
  contentPagecontentPageSearch: {
    type: 'search',
    path: 'contenpage',
    targetId: 'contentPagecontentPageList',
  },
  contentPagecontentPageList: {
    type: 'table',
    path: 'contentpage',
    targetsId: [],
  },
  contentPagecontentPageEdit: {
    type: 'edit',
    path: 'contentpage',
    targetId: 'contentPagecontentPageList',
  },
  dermoValidatordermoValidatorSearch: {
    type: 'search',
    path: 'userRoles',
    targetId: 'dermoValidatordermoValidatorList',
  },
  dermoValidatordermoValidatorList: {
    type: 'table',
    path: 'userRoles',
    targetId: [],
  },
  dermoValidatordermoValidatorEdit: {
    type: 'edit',
    path: 'dermoUserPos',
    targetId: 'dermoValidatordermoValidatorList',
  },
  dermoAdmindermoAdminSearch: {
    type: 'search',
    path: 'dermoadmin/dermoValidators',
    targetId: 'dermoAdmindermoAdminList',
  },
  dermoAdmindermoAdminList: {
    type: 'table',
    path: 'dermoadmin/dermoValidators',
    targetId: [],
  },
  dermoAdmindermoAdminEdit: {
    type: 'edit',
    path: 'dermoadmin',
    targetId: 'dermoAdmindermoAdminList',
  },
  mailingmailingSearch: {
    type: 'search',
    path: '/mailTemplates',
    targetId: 'mailingmailingList',
  },
  mailingmailingList: {
    type: 'table',
    path: '/mailTemplates',
    targetsId: [],
  },
  mailingmailingEdit: {
    type: 'edit',
    path: '/mailTemplates',
    targetId: 'mailingmailingList',
  },
  mailingButtonsSearch: {
    type: 'search',
    path: '/mailButtons',
    targetId: 'mailingButtonsList',
  },
  mailingButtonsList: {
    type: 'table',
    path: '/mailButtons',
    targetIds: [],
  },
  mailingButtonsEdit: {
    type: 'edit',
    path: '/mailButtons',
    targetId: 'mailingButtonsList',
  },
  marketingMaterialsSearch: {
    type: 'search',
    path: 'marketingmaterials',
    targetId: 'marketingMaterialsTable',
  },
  marketingMaterialsTable: {
    type: 'table',
    path: 'marketingmaterials',
    targetsId: [],
  },
  marketingMaterialsEdit: {
    type: 'edit',
    path: 'marketingmaterials',
    targetId: 'marketingMaterialsTable',
  },
  tagSearch: {
    type: 'search',
    path: '/tag',
    targetId: 'tagList',
  },
  tagList: {
    type: 'table',
    path: '/tag',
    targetsId: [],
  },
  tagEdit: {
    type: 'edit',
    path: '/tag',
    targetId: 'tagList',
  },
  surveySearch: {
    type: 'search',
    path: '/survey',
    targetId: 'surveyList',
  },
  surveyList: {
    type: 'table',
    path: '/survey',
    targetsId: [],
  },
  surveyEdit: {
    type: 'edit',
    path: '/survey',
    targetId: 'surveyList',
  },
  arnsurveySearch: {
    type: 'search',
    path: '/arnSurvey',
    targetId: 'arnsurveyList',
  },
  arnsurveyList: {
    type: 'table',
    path: '/arnSurvey',
    targetsId: [],
  },
  arnsurveyEdit: {
    type: 'edit',
    path: '/arnSurvey',
    targetId: 'arnsurveyList',
  },
  actionsactionsSearch: {
    type: 'search',
    path: '/actions',
    targetId: 'actionsactionsList',
  },
  actionsactionsList: {
    type: 'table',
    path: '/actions',
    targetsId: [],
  },
  actionsactionsEdit: {
    type: 'edit',
    path: '/actions',
    targetId: 'actionsactionsList',
  },
  requestorderrequestorderSearch: {
    type: 'search',
    path: '/requestquotation',
    targetId: 'requestorderrequestorderList',
  },
  requestorderrequestorderList: {
    type: 'table',
    path: '/requestquotation',
    targetsId: [],
  },
  requestorderrequestorderEdit: {
    type: 'edit',
    path: '/requestquotation',
    targetId: 'requestorderrequestorderEdit',
  },
  platformplatformSearch: {
    type: 'search',
    path: '/platform',
    targetId: 'platformplatformList',
  },
  platformplatformList: {
    type: 'table',
    path: '/platform',
    targetId: [],
  },
  platformplatformEdit: {
    type: 'edit',
    path: '/platform',
    targetId: 'platformplatformList',
  },
  shoppingcartorderSearch: {
    type: 'search',
    path: '/userresource/campaign',
    targetId: 'shoppingcartorderList',
  },
  shoppingcartorderList: {
    type: 'table',
    path: '/userresource/campaign',
    targetIds: [],
  },
  shoppingcartorderEdit: {
    type: 'edit',
    path: '/userresource/campaign',
    targetId: 'shoppingcartorderEdit',
  },
  pharmacySearch: {
    type: 'search',
    path: '/pharmacy',
    targetId: 'pharmacyList',
  },
  pharmacyList: {
    type: 'table',
    path: '/pharmacy',
    targetsId: [],
  },
  pharmacyEdit: {
    type: 'edit',
    path: '/pharmacy',
    targetId: 'pharmacyList',
  },
  concheckoutconcheckoutSearch: {
    type: 'search',
    path: '/checkout',
    targetId: 'concheckoutconcheckoutList',
  },
  concheckoutconcheckoutList: {
    type: 'table',
    path: '/checkout',
    targetsId: [],
  },
  assignpointsSearch: {
    type: 'search',
    path: '/userresource/uploadMedia',
    targetId: 'assignpointsList',
  },
  assignpointsList: {
    type: 'table',
    path: '/userresource/uploadMedia',
    targetIds: [],
  },
  qrProductSearch: {
    type: 'search',
    path: '/qrProduct',
    targetId: 'qrProductList',
  },
  qrProductList: {
    type: 'table',
    path: '/qrProduct',
    targetsId: [],
  },
  qrProductEdit: {
    type: 'edit',
    path: '/qrProduct',
    targetId: 'qrProductList',
  },
  filterSearch: {
    type: 'search',
    path: '/filter',
    targetId: 'filterList',
  },
  filterList: {
    type: 'table',
    path: '/filter',
    targetsId: [],
  },
  filterEdit: {
    type: 'edit',
    path: '/filter',
    targetId: 'filterList',
  },
  pearlsSearch: {
    type: 'search',
    path: '/userResource/pearls',
    targetId: 'pearlsList',
  },
  pearlsList: {
    type: 'table',
    path: '/userResource/pearls',
    targetIds: [],
  },
  parametersSearch: {
    type: 'search',
    path: '/parameters',
    targetId: 'parametersList',
  },
  parametersList: {
    type: 'table',
    path: '/parameters',
    targetIds: [],
  },
  parametersEdit: {
    type: 'edit',
    path: '/parameters',
    targetId: 'parametersList',
  },
  labelRedeemProductsSearch: {
    type: 'search',
    path: '/labelRedeemProducts',
    targetId: 'labelRedeemProductsList',
  },
  labelRedeemProductsList: {
    type: 'table',
    path: '/labelRedeemProducts',
    targetsId: [],
  },
  labelRedeemProductsEdit: {
    type: 'edit',
    path: '/labelRedeemProducts',
    targetId: 'labelRedeemProductsList',
  },
  cacheManagerSearch: {
    type: 'search',
    path: '/cacheManager',
    targetId: 'cacheManagerList',
  },
  cacheManagerList: {
    type: 'table',
    path: '/cacheManager',
    targetIds: [],
  },
  landingProductSearch: {
    type: 'search',
    path: '/landingProduct',
    targetId: 'landingProductList',
  },
  landingProductList: {
    type: 'table',
    path: '/landingProduct',
    targetIds: [],
  },
  landingProductEdit: {
    type: 'edit',
    path: '/landingProduct',
    targetId: 'landingProductList',
  },
  parametersTypesSearch: {
    type: 'search',
    path: '/parametersTypes',
    targetId: 'parametersTypesList',
  },
  parametersTypesList: {
    type: 'table',
    path: '/parametersTypes',
    targetIds: [],
  },

  // webeat components
  webeatActionsSearch: {
    type: 'search',
    path: '/actions',
    targetId: 'webeatActionsList',
  },
  webeatActionsList: {
    type: 'table',
    path: '/actions',
    targetIds: [],
  },
  webeatActionsEdit: {
    type: 'edit',
    path: '/actions',
    targetId: 'webeatActionsList',
  },

  webeatChallengeSearch: {
    type: 'search',
    path: '/challenge',
    targetId: 'webeatChallengeList',
  },
  webeatChallengeList: {
    type: 'table',
    path: '/challenge',
    targetIds: [],
  },
  webeatChallengeEdit: {
    type: 'edit',
    path: '/challenge',
    targetId: 'webeatChallengeList',
  },

  webeatChallengeTypeSearch: {
    type: 'search',
    path: '/challenge-type',
    targetId: 'webeatChallengeTypeList',
  },
  webeatChallengeTypeList: {
    type: 'table',
    path: '/challenge-type',
    targetIds: [
      {
        id: 'webeatChallengeTypeResourceTypeList',
        path: 'idChallengeType.idChallengeType',
      },
    ],
  },
  webeatChallengeTypeEdit: {
    type: 'edit',
    path: '/challenge-type',
    targetId: 'webeatChallengeTypeList',
  },
  webeatChallengeTypeResourceTypeList: {
    type: 'search',
    path: '/resource-type/challenge-type',
    targetIds: [],
  },

  webeatResourceTypeSearch: {
    type: 'search',
    path: '/resource-type',
    targetId: 'webeatResourceTypeList',
  },
  webeatResourceTypeList: {
    type: 'table',
    path: '/resource-type',
    targetIds: [],
  },
  webeatResourceTypeEdit: {
    type: 'edit',
    path: '/resource-type',
    targetId: 'webeatResourceTypeList',
  },

  webeatResourceTypeDetailSearch: {
    type: 'search',
    path: '/resource-type-detail',
    targetId: 'webeatResourceTypeDetailList',
  },
  webeatResourceTypeDetailList: {
    type: 'table',
    path: '/resource-type-detail',
    targetIds: [],
  },
  webeatResourceTypeDetailEdit: {
    type: 'edit',
    path: '/resource-type-detail',
    targetId: 'webeatResourceTypeDetailList',
  },

  webeatMailingSearch: {
    type: 'search',
    path: '/mailing',
    targetId: 'webeatMailingList',
  },
  webeatMailingList: {
    type: 'table',
    path: '/mailing',
    targetIds: [],
  },
  webeatMailingEdit: {
    type: 'edit',
    path: '/mailing',
    targetId: 'webeatMailingList',
  },

  webeatRestrictionsSearch: {
    type: 'search',
    path: '/platform-feature-filter-entity',
    targetId: 'webeatRestrictionsList',
  },
  webeatRestrictionsList: {
    type: 'table',
    path: '/platform-feature-filter-entity',
    targetsId: [],
  },
  webeatRestrictionsEdit: {
    type: 'edit',
    path: '/platform-feature-filter-entity',
    targetId: 'webeatRestrictionsList',
  },

  webeatMenuSearch: {
    type: 'search',
    path: '/menu',
    targetId: 'webeatMenuList',
  },
  webeatMenuList: {
    type: 'table',
    path: '/menu',
    targetsId: [{ id: 'webeatMenuChildList', path: 'parentId' }],
  },
  webeatMenuEdit: {
    type: 'edit',
    path: '/menu',
    targetId: 'webeatMenuList',
  },

  webeatMenuChildList: {
    type: 'table',
    path: '/menu',
    targetsId: [],
  },

  webeatMenuChildViewList: {
    type: 'table',
    path: '/menu',
    targetIds: [],
  },

  webeatMenuChildEdit: {
    type: 'edit',
    path: '/menu',
    targetId: 'webeatMenuChildViewList',
  },
  webeatMenuChildSearch: {
    type: 'search',
    path: '/menu',
    targetId: 'webeatMenuChildViewList',
  },

  webeatPharmacySearch: {
    type: 'search',
    path: '/pharmacy',
    targetId: 'webeatPharmacyList',
  },
  webeatPharmacyList: {
    type: 'table',
    path: '/pharmacy',
    targetIds: [],
  },
  webeatPharmacyEdit: {
    type: 'edit',
    path: '/pharmacy',
    targetId: 'webeatPharmacyList',
  },

  webeatQrProductsSearch: {
    type: 'search',
    path: '/qr-products',
    targetId: 'webeatQrProductsList',
  },
  webeatQrProductsList: {
    type: 'table',
    path: '/qr-products',
    targetIds: [],
  },
  webeatQrProductsEdit: {
    type: 'edit',
    path: '/qr-products',
    targetId: 'webeatQrProductsList',
  },

  webeatStaticPageSearch: {
    type: 'search',
    path: '/static-page',
    targetId: 'webeatStaticPageList',
  },
  webeatStaticPageList: {
    type: 'table',
    path: '/static-page',
    targetIds: [],
  },
  webeatStaticPageEdit: {
    type: 'edit',
    path: '/static-page',
    targetId: 'webeatStaticPageList',
  },

  webeatListOfValuesSearch: {
    type: 'search',
    path: '/list-of-values',
    targetId: 'webeatListOfValuesList',
  },
  webeatListOfValuesList: {
    type: 'table',
    path: '/list-of-values',
    targetIds: [],
  },
  webeatListOfValuesEdit: {
    type: 'edit',
    path: '/list-of-values',
    targetId: 'webeatListOfValuesList',
  },
  webeatHtmlContentSearch: {
    type: 'search',
    path: '/html-content',
    targetId: 'webeatHtmlContentList',
  },
  webeatHtmlContentList: {
    type: 'table',
    path: '/html-content',
    targetIds: [],
  },
  webeatHtmlContentEdit: {
    type: 'edit',
    path: '/html-content',
    targetId: 'webeatHtmlContentList',
  },
  webeatDermoEventSearch: {
    type: 'search',
    path: '/event',
    targetId: 'webeatDermoEventList',
  },
  webeatDermoEventList: {
    type: 'table',
    path: '/event',
    targetIds: [],
  },
  webeatDermoEventEdit: {
    type: 'edit',
    path: '/event',
    targetId: 'webeatDermoEventList',
  },
  webeatDermoValidationSearch: {
    type: 'search',
    path: '/dermo-validation',
    targetId: 'webeatDermoValidationList',
  },
  webeatDermoValidationList: {
    type: 'table',
    path: '/dermo-validation',
    targetIds: [],
  },
  webeatDermoUsersSearch: {
    type: 'search',
    path: '/user',
    targetId: 'webeatDermoUsersList',
  },
  webeatDermoUsersList: {
    type: 'table',
    path: '/user?q=roles::Dermo',
    targetIds: [],
  },
  scientificMaterialsSearch: {
    type: 'search',
    path: 'scientificmaterials',
    targetId: 'scientificMaterialsTable',
  },
  scientificMaterialsTable: {
    type: 'table',
    path: 'scientificmaterials',
    targetsId: [],
  },
  scientificMaterialsEdit: {
    type: 'edit',
    path: 'scientificmaterials',
    targetId: 'scientificMaterialsTable',
  },
  webeatCustomersDashboardSearch: {
    type: 'search',
    path: '/customer',
    targetId: 'webeatCustomersDashboardList',
  },
  webeatCustomersDashboardList: {
    type: 'table',
    path: '/customer',
    targetsId: [],
  },
  webeatCustomersDashboardEdit: {
    type: 'edit',
    path: '/customer',
    targetId: 'webeatCustomersDashboardList',
  },
  webeatBackOfficeUsersSearch: {
    type: 'search',
    path: 'bousers',
    targetId: 'webeatBackOfficeUsersList',
  },
  webeatBackOfficeUsersList: {
    type: 'table',
    path: 'bousers',
    targetsId: [],
  },
  webeatBackOfficeUsersEdit: {
    type: 'edit',
    path: 'bousers',
    targetId: 'webeatBackOfficeUsersList',
  },
  webeatBackOfficeRolesSearch: {
    type: 'search',
    path: 'back-office-role',
    targetId: 'webeatBackOfficeRolesList',
  },
  webeatBackOfficeRolesList: {
    type: 'table',
    path: 'back-office-role',
    targetsId: [],
  },
  webeatBackOfficeRolesEdit: {
    type: 'edit',
    path: 'back-office-role',
    targetId: 'webeatBackOfficeRolesList',
  },
  webeatBackOfficePermissionsSearch: {
    type: 'search',
    path: 'back-office-permission',
    targetId: 'webeatBackOfficePermissionsList',
  },
  webeatBackOfficePermissionsList: {
    type: 'table',
    path: 'back-office-permission',
    targetsId: [],
  },
  webeatBackOfficePermissionsEdit: {
    type: 'edit',
    path: 'back-office-permission',
    targetId: 'webeatBackOfficePermissionsList',
  },
  webeatBackOfficeMenusSearch: {
    type: 'search',
    path: 'back-office-menu',
    targetId: 'webeatBackOfficeMenusList',
  },
  webeatBackOfficeMenusList: {
    type: 'table',
    path: 'back-office-menu',
    targetsId: [],
  },
  webeatBackOfficeMenusEdit: {
    type: 'edit',
    path: 'back-office-menu',
    targetId: 'webeatBackOfficeMenusList',
  },
  webeatBackOfficePlatformsSearch: {
    type: 'search',
    path: 'platform',
    targetId: 'webeatBackOfficePlatformsList',
  },
  webeatBackOfficePlatformsList: {
    type: 'table',
    path: 'platform',
    targetsId: [],
  },
  webeatBackOfficePlatformsEdit: {
    type: 'edit',
    path: 'platform',
    targetId: 'webeatBackOfficePlatformsList',
  },
  webeatTagSearch: {
    type: 'search',
    path: '/tag',
    targetId: 'webeatTagList',
  },
  webeatTagList: {
    type: 'table',
    path: '/tag',
    targetsId: [],
  },
  webeatTagEdit: {
    type: 'edit',
    path: '/tag',
    targetId: 'webeatTagList',
  },
  webeatHomeSearch: {
    type: 'search',
    path: '/home',
    targetId: 'webeatHomeList',
  },
  webeatHomeList: {
    type: 'table',
    path: '/home',
    targetsId: [],
  },
  webeatHomeEdit: {
    type: 'edit',
    path: '/home',
    targetId: 'webeatHomeList',
  },
  webeatParametersSearch: {
    type: 'search',
    path: '/parameters',
    targetId: 'webeatParametersList',
  },
  webeatParametersList: {
    type: 'table',
    path: '/parameters',
    targetsId: [],
  },
  webeatParametersEdit: {
    type: 'edit',
    path: '/parameters',
    targetId: 'webeatParametersList',
  },
  consumableSearch: {
    type: 'search',
    path: '/consumable',
    targetId: 'consumableList',
  },
  consumableList: {
    type: 'table',
    path: '/consumable',
    targetsId: [],
  },
  consumablePageEdit: {
    type: 'edit',
    path: '/consumable',
    targetId: 'consumableList',
  },
  campaignSearch: {
    type: 'search',
    path: '/campaign',
    targetId: 'campaignList',
  },
  campaignList: {
    type: 'table',
    path: '/campaign',
    targetsId: [{ id: 'campaignCampaignConsumablesList', path: 'campaignId' }],
  },
  campaignPageEdit: {
    type: 'edit',
    path: '/campaign',
    targetId: 'campaignList',
  },
  campaignCampaignConsumablesList: {
    type: 'table',
    path: '/campaign-consumable',
    targetsId: [],
  },
  campaignConsumableSearch: {
    type: 'search',
    path: '/campaign-consumable',
    targetId: 'campaignConsumableList',
  },
  campaignConsumableList: {
    type: 'table',
    path: '/campaign-consumable',
    targetsId: [],
  },
  campaignConsumableEdit: {
    type: 'edit',
    path: '/campaign-consumable',
    targetId: 'campaignConsumableList',
  },
  consumableLandingSearch: {
    type: 'search',
    path: '/landing',
    targetId: 'consumableLandingList',
  },
  consumableLandingList: {
    type: 'table',
    path: '/landing',
    targetsId: [],
  },
  consumableLandingEdit: {
    type: 'edit',
    path: '/landing',
    targetId: 'consumableLandingList',
  },
  scanLandingSearch: {
    type: 'search',
    path: '/scan-landing',
    targetId: 'scanLandingList',
  },
  scanLandingList: {
    type: 'table',
    path: '/scan-landing',
    targetsId: [],
  },
  scanLandingEdit: {
    type: 'edit',
    path: '/scan-landing',
    targetId: 'scanLandingList',
  },
  webeatSearchManagementsSearch: {
    type: 'search',
    path: '/search-management',
    targetId: 'webeatSearchManagementsList',
  },
  webeatSearchManagementsList: {
    type: 'table',
    path: '/search-management',
    targetsId: [],
  },
  webeatSearchManagementsEdit: {
    type: 'edit',
    path: '/search-management',
    targetId: 'webeatSearchManagementsList',
  },
  webeatDataReplicaSearch: {
    type: 'search',
    path: '/data-replica',
    targetId: 'webeatDataReplicaList',
  },
  webeatDataReplicaList: {
    type: 'table',
    path: '/data-replica',
    targetsId: [],
  },
  webeatFaqSectionSearch: {
    type: 'search',
    path: '/faq-section',
    targetId: 'webeatFaqSectionList',
  },
  webeatFaqSectionList: {
    type: 'table',
    path: '/faq-section',
    targetsId: [{ id: 'webeatFaqSectionQuestionsList', path: 'idSection' }],
  },
  webeatFaqSectionEdit: {
    type: 'edit',
    path: '/faq-section',
    targetId: 'webeatFaqSectionList',
  },
  webeatFaqSectionQuestionsList: {
    type: 'table',
    path: '/faq-question',
    targetsId: [],
  },
  webeatFaqQuestionSearch: {
    type: 'search',
    path: '/faq-question',
    targetId: 'webeatFaqQuestionList',
  },
  webeatFaqQuestionList: {
    type: 'table',
    path: '/faq-question',
    targetsId: [],
  },
  webeatFaqQuestionEdit: {
    type: 'edit',
    path: '/faq-question',
    targetId: 'webeatFaqQuestionList',
  },
  webeatMetaTagRuleSearch: {
    type: 'search',
    path: '/meta-tag-rule',
    targetId: 'webeatMetaTagRuleList',
  },
  webeatMetaTagRuleList: {
    type: 'table',
    path: '/meta-tag-rule',
    targetsId: [],
  },
  webeatMetaTagRuleEdit: {
    type: 'edit',
    path: '/meta-tag-rule',
    targetId: 'webeatMetaTagRuleList',
  },
  consumableVariantSearch: {
    type: 'search',
    path: '/consumable-variant',
    targetId: 'consumableVariantList',
  },
  consumableVariantList: {
    type: 'table',
    path: '/consumable-variant',
    targetsId: [],
  },
  consumableVariantsPageEdit: {
    type: 'edit',
    path: '/consumable-variant',
    targetId: 'consumableVariantList',
  },
  // Subscription modal
  webeatSubscriptionModalSearch: {
    type: 'search',
    path: '/subscription-modal',
    targetId: 'webeatSubscriptionModalList',
  },
  webeatSubscriptionModalList: {
    type: 'table',
    path: '/subscription-modal',
    targetsId: [],
  },
};
