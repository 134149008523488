import {
  Checkbox,
  Col,
  DatePicker,
  Icon,
  Input,
  InputNumber,
  Row,
  Select,
  Tooltip,
} from 'antd';
import Form, { FormComponentProps } from 'antd/lib/form';
import React, { FC, useEffect } from 'react';

import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import moment from 'moment';
import {
  isConPlatform,
  isPINPlatform,
  isWebeatPlatform,
  loadTimezoneName,
} from '../utils';
import { usePrevious } from './ChallengeBuilder';
import { IChallengeOptions } from './ChallengeConfig';
import {
  // FIXME: BORRAR: categories AHORA ES filters
  //getCategoriesValues,
  getFiltersValues,
  getRoleTypeValues,
  getSpecialtiesValues,
  getTagValues,
  showBrands,
  showContentTypes,
  // FIXME: BORRAR: categories AHORA ES filters
  //showCategories,
  showFilters,
  showRoleType,
  showSpecialties,
} from './ChallengeUtils';
import { ChallengeRoleTypeEnum, ChallengeTypeEnum } from './Enums';
import {
  booleanFields,
  challengeDates,
} from './Enums/challengeOptionsFields.enum';

type Props = IChallengeOptions & FormComponentProps;

const OptionsForm: FC<Props> = ({
  roleTypeCombo,
  specialtyCombo,
  contentTypeCombo,
  brandCombo,
  challengeTagCombo,
  filtersCombo,
  filterTypeCombo,
  editingResource,
  form: { getFieldDecorator, resetFields },
  handleChangeField,
  intl: { formatMessage },
  formHasChanged,
  values,
}) => {
  const prevFormHasChanged = usePrevious(formHasChanged);
  const challengeType = values?.idChallengeType?.idChallengeType;

  const isExperienceProduct =
    values?.idChallengeType?.idChallengeType ===
    ChallengeTypeEnum.EXPERIENCE_PRODUCT;

  const challengeTooltipTitle = (id: string) => {
    return formatMessage({
      id,
    });
  };
  // TODO: DERMO: actualmente un challenge sólo puede tener 1 role. Por eso en el array se mira sólo..
  // TODO: .. la posición 0. Refactorizar cuando un challenge pueda tener múltiples roles.
  // const isRoleDermo =
  //   isConPlatform() &&
  //   values?.roles?.length &&
  //   values.roles[0]?.idRoleType === ChallengeRoleTypeEnum.DERMO;

  useEffect(() => {
    if (formHasChanged !== undefined && formHasChanged !== prevFormHasChanged)
      resetFields();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formHasChanged]);

  const BooleanComp = ({ key, value }: { key: string; value: any }) => {
    return (
      <Row
        type="flex"
        justify="space-between"
        align="top"
        className="challengeForm__checkbox"
        key={key}>
        <Col span={20}>
          <span style={{ lineHeight: '40px' }}>
            {formatMessage({ id: `challenge.${key}` })}
          </span>
        </Col>
        <Col span={4}>
          <Form.Item className="challengeForm__checkbox">
            {getFieldDecorator(key, {
              initialValue: value,
              valuePropName: 'checked',
            })(
              <Checkbox
                disabled={editingResource}
                onChange={(e: CheckboxChangeEvent) =>
                  handleChangeField({
                    type: 'check',
                    id: key,
                    value: e.target.checked,
                  })
                }
                style={{ float: 'right' }}
              />,
            )}
          </Form.Item>
        </Col>
      </Row>
    );
  };

  const DateComp = ({ key, value }: { key: string; value: any }) => {
    const currentTimezone = loadTimezoneName();

    // Need to set DatePicker timezone as well to avoid the component to use local machine timezone
    moment.tz.setDefault(currentTimezone);

    const dateValue = value ? moment.tz(value, currentTimezone) : undefined;

    return (
      <Row className="dateComponent" key={key}>
        <div className="dateComponent__title">
          {formatMessage({ id: `challenge.${key}` })}
        </div>
        <Row
          key={key}
          className="dateComponent__selector"
          type="flex"
          justify="space-between">
          <Form.Item style={{ width: '100%' }}>
            {getFieldDecorator(key, { initialValue: dateValue })(
              <DatePicker
                disabled={editingResource}
                format="DD-MM-YYYY HH:mm"
                showTime={{ format: 'HH:mm' }}
                showToday={false}
                size="small"
                onChange={(date: any | null, dateString: string) => {
                  let newDate = null;

                  if (date) {
                    newDate = moment(dateString, 'DD-MM-YYYY HH:mm');

                    newDate = newDate.format();
                  }

                  handleChangeField({
                    type: 'date',
                    id: key,
                    value: newDate,
                  });
                }}
              />,
            )}
          </Form.Item>
        </Row>
      </Row>
    );
  };

  return (
    <Form className="challengeConfigForm">
      {booleanFields.map((date: { key: string }) => {
        return BooleanComp({
          key: date.key,
          value: values ? values[date.key] : null,
        });
      })}
      {/*
      // * --------------------------------------------- Tags (CON)
      */}
      {isConPlatform() &&
        values.idChallengeType?.idChallengeType !==
          ChallengeTypeEnum.FORMATION_CON && (
          <Row className="categoriesSelector">
            <div className="dateComponent__title">
              {formatMessage({ id: 'challenge.options.tags' })}
            </div>
            <Row className="brandSelector" type="flex" justify="space-between">
              <Form.Item style={{ width: '100%' }}>
                {getFieldDecorator('tags', {
                  initialValue: getTagValues(values),
                })(
                  <Select
                    mode="multiple"
                    allowClear
                    showSearch
                    dropdownMatchSelectWidth={false}
                    disabled={false}
                    onChange={(value: string) =>
                      handleChangeField({
                        type: isWebeatPlatform() ? 'selector' : 'combo',
                        id: 'tags',
                        value,
                        multiSelectId: 'idTag',
                      })
                    }>
                    {challengeTagCombo.map((option) => (
                      <Select.Option
                        key={option.value}
                        value={option.value.toString()}>
                        {option.description}
                      </Select.Option>
                    ))}
                  </Select>,
                )}
              </Form.Item>
            </Row>
          </Row>
        )}
      {/*
      // * --------------------------------------------- ROLES (CON)
      */}
      {!showRoleType(challengeType) && isConPlatform() && (
        <Row className="categoriesSelector">
          <div className="dateComponent__title">
            {formatMessage({ id: 'challenge.options.roles' })}
          </div>
          <Row className="brandSelector" type="flex" justify="space-between">
            <Form.Item style={{ width: '100%' }}>
              {getFieldDecorator('role', {
                initialValue: getRoleTypeValues(values),
              })(
                <Select
                  mode="multiple"
                  allowClear
                  showSearch
                  dropdownMatchSelectWidth={false}
                  disabled={
                    values.idChallengeType?.idChallengeType ===
                    ChallengeTypeEnum.FORMATION_CON
                      ? true
                      : false
                  }
                  onChange={(value: any, options: any) => {
                    //Comprobamos que en el combo multiple, si existe ya como seleccionado el rol friend y cambiamos a Lover, automáticamente te lo quite
                    //de la lista.
                    let index = value.indexOf(
                      ChallengeRoleTypeEnum.FRIEND.toString(),
                    );
                    if (
                      value.includes(ChallengeRoleTypeEnum.LOVER.toString()) &&
                      value.includes(ChallengeRoleTypeEnum.FRIEND.toString())
                    )
                      value.splice(index, 1);

                    handleChangeField({
                      type: isWebeatPlatform() ? 'selector' : 'combo',
                      id: 'roles',
                      value,
                      multiSelectId: 'idRoleType',
                    });
                  }}>
                  {roleTypeCombo.map((option) =>
                    !isWebeatPlatform() &&
                    values.roles?.some(
                      (e: any) => e.idRoleType.toString() === '2',
                    ) &&
                    option.value.toString() === '1' ? (
                      ''
                    ) : (
                      <Select.Option
                        key={option.value}
                        value={option.value.toString()}>
                        {option.description}
                      </Select.Option>
                    ),
                  )}
                </Select>,
              )}
            </Form.Item>
          </Row>
        </Row>
      )}
      {/*
      // * --------------------------------------------- DURATION (PIN)
      */}
      {isPINPlatform() && (
        <Row className="duration">
          <div className="dateComponent__title">
            {formatMessage({ id: 'challenge.duration' })}
            <Tooltip
              className="fieldTooltip"
              title={challengeTooltipTitle('challenge.tooltip.duration')}>
              <Icon type="question-circle-o" />
            </Tooltip>
          </div>
          <Row className="brandSelector" type="flex" justify="space-between">
            <Form.Item style={{ width: '100%' }}>
              {getFieldDecorator('minutes', {
                initialValue: values.duration,
              })(
                <InputNumber
                  disabled={!values.showDuration}
                  onChange={(value) =>
                    handleChangeField({
                      id: 'duration',
                      type: 'number',
                      value,
                    })
                  }
                  max={999}
                  min={1}
                  defaultValue={1}
                />,
              )}
            </Form.Item>
          </Row>
        </Row>
      )}
      {/*
      // * --------------------------------------------- SPECIALTIES (PIN)
      */}
      {showSpecialties(challengeType) && (
        <Row className="categoriesSelector">
          <div className="dateComponent__title">
            {formatMessage({ id: 'challenge.specialties' })}
          </div>
          <Row className="brandSelector" type="flex" justify="space-between">
            <Form.Item style={{ width: '100%' }}>
              {getFieldDecorator('specialties', {
                initialValue: getSpecialtiesValues(values),
              })(
                <Select
                  mode="multiple"
                  allowClear
                  showSearch
                  dropdownMatchSelectWidth={false}
                  disabled={editingResource}
                  onChange={(value: string, options: any) =>
                    handleChangeField({
                      type: 'combo',
                      id: 'specialties',
                      value,
                      multiSelectId: 'idSpecialty',
                    })
                  }>
                  {specialtyCombo
                    .sort((a, b) => a.description.localeCompare(b.description))
                    .map((option) => (
                      <Select.Option
                        key={option.value}
                        value={option.value.toString()}>
                        {option.description}
                      </Select.Option>
                    ))}
                </Select>,
              )}
            </Form.Item>
          </Row>
        </Row>
      )}
      {/*
      // * --------------------------------------------- SPECIALTY (CON)
      */}
      {/* {!showSpecialties(challengeType) && !isRoleDermo && (
        <Row className="categoriesSelector">
          <div className="dateComponent__title">
            {formatMessage({ id: 'challenge.specialty' })}
          </div>
          <Row className="brandSelector" type="flex" justify="space-between">
            <Form.Item style={{ width: '100%' }}>
              {getFieldDecorator('specialties', {
                initialValue: getSpecialtiesValues(values),
              })(
                <Select
                  disabled={editingResource}
                  onChange={(value: string, options: any) =>
                    handleChangeField({
                      type: 'combo',
                      id: 'specialties',
                      value,
                      multiSelectId: 'idSpecialty',
                    })
                  }>
                  {specialtyCombo.map((option) => (
                    <Select.Option
                      key={option.value}
                      value={option.value.toString()}>
                      {option.description}
                    </Select.Option>
                  ))}
                </Select>,
              )}
            </Form.Item>
          </Row>
        </Row>
      )} */}
      {/*
      // * --------------------------------------------- BRAND (PIN)
      */}
      {showBrands(challengeType) && (
        <Row className="categoriesSelector">
          <div className="dateComponent__title">
            {formatMessage({ id: 'challenge.brand' })}
          </div>
          <Row className="brandSelector" type="flex" justify="space-between">
            <Form.Item style={{ width: '100%' }}>
              {/* TODO: Why brand has a default value of 0? */}
              {getFieldDecorator('brand', {
                initialValue:
                  (values?.brand !== 0 && values?.brand?.toString()) ?? null,
                rules: [{ required: showBrands(challengeType) }],
              })(
                <Select
                  disabled={editingResource}
                  onChange={(value: string, options: any) =>
                    handleChangeField({
                      type: 'combo',
                      id: 'brand',
                      value,
                    })
                  }>
                  {brandCombo
                    .sort((a, b) => a.description.localeCompare(b.description))
                    .map((option) => (
                      <Select.Option
                        key={option.value}
                        value={option.value.toString()}>
                        {option.description}
                      </Select.Option>
                    ))}
                </Select>,
              )}
            </Form.Item>
          </Row>
        </Row>
      )}
      {/*}
      {showCategories(challengeType) && (
        <Row className="categoriesSelector">
          <div className="dateComponent__title">
            {formatMessage({ id: 'challenge.categories' })}
          </div>
          <Row className="brandSelector" type="flex" justify="space-between">
            <Form.Item style={{ width: '100%' }}>
              {getFieldDecorator('categories', {
                initialValue: getCategoriesValues(values),
                rules: [{ required: showCategories(challengeType) }],
              })(
                <Select
                  allowClear
                  showSearch
                  dropdownMatchSelectWidth={false}
                  disabled={editingResource}
                  mode="multiple"
                  onChange={(value: string, options: any) =>
                    handleChangeField({
                      type: 'combo',
                      id: 'categories',
                      value,
                      multiSelectId: 'idCategory',
                    })
                  }>
                  {categoriesCombo.map((option) => (
                    <Select.Option
                      key={option.value}
                      value={option.value.toString()}>
                      {option.description}
                    </Select.Option>
                  ))}
                </Select>,
              )}
            </Form.Item>
          </Row>
        </Row>
      )}
      */}
      {/*
      // * --------------------------------------------- FILTERS (PIN)
      */}
      {showFilters(challengeType) && (
        <Row className="categoriesSelector">
          <div className="dateComponent__title">
            {formatMessage({ id: 'challenge.filters' })}
          </div>
          <Row className="brandSelector" type="flex" justify="space-between">
            <Form.Item style={{ width: '100%' }}>
              {getFieldDecorator('filters', {
                initialValue: getFiltersValues(values),
              })(
                <Select
                  allowClear
                  showSearch
                  dropdownMatchSelectWidth={false}
                  disabled={editingResource}
                  mode="multiple"
                  onChange={(value: string, options: any) =>
                    handleChangeField({
                      type: 'combo',
                      id: 'filters',
                      value,
                      multiSelectId: 'idFilter',
                    })
                  }>
                  {filterTypeCombo.map((filterType) => {
                    return (
                      <Select.OptGroup label={filterType.description}>
                        {filtersCombo
                          .filter((filter) => filter.type === filterType.value)
                          .sort((a, b) =>
                            a.description.localeCompare(b.description),
                          )
                          .map((option) => (
                            <Select.Option
                              key={option.value}
                              value={option.value.toString()}>
                              {option.description}
                            </Select.Option>
                          ))}
                      </Select.OptGroup>
                    );
                  })}
                </Select>,
              )}
            </Form.Item>
          </Row>
        </Row>
      )}
      {/*
      // * --------------------------------------------- SLUG
      */}
      <Row className="slugInput">
        <div className="dateComponent__title">
          {formatMessage({ id: 'challenge.slug' })}
          {isPINPlatform() && (
            <Tooltip
              className="fieldTooltip"
              title={challengeTooltipTitle('challenge.tooltip.slug')}>
              <Icon type="question-circle-o" />
            </Tooltip>
          )}
        </div>
        <Row
          className="dateComponent__selector"
          type="flex"
          justify="space-between">
          <Form.Item style={{ width: '100%' }}>
            {getFieldDecorator('slug', {
              initialValue: values?.slug,
            })(
              <Input
                disabled={editingResource}
                onChange={(e) =>
                  handleChangeField({
                    type: 'text',
                    id: 'slug',
                    value: e.target.value,
                  })
                }
              />,
            )}
          </Form.Item>
        </Row>
      </Row>
      {/*
      // * --------------------------------------------- FEATURED PRIORITY ORDER
      */}
      <Row className="slugInput">
        <div className="dateComponent__title">
          {formatMessage({
            id: values.featured
              ? 'challenge.order-priority-featured'
              : 'challenge.order-priority',
          })}
        </div>
        <Row
          className="dateComponent__selector"
          type="flex"
          justify="space-between">
          <Form.Item style={{ width: '100%' }}>
            {getFieldDecorator('order', {
              initialValue: values?.order,
            })(
              <InputNumber
                disabled={editingResource}
                onChange={(value) =>
                  handleChangeField({
                    id: 'order',
                    type: 'number',
                    value,
                  })
                }
                min={0}
              />,
            )}
          </Form.Item>
        </Row>
      </Row>
      {/*
      // * --------------------------------------------- TARGET ENROLLMENT
      */}
      {isPINPlatform() && (
        <Row className="targetEnrollementInput">
          <div className="dateComponent__title">
            {formatMessage({ id: 'challenge.targetEnrollement' })}
          </div>
          <Row
            className="dateComponent__title"
            type="flex"
            justify="space-between">
            <Form.Item style={{ width: '100%' }} required>
              {getFieldDecorator('targetEnrollement', {
                initialValue: values?.targetEnrollement,
                rules: [
                  {
                    validator: (rule, value, callback) => {
                      if (value <= 0 || isNaN(Number(value))) {
                        callback(
                          formatMessage({
                            id: 'validation.target.numbers',
                          }),
                        );
                      } else {
                        callback();
                      }
                    },
                  },
                ],
              })(
                <Input
                  disabled={editingResource}
                  required
                  onChange={(e) =>
                    handleChangeField({
                      type: 'number',
                      id: 'targetEnrollement',
                      value: e.target.value,
                    })
                  }
                />,
              )}
            </Form.Item>
          </Row>
        </Row>
      )}
      {/*
      // * --------------------------------------------- TARGET ACHIVEMENT
      */}
      {isPINPlatform() && (
        <Row className="targetAchivementInput">
          <div className="dateComponent__title">
            {formatMessage({ id: 'challenge.targetAchivement' })}
          </div>
          <Row
            className="dateComponent__title"
            type="flex"
            justify="space-between">
            <Form.Item style={{ width: '100%' }}>
              {getFieldDecorator('targetAchivement', {
                initialValue: values?.targetAchivement,
                rules: [
                  {
                    validator: (rule, value, callback) => {
                      if (value <= 0 || isNaN(Number(value))) {
                        callback(
                          formatMessage({
                            id: 'validation.target.numbers',
                          }),
                        );
                      } else {
                        callback();
                      }
                    },
                  },
                ],
              })(
                <Input
                  disabled={editingResource}
                  onChange={(e) =>
                    handleChangeField({
                      type: 'number',
                      id: 'targetAchivement',
                      value: e.target.value,
                    })
                  }
                />,
              )}
            </Form.Item>
          </Row>
        </Row>
      )}
      {/*
      // * --------------------------------------------- CONTENT TYPE
      */}
      {isPINPlatform() && (
        <Row className="contentTypeInput">
          <div className="dateComponent__title">
            {formatMessage({ id: 'challenge.contentType' })}
          </div>
          <Row
            className="dateComponent__title"
            type="flex"
            justify="space-between">
            <Form.Item style={{ width: '100%' }} required>
              {getFieldDecorator('contentType', {
                initialValue: values?.contentType,
                rules: [{ required: !showContentTypes(challengeType) }],
              })(
                <Select
                  disabled={editingResource}
                  onChange={(value: string) =>
                    handleChangeField({
                      type: 'combo',
                      id: 'contentType',
                      value,
                    })
                  }>
                  {contentTypeCombo
                    .sort((a, b) => a.description.localeCompare(b.description))
                    .map((option) => (
                      <Select.Option
                        key={option.value}
                        value={option.value.toString()}>
                        {option.description}
                      </Select.Option>
                    ))}
                </Select>,
              )}
            </Form.Item>
          </Row>
        </Row>
      )}
      {/*
      // * --------------------------------------------- DATE FIELDS
      */}
      {challengeDates.map((date: { key: string }) => {
        return DateComp({
          key: date.key,
          value: values ? values[date.key] : null,
        });
      })}
      {isExperienceProduct && (
        <Row className="slugInput">
          <div className="dateComponent__title">
            {formatMessage({ id: 'challenge.featured.coverImageMobile' })}
          </div>
          <Row
            className="dateComponent__selector"
            type="flex"
            justify="space-between">
            <Form.Item style={{ width: '100%' }}>
              {getFieldDecorator('coverImageMobile', {
                initialValue: values?.coverImageMobile,
              })(
                <Input
                  disabled={editingResource}
                  onChange={(e) =>
                    handleChangeField({
                      type: 'text',
                      id: 'coverImageMobile',
                      value: e.target.value,
                    })
                  }
                />,
              )}
            </Form.Item>
          </Row>
        </Row>
      )}
    </Form>
  );
};

export default Form.create<Props>({ name: 'challenge_options_form' })(
  OptionsForm,
);
