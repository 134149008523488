import React, { FC } from 'react';
import { useIntl } from 'react-intl';
import { Route, Switch } from 'react-router-dom';
import { TMainWrapperListProps } from '../app/MainWrapper';
import NotFound from '../app/NotFound';
import config from '../config';
import Welcome from '../welcome/Welcome';

import ActionsDashboard from './ActionsDashboard';
import BoMenuDashboard from './BoMenuDashboard';
import BoPermissionsDashboard from './BoPermissionsDashboard';
import BoRolesDashboard from './BoRolesDashboard';
import BoUsersDashboard from './BoUsersDashboard';
// FIXME: BORRAR ESTE IMPORT SI BORRAMOS EL DASHBOARD DE CATEGORY
// FIXME: BORRAR TAMBIEN EL PATH EN LIN 115
import ArnSurveyDashboard from './ArnSurveyDashboard';
import AssignPointsDashboard from './AssignPointsDashboard';
import BoPlatformDashboard from './BoPlatform';
import CacheManagerDashboard from './CacheManagerDashboard';
import CategoryDashboard from './CategoryDashboard';
import CenterDashboard from './CenterDashboard';
import ChallengeDashboard from './ChallengeDashboard';
import ChallengeI18nDashboard from './ChallengeI18nDashboard';
import ChallengeL10nDashboard from './ChallengeL10nDashboard';
import ChallengeResourceTypeDashboard from './ChallengeResourceTypeDashboard';
import ChallengeRestrictionDashboard from './ChallengeRestrictionDashboard';
import ChallengeTemplateDashboard from './ChallengeTemplateDashboard';
import ChallengeTemplateDetailDashboard from './ChallengeTemplateDetailDashboard';
import ChallengeTypeDashboard from './ChallengeTypeDashboard';
import ConCheckoutDashboard from './ConCheckoutDashboard';
import ContentPageDashboard from './ContentPageDashboard';
import CountryDashboard from './CountryDashboard';
import DermoAdminDashboard from './DermoAdminDashboard';
import DermoValidatorDashboard from './DermoValidatorDashboard';
import DermoAdminValidatorDashboard from './DermoAdminValidatorDashboard';
import DisclaimersDashboard from './DisclaimersDashboard';
import ExceptionPointsCenterDashboard from './ExceptionPointsCenterDashboard';
import ExceptionPointsUsersDashboard from './ExceptionPointsUsersDashboard';
import FilterDashboard from './FilterDashboard';
import IsdinCentersDashboard from './IsdinCentersDashboard';
import IsdinNewCentersDashboard from './IsdinNewCentersDashboard';
import IsdinUsersDashboard from './IsdinUsersDashboard';
import LabelRedeemProductsDashboard from './LabelRedeemProductsDashboard';
import LandingProductDashboard from './LandingProductDashboard';
import LanguagesDashboard from './LanguagesDashboard';
import ListOfValuesDashboard from './ListOfValuesDashboard';
import MailingButtonsDashboard from './MailingButtonsDashboard';
import MailingDashboard from './MailingDashboard';
import MarketingMaterialsDashboard from './MarketingMaterialsDashboard';
import MenuDashboard from './MenuDashboard';
import ParametersDashboard from './ParametersDashboard';
import ParametersTypesDashboard from './ParametersTypesDashboard';
import PearlsDashboard from './PearlsDashboard';
import PermissionsDashboard from './PermissionsDashboard';
import PermissionsI18nDashboard from './PermissionsI18nDashboard';
import PharmacyDashboard from './PharmacyDashboard';
import QrProductDashboard from './QrProductDashboard';
import RequestOrderDashboard from './RequestOrderDashboard';
import ResourceDashboard from './ResourceDashboard';
import ResourceDetailDashboard from './ResourceDetailDashboard';
import ResourceDI18nDashboard from './ResourceDI18nDashboard';
import ResourceI18nDashboard from './ResourceI18nDashboard';
import ResourceRestrictionDashboard from './ResourceRestrictionDashboard';
import ResourceTypeDashboard from './ResourceTypeDashboard';
import ResourceTypeDetailDashboard from './ResourceTypeDetailDashboard';
import RolesDashboard from './RolesDashboard';
import RolesI18nDashboard from './RolesI18nDashboard';
import RolesPermissionsDashboard from './RolesPermissionsDashboard';
import ScoreDashboard from './ScoreDashboard';
import ShoppingCartOrderDashboard from './ShoppingCartOrderDashboard';
import SpecialtyDashboard from './SpecialtyDashboard';
import TagDashboard from './TagDashboard';
import TemplateChReDashboard from './TemplateChReDashboard';
import UserResourceDashboard from './UserResourceDashboard';
import UsersDashboard from './UsersDashboard';
import UsersRolesDashboard from './UsersRolesDashboard';
import WebeatActionsDashboard from './WebeatActionsDashboard';
import WebeatChallengeDashboard from './WebeatChallengeDashboard';
import WebeatChallengeTypeDashboard from './WebeatChallengeTypeDashboard';
import WebeatListOfValuesDashboard from './WebeatListOfValuesDashboard';
import WebeatMailingDashboard from './WebeatMailingDashboard';
import WebeatMenuDashboard from './WebeatMenuDashboard';
import WebeatMenuChildDashboard from './WebeatMenuChildDashboard';
import WebeatPharmacyDashboard from './WebeatPharmacyDashboard';
import WebeatQrProductDashboard from './WebeatQrProductDashboard';
import WebeatResourceTypeDashboard from './WebeatResourceTypeDashboard';
import WebeatResourceTypeDetailDashboard from './WebeatResourceTypeDetailDashboard';
import WebeatStaticPageDashboard from './WebeatStaticPageDashboard';
import ScientificMaterialsDashboard from './ScientificMaterialsDashboard';
import WebeatBackOfficeUsersDashboard from './WebeatBackofficeUsersDashboard';
import WebeatBackOfficeRolesDashboard from './WebeatBackofficeRolesDashboard';
import WebeatBackOfficePermissionsDashboard from './WebeatBackofficePermissionsDashboard';
import WebeatBackOfficePlatformsDashboard from './WebeatBackofficePlatformsDashboard';
import WebeatBackOfficeMenusDashboard from './WebeatBackofficeMenusDashboard';
import WebeatCustomersDashboard from './WebeatCustomersDashboard';
import WebeatSearchManagementDashboard from './WebeatSearchManagementDashboard';
import WebeatTagDashboard from './WebeatTagDashboard';
import WebeatParametersDashboard from './WebeatParametersDashboard';
import ConsumableDashboard from './ConsumableDashboard';
import WebeatHtmlContentDashboard from './WebeatHtmlContentDashboard';
import WebeatRestrictionsDashboard from './WebeatRestrictionsDashboard';
import InvalidateQrsDashboard from './InvalidateQrsDashboard';
import WebeatDataReplicaDashboard from './WebeatDataReplicaDashboard';
import WebeatFaqSectionDashboard from './WebeatFaqSectionDashboard';
import WebeatFaqQuestionDashboard from './WebeatFaqQuestionDashboard';
import WebeatMetaTagRuleDashboard from './WebeatMetaTagRuleDashboard';
import CampaignDashboard from './CampaignDashboard';
import ConsumableVariantDashboard from './ConsumableVariantDashboard';
import WebeatDermoEventDashboard from './WebeatDermoEventDashboard';
import ConsumableLandingDashboard from './ConsumableLandingDashboard';
import WebeatDermoValidationDashboard from './WebeatDermoValidationDashboard';
import CampaignConsumableDashboard from './CampaignConsumableDashboard';
import CatalogLimitsDashboard from './CatalogLimitsDashboard';
import WebeatDermoUsersDashboard from './WebeatDermoUsersDashboard';
import ScanLandingDashboard from './ScanLandingDashboard';
import WebeatSubscriptionModalDashboard from './WebeatSubscriptionModalDashboard';
import WebeatHomeDashboard from './WebeatHomeDashboard'

const DashboardRouter = (props: TMainWrapperListProps) => {
  const intl = useIntl();
  const renderDashboard =
    (Component: FC, blockParams?: boolean) => (renderProps: any) => {
      const { query, router } = props;
      const { location } = router;
      if (blockParams && location.search === '') {
        return <NotFound {...{ intl, query }} {...renderProps} />;
      } else return <Component {...{ intl, query }} {...renderProps} />;
    };

  return (
    <Switch>
      {config.WELCOME.SHOW && (
        <Route path="/Home" render={renderDashboard(Welcome)} />
      )}
      <Route
        exact
        path="/webeat-parameters"
        render={renderDashboard(WebeatParametersDashboard)}
      />
      <Route
        exact
        path="/webeat-data-replica"
        render={renderDashboard(WebeatDataReplicaDashboard)}
      />
      <Route
        exact
        path="/webeat-back-office-users"
        render={renderDashboard(WebeatBackOfficeUsersDashboard)}
      />
      <Route
        exact
        path="/webeat-back-office-roles"
        render={renderDashboard(WebeatBackOfficeRolesDashboard)}
      />
      <Route
        exact
        path="/webeat-back-office-permissions"
        render={renderDashboard(WebeatBackOfficePermissionsDashboard)}
      />
      <Route
        exact
        path="/webeat-back-office-platforms"
        render={renderDashboard(WebeatBackOfficePlatformsDashboard)}
      />
      <Route
        exact
        path="/webeat-back-office-menus"
        render={renderDashboard(WebeatBackOfficeMenusDashboard)}
      />
      <Route
        exact
        path="/webeat-actions"
        render={renderDashboard(WebeatActionsDashboard)}
      />
      <Route
        exact
        path="/webeat-challenge"
        render={renderDashboard(WebeatChallengeDashboard)}
      />
      <Route
        exact
        path="/webeat-challenge-type"
        render={renderDashboard(WebeatChallengeTypeDashboard)}
      />
      <Route
        exact
        path="/webeat-resource-type"
        render={renderDashboard(WebeatResourceTypeDashboard)}
      />
      <Route
        exact
        path="/webeat-resource-type-detail"
        render={renderDashboard(WebeatResourceTypeDetailDashboard)}
      />
      <Route
        exact
        path="/webeat-list-of-values"
        render={renderDashboard(WebeatListOfValuesDashboard)}
      />
      <Route
        exact
        path="/webeat-mailing"
        render={renderDashboard(WebeatMailingDashboard)}
      />
      <Route
        exact
        path="/webeat-restrictions"
        render={renderDashboard(WebeatRestrictionsDashboard)}
      />
      <Route
        exact
        path="/webeat-menu"
        render={renderDashboard(WebeatMenuDashboard)}
      />
      <Route
        exact
        path="/webeat-activateQrs"
        render={renderDashboard(InvalidateQrsDashboard)}
      />
      <Route
        exact
        path="/webeatMenuChildView"
        render={renderDashboard(WebeatMenuChildDashboard)}
      />
      <Route
        exact
        path="/webeat-pharmacy"
        render={renderDashboard(WebeatPharmacyDashboard)}
      />
      <Route
        exact
        path="/webeat-qr-products"
        render={renderDashboard(WebeatQrProductDashboard)}
      />
      <Route
        exact
        path="/webeat-static-pages"
        render={renderDashboard(WebeatStaticPageDashboard)}
      />
      <Route
        exact
        path="/webeat-tag"
        render={renderDashboard(WebeatTagDashboard)}
      />
      <Route
        exact
        path="/webeat-html-content"
        render={renderDashboard(WebeatHtmlContentDashboard)}
      />
      <Route
        exact
        path="/webeat-dermo-event"
        render={renderDashboard(WebeatDermoEventDashboard)}
      />
      <Route
        exact
        path="/webeat-dermo-validation"
        render={renderDashboard(WebeatDermoValidationDashboard)}
      />
      <Route
        exact
        path="/webeat-dermo-users"
        render={renderDashboard(WebeatDermoUsersDashboard)}
      />
      <Route
        exact
        path="/pearlsview"
        render={renderDashboard(PearlsDashboard)}
      />
      <Route
        exact
        path="/assignpointsview"
        render={renderDashboard(AssignPointsDashboard)}
      />
      <Route
        exact
        path="/isdinusersview"
        render={renderDashboard(IsdinUsersDashboard)}
      />
      <Route
        exact
        path="/isdincentersview"
        render={renderDashboard(IsdinCentersDashboard)}
      />
      <Route
        exact
        path="/isdinnewcentersview"
        render={renderDashboard(IsdinNewCentersDashboard)}
      />
      <Route
        exact
        path="/actionsview"
        render={renderDashboard(ActionsDashboard)}
      />
      <Route
        exact
        path="/bousersview"
        render={renderDashboard(BoUsersDashboard)}
      />
      <Route
        exact
        path="/borolesview"
        render={renderDashboard(BoRolesDashboard)}
      />
      <Route
        exact
        path="/bopermissionsview"
        render={renderDashboard(BoPermissionsDashboard)}
      />
      <Route exact path="/menuview" render={renderDashboard(BoMenuDashboard)} />
      <Route
        exact
        path="/categoryView"
        render={renderDashboard(CategoryDashboard)}
      />
      <Route
        exact
        path="/centerview"
        render={renderDashboard(CenterDashboard)}
      />
      <Route
        exact
        path="/challengeview"
        render={renderDashboard(ChallengeDashboard)}
      />
      <Route
        exact
        path="/challengeI18nview"
        render={renderDashboard(ChallengeI18nDashboard)}
      />
      <Route
        exact
        path="/challengeL10nview"
        render={renderDashboard(ChallengeL10nDashboard)}
      />
      <Route
        exact
        path="/challengeResourceTypeview"
        render={renderDashboard(ChallengeResourceTypeDashboard)}
      />
      <Route
        exact
        path="/challengeRestrictionview"
        render={renderDashboard(ChallengeRestrictionDashboard)}
      />
      <Route
        exact
        path="/challengeTemplateview"
        render={renderDashboard(ChallengeTemplateDashboard)}
      />
      <Route
        exact
        path="/challengeTemplateDetailview"
        render={renderDashboard(ChallengeTemplateDetailDashboard)}
      />
      <Route
        exact
        path="/challengeTypeview"
        render={renderDashboard(ChallengeTypeDashboard)}
      />
      <Route
        exact
        path="/checkoutview"
        render={renderDashboard(ConCheckoutDashboard)}
      />
      <Route
        exact
        path="/contentpageview"
        render={renderDashboard(ContentPageDashboard)}
      />
      <Route
        exact
        path="/countryview"
        render={renderDashboard(CountryDashboard)}
      />
      <Route
        exact
        path="/disclaimersview"
        render={renderDashboard(DisclaimersDashboard)}
      />
      <Route
        exact
        path="/exceptionPointsCenterview"
        render={renderDashboard(ExceptionPointsCenterDashboard)}
      />
      <Route
        exact
        path="/exceptionPointsUsersview"
        render={renderDashboard(ExceptionPointsUsersDashboard)}
      />
      <Route
        exact
        path="/activateQrs"
        render={renderDashboard(InvalidateQrsDashboard)}
      />
      <Route
        exact
        path="/landingProductsview"
        render={renderDashboard(LandingProductDashboard)}
      />
      <Route
        exact
        path="/languagesview"
        render={renderDashboard(LanguagesDashboard)}
      />
      <Route
        exact
        path="/listofvaluesview"
        render={renderDashboard(ListOfValuesDashboard)}
      />
      <Route
        exact
        path="/liwwcataloglimits"
        render={renderDashboard(CatalogLimitsDashboard)}
      />
      <Route
        exact
        path="/liwwmenuview"
        render={renderDashboard(MenuDashboard)}
      />
      <Route
        exact
        path="/marketingmaterialsview"
        render={renderDashboard(MarketingMaterialsDashboard)}
      />
      <Route
        exact
        path="/scientificmaterialsview"
        render={renderDashboard(ScientificMaterialsDashboard)}
      />
      <Route
        exact
        path="/permissionsview"
        render={renderDashboard(PermissionsDashboard)}
      />
      <Route
        exact
        path="/adminValidators"
        render={renderDashboard(DermoAdminDashboard)}
      />
      <Route
        exact
        path="/dermos"
        render={renderDashboard(DermoValidatorDashboard)}
      />
      <Route
        exact
        path="/adminDermos"
        render={renderDashboard(DermoAdminValidatorDashboard)}
      />
      <Route
        exact
        path="/permissionsI18nview"
        render={renderDashboard(PermissionsI18nDashboard)}
      />
      <Route
        exact
        path="/platformview"
        render={renderDashboard(BoPlatformDashboard)}
      />
      <Route
        exact
        path="/requestorderview"
        render={renderDashboard(RequestOrderDashboard)}
      />
      <Route
        exact
        path="/resourceview"
        render={renderDashboard(ResourceDashboard)}
      />
      <Route
        exact
        path="/resourceDI18nview"
        render={renderDashboard(ResourceDI18nDashboard)}
      />
      <Route
        exact
        path="/resourceDetailview"
        render={renderDashboard(ResourceDetailDashboard)}
      />
      <Route
        exact
        path="/resourceI18nview"
        render={renderDashboard(ResourceI18nDashboard)}
      />
      <Route
        exact
        path="/resourceRestrictionview"
        render={renderDashboard(ResourceRestrictionDashboard)}
      />
      <Route
        exact
        path="/resourceTypeview"
        render={renderDashboard(ResourceTypeDashboard)}
      />
      <Route
        exact
        path="/resourceTypeDetailview"
        render={renderDashboard(ResourceTypeDetailDashboard)}
      />
      <Route exact path="/rolesview" render={renderDashboard(RolesDashboard)} />
      <Route
        exact
        path="/rolesI18nview"
        render={renderDashboard(RolesI18nDashboard)}
      />
      <Route
        exact
        path="/rolesPermissionsview"
        render={renderDashboard(RolesPermissionsDashboard)}
      />
      <Route
        exact
        path="/shoppingcartorderview"
        render={renderDashboard(ShoppingCartOrderDashboard)}
      />
      <Route
        exact
        path="/specialtyview"
        render={renderDashboard(SpecialtyDashboard)}
      />
      <Route exact path="/scoreview" render={renderDashboard(ScoreDashboard)} />
      <Route
        exact
        path="/templateChReview"
        render={renderDashboard(TemplateChReDashboard)}
      />
      <Route
        exact
        path="/userResourceview"
        render={renderDashboard(UserResourceDashboard)}
      />
      <Route exact path="/usersview" render={renderDashboard(UsersDashboard)} />
      <Route
        exact
        path="/usersRolesview"
        render={renderDashboard(UsersRolesDashboard)}
      />
      <Route
        exact
        path="/Mailingview"
        render={renderDashboard(MailingDashboard)}
      />
      <Route
        exact
        path="/mailingbuttonsview"
        render={renderDashboard(MailingButtonsDashboard)}
      />
      <Route exact path="/tagview" render={renderDashboard(TagDashboard)} />
      <Route
        exact
        path="/arnsurveyview"
        render={renderDashboard(ArnSurveyDashboard)}
      />
      <Route
        exact
        path="/pharmacyview"
        render={renderDashboard(PharmacyDashboard)}
      />
      <Route
        exact
        path="/qrProductview"
        render={renderDashboard(QrProductDashboard)}
      />
      <Route
        exact
        path="/filterview"
        render={renderDashboard(FilterDashboard)}
      />
      <Route
        exact
        path="/parametersview"
        render={renderDashboard(ParametersDashboard)}
      />
      <Route
        exact
        path="/labelredeemproductsview"
        render={renderDashboard(LabelRedeemProductsDashboard)}
      />
      <Route
        exact
        path="/managecacheview"
        render={renderDashboard(CacheManagerDashboard)}
      />
      <Route
        exact
        path="/parameterstypesview"
        render={renderDashboard(ParametersTypesDashboard)}
      />
      <Route
        exact
        path="/webeat-customers"
        render={renderDashboard(WebeatCustomersDashboard)}
      />
      <Route
        exact
        path="/consumables-dashboard"
        render={renderDashboard(ConsumableDashboard)}
      />
      <Route
        exact
        path="/consumable-campaigns-dashboard"
        render={renderDashboard(CampaignDashboard)}
      />
      <Route
        exact
        path="/campaign-consumable-dashboard"
        render={renderDashboard(CampaignConsumableDashboard)}
      />
      <Route
        exact
        path="/consumable-landing"
        render={renderDashboard(ConsumableLandingDashboard)}
      />
      <Route
        exact
        path="/scan-landing"
        render={renderDashboard(ScanLandingDashboard)}
      />
      <Route
        path="/variants-dashboard"
        render={renderDashboard(ConsumableVariantDashboard)}
      />
      <Route
        exact
        path="/search-management"
        render={renderDashboard(WebeatSearchManagementDashboard)}
      />
      <Route
        exact
        path="/webeat-faq-section"
        render={renderDashboard(WebeatFaqSectionDashboard)}
      />
      <Route
        exact
        path="/webeat-faq-question"
        render={renderDashboard(WebeatFaqQuestionDashboard)}
      />
      <Route
        exact
        path="/meta-tag-rule"
        render={renderDashboard(WebeatMetaTagRuleDashboard)}
      />
      <Route
        exact
        path="/webeat-home"
        render={renderDashboard(WebeatHomeDashboard)}
      />
      <Route
        exact
        path="/webeat-subscription-modal"
        render={renderDashboard(WebeatSubscriptionModalDashboard)}
      />
      <Route path="*" render={renderDashboard(NotFound)} />
    </Switch>
  );
};

export default DashboardRouter;
