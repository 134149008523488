import React, { FC, useEffect } from "react";
import { Collapse } from 'antd';
import {
  HeroSectionKey,
  CategoriesSectionKey,
  HeroBlockSectionKey,
  ProductsSectionKey,
  CommunitySectionKey,
  ExperienceSectionKey,
  TestimonialsSectionKey,
  HeroEndBlockSectionKey,
  HomeSectionListKey,
  HomeSectionListValues,
} from './WebeatHomeSectionEnum';
import { WebeatHomeSectionFields } from "./WebeatHomeSectionFields";
import { IHomeSection } from "./WebeatHomeSectionsConfig";
import './WebeatHomeSections.css';

const { Panel } = Collapse; // Desestructurar Panel de Collapse

export interface IWebeatHomeSectionsValuesProps {
  sections?: IHomeSection[]
}

export const WebeatHomeSections: FC<{
  values: IWebeatHomeSectionsValuesProps;
  parentProps: any;
  field: { key: string; type: string };
  handleChangeField: (params: {
    type: string;
    id: string;
    value: any;
    format?: string;
  }) => Promise<void> | void;
}> = ({ values, parentProps, field, handleChangeField }) => {
  
  useEffect(() => {
    if (!values.sections) {
      handleChangeField({
        type: field.type,
        id: field.key,
        value: HomeSectionListValues
      });
    }
  }, [field.key, field.type, handleChangeField, values.sections]);

  const handleChange = (sectionKey: HomeSectionListKey, active: boolean, content: string, ) => {
    const updatedSections = (values.sections || HomeSectionListValues).map((section: IHomeSection) => {
      if (section.key === sectionKey) {
        return {
          ...section,
          active,
          content
        };
      }
      return section;
    });

    handleChangeField({
      type: field.type,
      id: field.key,
      value: updatedSections
    });
  }


  return (
    <div className="webeat-home-sections__container">
      <Collapse accordion>
        {
          [
            HeroSectionKey,
            CategoriesSectionKey,
            HeroBlockSectionKey,
            ProductsSectionKey,
            CommunitySectionKey,
            ExperienceSectionKey,
            TestimonialsSectionKey,
            HeroEndBlockSectionKey
          ].map((sectionKey) => {
            const section = values.sections?.find((section: IHomeSection) => section.key === sectionKey);
            return (
              <Panel header={sectionKey} key={sectionKey} className={
                section?.active ? 'webeat-home-sections__panel--active' : 'webeat-home-sections__panel--inactive'
              }>
                <WebeatHomeSectionFields sectionKey={sectionKey as HomeSectionListKey} handleChange={handleChange} active={!!section?.active} content={section?.content || JSON.stringify({})} />
              </Panel>
            );
          })
        }
      </Collapse>
    </div>
  );
};
