import React from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch, AnyAction } from 'redux';
import { injectIntl } from 'react-intl';
import { isEmpty } from 'lodash';
import { Form, Button, notification, Modal, List } from 'antd';
import { FormComponentProps } from 'antd/lib/form/Form';

import apiPaths from '../../apiPaths';
import config from '../../config';
import configDev from '../../configDev';
import { appComponents } from '../../components';
import { loadConfig, saveConfig } from '../../utils/localStorage';
import { ReducersState } from '../../reducers';
import * as api from '../../api';
import { getPlatformBaseUrl, isPINPlatform } from '../../utils';

import {
  deleteTableData,
  getTableData,
  setFormStateFlag,
  setChildSelectedRow,
  updateTableQueryParams,
} from '../../tables/tableActions';
import { feedback } from '../../utils/feedback';
import {
  setInitialState,
  setFormData,
  updateEditData,
  createEditData,
  fetchForeignData,
  setFieldsConfig,
  setSelectedTabEdit,
  resetEditForm,
  setResetFormTrigger,
  fetchParentData,
} from './editActions';
import {
  editNavigationState,
  navigateAfterCreate,
} from '../../app/queryActions';
import { setDrawerVisibility } from '../../dashboards/dashboardActions';
import { getComboData } from '../../combos/comboActions';
import { logout } from '../../auth/authActions';

import EditFormRender from './EditFormRender';
import { formConstructor } from '../formConstructor';
import '../FormStyles.css';
import '../edit/LiwwEditResultModal.css';

import {
  IFormSettings,
  IComponentGroup,
  IEditParams,
  IGroupField,
  ICompleteField,
} from '../formInterfaces';
import { IEditField } from '../../fields/FieldsInterfaces';
import { TDashboardComposerListProps } from '../../dashboards/DashboardComposer';
import { IRow, IRecord } from '../../app/AppInterfaces';
import { MaterialsEnum } from '../../marketingMaterials/marketingMaterials.enum';
import { getMutatedValues } from '../../utils/mutationUtils';
import { loadTimezoneName } from '../../utils';
import liwwUpdateInfoModal, {
  ILiwwUpdateResponse,
} from './liwwUserUpdateInfoModal';

const error = notification.error;
const flatten = require('flat');
const { PRODUCT_IMAGE, VIDEO_YOUTUBE, VIDEO_UPLOAD, VIDEO_VIMEO } =
  MaterialsEnum;

const currentPlatform = getPlatformBaseUrl();

const isTableTarget = (targetId: string) =>
  targetId !== '' && appComponents[targetId].type === 'table';

interface OwnProps {
  params: IEditParams;
  fields: IEditField[];
  groups: IComponentGroup[];
  options: IFormSettings;
}

export type IEditFormListProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  OwnProps &
  FormComponentProps &
  TDashboardComposerListProps;

class EditForm extends React.PureComponent<IEditFormListProps, {}> {
  constructor(props: IEditFormListProps) {
    super(props);
    const {
      params,
      setInitialState,
      fieldsConfig,
      groups,
      fields,
      userPermissions,
    } = props;
    const { componentId } = params;
    let userConfig = loadConfig();
    let sortedFieldsConfig = [];

    if (isEmpty(fieldsConfig)) {
      if (
        configDev.SAVE_CONFIG &&
        userConfig !== undefined &&
        userConfig[config.USER.USERID] &&
        userConfig[config.USER.USERID][componentId] &&
        userConfig[config.USER.USERID][componentId]['editFormConfig']
      ) {
        sortedFieldsConfig =
          userConfig[config.USER.USERID][componentId]['editFormConfig'];
      } else {
        sortedFieldsConfig = formConstructor(
          groups,
          fields,
          userPermissions!,
          params,
        );

        if (configDev.SAVE_CONFIG) {
          if (userConfig === undefined) {
            userConfig = {};
          }
          const newConfig = {
            ...userConfig,
            [config.USER.USERID]: {
              ...userConfig[config.USER.USERID],
              [componentId]: {
                ...userConfig[config.USER.USERID][componentId],
                editFormConfig: sortedFieldsConfig,
              },
            },
          };
          saveConfig(newConfig);
        }
      }

      setInitialState({
        componentId,
        targetId: appComponents[componentId].targetId,
        fieldsConfig: sortedFieldsConfig,
        values: {},
        selectedTab: '0',
        resetFormTrigger: false,
      });
    }
  }

  async componentDidMount() {
    const {
      params,
      setFormData,
      fields,
      selectedRow,
      userPermissions,
      groups,
    } = this.props;
    const { componentId } = params;

    let values = isEmpty(selectedRow)
      ? await this.setFormInitialValues(fields, userPermissions!)
      : flatten({ ...selectedRow }, { safe: true });
    if (!isEmpty(values)) setFormData({ componentId, values });

    const firstKey = await this.getFormFirstKey(
      formConstructor(groups, fields, userPermissions!, params),
    );

    if (firstKey !== '') {
      const element: HTMLElement = document.getElementById(`${firstKey}`)!;
      if (element.nodeName === 'INPUT') element.focus();
      else if (element.nodeName === 'SPAN') {
        const child: HTMLElement = element.childNodes[0]
          .childNodes[0] as HTMLElement;
        child.focus();
      } else if (element.nodeName === 'DIV') {
        const child: HTMLElement = element.childNodes[0] as HTMLElement;
        child.focus();
      }
    }
  }

  async componentDidUpdate(prevProps: IEditFormListProps) {
    const {
      targetId,
      params,
      selectedRow,
      setFormData,
      form,
      fields,
      queryParams,
      values,
      hasNavigated,
      editNavigationState,
      fetchForeignData,
      setFieldsConfig,
      fieldsConfig,
      router,
      setResetFormTrigger,
      resetFormTrigger,
      userPermissions,
    } = this.props;
    const { componentId } = params;
    const { q } = queryParams;
    let control = false;
    let query: { [key: string]: string } = {};
    let initialValues: { [key: string]: string } = {};

    //TODO - Esto podria eleminarse......
    if (!isEmpty(q)) {
      q!.split(config.QUERY.AND).forEach((element) => {
        const key = element.split(config.QUERY.ID_OPERATOR)[0];
        const value = element.split(config.QUERY.ID_OPERATOR)[1];
        fields.forEach(async (field) => {
          if (field.key === key && value)
            initialValues = { ...initialValues, [key]: value.toString() };
        });
        //END TODO -----

        if (value) {
          query = { ...query, [key]: value };
        }
      });
    }

    if (isTableTarget(targetId)) {
      if (hasNavigated && !isEmpty(query)) {
        //Este block es un setInitialValues .....
        fields.forEach(async (field) => {
          if (
            field.hasOwnProperty('initialValue') &&
            initialValues[field.key] !== undefined
          )
            initialValues = {
              ...initialValues,
              [field.key]: field.initialValue!,
            };
          if (
            field.mustRender !== false &&
            field.path !== undefined &&
            initialValues[field.key] !== undefined
          ) {
            const response: any = await fetchForeignData({
              dataPath: field.path,
              componentId,
              foreignKey: field.key.split('.')[0],
              foreignValue: initialValues[field.key],
            });
            if ('data' in response)
              initialValues = { ...initialValues, ...response.data };
          }
        });
        setFormData({
          componentId,
          values: initialValues,
        });

        //END TODO BLOCK --- set initial Values

        if (config.COMPONENT.EDIT.DISABLE_QUERY_PARAMS_FIELD) {
          const fieldsConfigBehaviourSelector = (field: ICompleteField) => {
            for (let key in query) {
              if (field.key === key && !field.disabled) {
                field.disabled = true;
                control = true;
              }
            }
            return field;
          };

          const newFieldsConfig = this.changeFieldsConfig(
            fieldsConfig,
            fieldsConfigBehaviourSelector,
          );
          if (control) setFieldsConfig(componentId, newFieldsConfig);
        }

        editNavigationState({ component: 'editFormNavigation' });
      } else if (!isEmpty(query)) {
        if (config.COMPONENT.EDIT.DISABLE_QUERY_PARAMS_FIELD) {
          const fieldsConfigBehaviourSelector = (field: ICompleteField) => {
            for (let key in query) {
              if (
                field.key === key &&
                !field.disabled &&
                !field.hasOwnProperty('parentValue')
              ) {
                field.disabled = true;
                control = true;
              }
            }
            return field;
          };

          const newFieldsConfig = this.changeFieldsConfig(
            fieldsConfig,
            fieldsConfigBehaviourSelector,
          );
          if (control) setFieldsConfig(componentId, newFieldsConfig);
        }
      } else if (
        (!router && !isEmpty(values)) ||
        (router && !isEmpty(values) && isEmpty(q))
      ) {
        const fieldsConfigBehaviourSelector = (field: ICompleteField) => {
          if (
            field.forceDisabled === undefined &&
            field.parentValue === undefined &&
            field.hasOwnProperty('disabled') &&
            field.type === 'combo' &&
            field.disabled !== field.initialDisabled &&
            field.disabled !== field.behaviourDisabled
          ) {
            field.disabled = field.initialDisabled;
            control = true;
          }
          return field;
        };

        const newFieldsConfig = this.changeFieldsConfig(
          fieldsConfig,
          fieldsConfigBehaviourSelector,
        );
        if (control) setFieldsConfig(componentId, newFieldsConfig);
      }
    }

    if (prevProps.selectedRow && selectedRow !== prevProps.selectedRow) {
      if (isEmpty(selectedRow)) {
        const fieldsConfigBehaviourSelector = (field: ICompleteField) =>
          this.setBehaviourInitialState(field);

        const newFieldsConfig = this.changeFieldsConfig(
          fieldsConfig,
          fieldsConfigBehaviourSelector,
        );
        await setFieldsConfig(componentId, newFieldsConfig);
      }

      let values = isEmpty(selectedRow)
        ? await this.setFormInitialValues(fields, userPermissions!)
        : flatten(selectedRow, { safe: true });
      if (config.COMPONENT.EDIT.DISABLE_QUERY_PARAMS_FIELD) {
        fields.forEach((field) => {
          for (let key in query) {
            if (field.key === key)
              values = { ...values, [key]: query[key].toString() };
          }
        });
      }
      await setFormData({ componentId, values });
      form.resetFields();
    }
    if (resetFormTrigger) {
      await setResetFormTrigger({ componentId });
      form.resetFields();
    }
  }

  getFormFirstKey = (fieldsConfig: IGroupField[]): string => {
    let firstKey: string = '';
    fieldsConfig.forEach((field) => {
      !firstKey &&
        field.subgroupRows.forEach((subgroupRow) => {
          !firstKey &&
            subgroupRow.forEach((subgroup) => {
              !firstKey &&
                subgroup.fieldRow.forEach((fieldRow) => {
                  !firstKey &&
                    fieldRow.forEach((field) => {
                      if (
                        !firstKey &&
                        !field.disabled &&
                        field.alwaysDisabled !== true &&
                        field.visible !== false
                      )
                        firstKey = field.key;
                    });
                });
            });
        });
    });
    return firstKey;
  };

  setBehaviourInitialState = (field: ICompleteField) => {
    if (field.hasOwnProperty('initialVisibility')) {
      field.visible = field.initialVisibility;
    }
    if (field.hasOwnProperty('initialDisabled')) {
      field.disabled = field.initialDisabled;
    }
    if (field.hasOwnProperty('initialMandatory')) {
      field.mandatory = field.initialMandatory;
    }
    return field;
  };

  /**This function sets the initial values for the Edit Form.
   * If its type is a boolean sets `false` by default, if has InitialValue sets its `initialValue`
   * @param {object} fields - EditForm fields
   * @return {object} Initial component values
   */
  async setFormInitialValues(fields: IEditField[], userPermissions: string[]) {
    const {
      appParams,
      userParams,
      fetchForeignData,
      params,
      queryParams,
      fetchParentData,
      parentData,
    } = this.props;
    const { componentId } = params;
    const { q } = queryParams;
    let values: IRow = {};
    let value: string;
    let key;
    let path: string | null = null;
    let fieldPath: IEditField | null = null;

    if (!isEmpty(q)) {
      q!.split(config.QUERY.AND).forEach((element) => {
        value = element.split(config.QUERY.ID_OPERATOR)[1];
        key = element.split(config.QUERY.ID_OPERATOR)[0];
        values = { ...values, [key]: value.toString() };
      });
    }

    await Promise.all(
      fields.map(async (field) => {
        if (
          (field.mustRender === undefined || field.mustRender) &&
          (field.type === 'check' || field.type === 'checkSelect')
        ) {
          values[field.key] = false;
        }
        //1. check permissions field with user permissions
        const { permissions } = field;
        let control;
        if (permissions) {
          permissions.forEach((permission) => {
            if (
              userPermissions.includes(permission.name) &&
              permission.value !== undefined
            ) {
              control = permission.value;
            }
          });
        }

        if (value) {
          if (field.path !== undefined) {
            path = field.path;
            fieldPath = field;
          }
        }

        const currentTimezone = loadTimezoneName();

        if (control) {
          values = { ...values, [field.key]: control };
        } else if (field.initialValue !== undefined) {
          if (field.type === 'date' && field.initialValue === 'now')
            values = {
              ...values,
              [field.key]: moment().tz(currentTimezone).utc(),
            };
          else values = { ...values, [field.key]: field.initialValue };
        } else if (field.initialAppParam !== undefined) {
          if (appParams && field.initialAppParam in appParams)
            values = {
              ...values,
              [field.key]: appParams[field.initialAppParam],
            };
        } else if (field.initialUserParam !== undefined) {
          if (field.initialUserParam in userParams!)
            values = {
              ...values,
              [field.key]: userParams![field.initialUserParam],
            };
        }
      }),
    );

    //TODO FIX - Esta seccion es provisional (no funciona el await dentro de la funcion field.forEach())

    if (fieldPath !== null && path !== undefined && fieldPath!.key === key) {
      let data;
      if (isEmpty(parentData)) {
        const foreignKey =
          fieldPath!.key!.indexOf('.') > 0
            ? fieldPath!.key!.slice(0, fieldPath!.key!.lastIndexOf('.'))
            : fieldPath!.key;
        const response: any = await fetchForeignData({
          dataPath: fieldPath!.path!,
          componentId,
          foreignKey,
          foreignValue: value!.toString(),
        });
        if ('data' in response) data = response.data;
        fetchParentData({ componentId, data });
      } else data = { ...parentData };

      values = { ...values, ...data };
    }
    return values;
  }

  /**This functions manages changes in Form fields and checks if Form fields have changed
   * @param id - Field key
   * @param value - Field's value
   * @param path - Call path
   * @param type - Field's type, used to delete or set field to false, where parent field changes field visibility
   */
  handleChangeField = async ({
    id,
    value,
    type,
    path,
    multiSelectId,
    deleteImage,
  }: {
    id: string;
    value: any;
    type: string;
    path?: string;
    multiSelectId?: any;
    deleteImage?: string;
  }) => {
    const {
      params,
      values,
      targetId,
      setFormStateFlag,
      fetchForeignData,
      setFormData,
      selectedRow,
      formHasChanged,
    } = this.props;
    const { componentId } = params;
    let formChange = false;
    if (
      ['menumenuEdit', 'webeatMenuEdit'].includes(componentId) &&
      values?.[id] !== value
    ) {
      switch (id) {
        case 'absolute':
          delete values.route;
          break;
        case 'type':
          delete values.order;
          break;
      }
    }

    if (['menumenuEdit'].includes(componentId) && values?.[id] !== value) {
      switch (id) {
        case 'route':
          const combos = this.props.combos.combos[componentId]['menuPages'][
            'menuPages'
          ].data.filter(
            (menuPage) =>
              menuPage.value === value &&
              menuPage.hasOwnProperty('idContentPage'),
          );
          if (combos?.length) values.contentPageId = combos[0].idContentPage;
          else values.contentPageId = null;
          break;
      }
    }
    if (
      ['webeatMenuEdit', 'webeatMenuChildEdit'].includes(componentId) &&
      values?.[id] !== value
    ) {
      switch (id) {
        case 'pageType':
          if (value === 'GRID') {
            values['idGrid.type'] = '0';
          } else {
            values.route = null;
            values.routeSelector = null;
            Object.keys(values).forEach((val) => {
              if (val.split('.')[0] === 'idGrid') {
                delete values[val];
              }
            });
          }
          break;
        case 'routeSelector':
          const webeatCombos =
            this.props.combos.combos[componentId]['webeatMenuPages'][
              'webeatMenuPages'
            ].data;
          const comboIdStaticPage = webeatCombos.filter(
            (menuPage) =>
              menuPage.value === value && menuPage.pageType === 'STATIC_PAGE',
          );

          const comboIdGrid = webeatCombos.filter(
            (menuPage) =>
              menuPage.value === value && menuPage.pageType === 'GRID',
          );
          const comboMenuUrl = webeatCombos.filter(
            (menuPage) =>
              menuPage.value === value && menuPage.hasOwnProperty('menuUrl'),
          );

          // Fill static page
          if (values.pageType === 'STATIC_PAGE' && comboIdStaticPage?.length) {
            values.idStaticPage = comboIdStaticPage[0].idStaticPage;
            if (!values?.name) {
              values['name'] = comboIdStaticPage[0].description;
            }
          } else {
            values.idStaticPage = null;
          }

          // Fill grid
          if (values.pageType === 'GRID' && comboIdGrid?.length) {
            values.idGrid = comboIdGrid[0].idGrid;
            if (!values?.name) {
              values['name'] = comboIdGrid[0].description;
            }
          } else {
            values.idGrid = null;
          }

          if (comboMenuUrl?.length) {
            values.route = comboMenuUrl[0].menuUrl;
          } else {
            values.route = null;
          }
          break;
      }
    }

    if (
      ['webeatRestrictionsEdit'].includes(componentId) &&
      values?.[id] !== value
    ) {
      switch (id) {
        case 'entityFilterType':
          values.value = '';
          values.restrictionStateSelector = null;
          values.restrictionListOfValueSelector = null;
          break;
        case 'restrictionListOfValueSelector':
          values.value = value;
          break;
        case 'restrictionStateSelector':
          values.value = value;
          break;
      }
    }

    if (Array.isArray(value)) {
      setFormStateFlag({
        componentId: targetId,
        formHasChanged: true,
      });
    }

    if (values[id] && value === values[id]) return;

    if (
      value === '' &&
      (values[id] === undefined || values[id] === null || values[id] === '')
    )
      return;

    let newValues = { ...values };

    if (deleteImage) {
      setFormData({
        componentId,
        values: {
          ...values,
          [id]: value,
          imagesToDelete: values?.imagesToDelete
            ? [...values.imagesToDelete, deleteImage]
            : [deleteImage],
        },
      });
    } else if (path) {
      if (value > 0) {
        if (id.includes('.')) {
          const foreignKey = id.slice(0, id.lastIndexOf('.'));
          const response: any = await fetchForeignData({
            dataPath: path,
            componentId,
            foreignKey,
            foreignValue: value,
          });
          newValues = { ...newValues, ...response.data };
        }
      } else {
        Object.keys(values).forEach((val) => {
          if (id.split('.')[0] === val.split('.')[0]) {
            delete newValues[val];
          }
        });
      }
      setFormData({
        componentId,
        values: newValues,
      });
    } else {
      if (multiSelectId) {
        newValues = this.getMultiSelectValues({
          id,
          values,
          value,
          multiSelectId,
        });
        setFormData({
          componentId,
          values: newValues,
        });
      } else if (values[id] !== value || (!values[id] && value == null)) {
        if (value === null && values[id] !== undefined) {
          if (type === 'check' || type === 'checkSelect') newValues[id] = false;
          // else delete values[id];
          newValues = this.checkCustomBehaviours({ values, componentId, id });

          setFormData({
            componentId,
            values: {
              ...newValues,
              [id]: value,
            },
          });
        } else {
          if (id.includes('.')) {
            const fk = id.slice(0, id.lastIndexOf('.'));
            if (values[fk] === null) delete newValues[fk];
          }
          newValues = this.checkCustomBehaviours({ values, componentId, id });
          setFormData({
            componentId,
            values: {
              ...newValues,
              [id]: value,
            },
          });
        }
      }
    }

    //FORM HAS CHANGE VERIFICATION

    if (!isEmpty(selectedRow)) {
      if (value !== values[id] && !formHasChanged) {
        setFormStateFlag({
          componentId: targetId,
          formHasChanged: true,
        });
      }
    } else if (isEmpty(selectedRow)) {
      if (value && !values[id]) formChange = true;
      else
        for (let key in values) {
          if (
            values[key] ||
            values[key] === 0 ||
            typeof selectedRow[key] === 'object'
          )
            formChange = true;
        }
      setFormStateFlag({
        componentId: targetId,
        formHasChanged: formChange,
      });
    }
  };

  checkNestedField = (key: string, val: any, selectedRow: IRow) => {
    const index = key.indexOf('.');
    const preKey = key.substring(0, index);
    const postKey = key.substring(index + 1, key.length);

    for (let k in selectedRow[preKey]) {
      if (k !== postKey) continue;

      if (
        moment.isMoment(val) &&
        this.checkDateField(val, selectedRow[preKey][k]) === true
      )
        return true;

      if (!moment.isMoment(val) && selectedRow[preKey][k] !== val) return true;
    }
  };

  checkDateField = (value: any, string: string) => {
    let selectedTime = moment(string);
    if (value._f === 'MM/YY') selectedTime = moment(string, 'MM/YY');
    switch (value._f) {
      case 'YYYY-MM-DD':
        if (
          value.year() !== selectedTime.year() ||
          value.month() !== selectedTime.month() ||
          value.date() !== selectedTime.date()
        ) {
          return true;
        }
        return false;
      case 'MM/YY':
        if (
          value.year() !== selectedTime.year() ||
          value.month() !== selectedTime.month()
        ) {
          return true;
        }
        return false;
      case 'YYYY-MM-DDTHH:mm:ss.SSS+0000':
        if (
          value.hours() !== selectedTime.hours() ||
          value.minutes() !== selectedTime.minutes() ||
          value.seconds() !== selectedTime.seconds()
        ) {
          return true;
        }
        return false;
      default:
        return false;
    }
  };

  transformMomentToUTC = (values: IRow) => {
    Object.keys(values).forEach((key) => {
      if (moment.isMoment(values[key])) {
        values[key] = values[key].toISOString();
      }
    });
    return values;
  };

  handleReloadCombo = () => {
    const editComponent = this.props.components.find(
      (component) => component.params.type === 'edit',
    );
    const editFields = editComponent?.fields as IEditField[];
    const combosToReload: IEditField[] = editFields?.filter(
      (field: IEditField) => field.type === 'combo' && field.reloadComponentIds,
    );
    Promise.all(
      combosToReload?.map(async ({ comboId, key, reloadComponentIds }) => {
        if (comboId && reloadComponentIds) {
          reloadComponentIds.forEach(async (id) => {
            const comboDataParameters = {
              dataPath: 'combodata',
              componentId: id,
              fieldKey: key,
              queryParams: { id: comboId },
              comboId,
            };
            await this.props.getComboData(comboDataParameters);
          });
        }
      }),
    );
  };

  handleUpdateRecord = (
    e?: React.MouseEvent,
    customButton?: { [key: string]: boolean },
    drawerOptions?: {
      setDrawerVisibility: Function;
      dashboardId: string;
      visible: boolean;
    },
  ): void | JSX.Element => {
    if (customButton !== undefined) e!.preventDefault();
    const {
      targetId,
      params,
      form,
      updateEditData,
      getTableData,
      formHasChanged,
      setFormStateFlag,
      setSelectedRow,
      intl
    } = this.props;

    let { values } = this.props;
    const { componentId, primaryKey } = params;

    const performUpdateRecordOperation = async() => form.validateFields(async (err, formValues) => {
      if (!err && this.customValidations(values, componentId)) {
        values = this.transformMomentToUTC(values);
        const mutatedValues = getMutatedValues(componentId, values);
        const result: unknown = await updateEditData({
          dataPath: appComponents[componentId].path,
          componentId,
          values: mutatedValues,
        });
        //MODAL TO INFORM ABOUT THE LIWW EMAIL UPDATE
        const typedResult = result as ILiwwUpdateResponse;
        if (
          'status' in typedResult &&
          appComponents[componentId].path === apiPaths.CALL.USERS &&
          isPINPlatform()
        )
          liwwUpdateInfoModal(typedResult);

          if (
            'status' in typedResult &&
            typedResult['status'] === 200 &&
            isTableTarget(targetId)
          ) {
            if (values?.imagesToDelete) {
              api
                .deleteCall({
                  dataPath: `${currentPlatform}${apiPaths.UPLOAD_MEDIA}`,
                  callConfig: { data: { path: values.imagesToDelete } },
                })
                .catch((err) => {
                  console.error(err.message);
                });
            }
            const newRecords: any = await getTableData({
              dataPath: appComponents[targetId].path,
              componentId: targetId,
            });

            let newSelectedRow: IRow = {};
            'data' in newRecords &&
              newRecords.data.content.forEach((record: IRecord) => {
                if (record[primaryKey] === values[primaryKey])
                  newSelectedRow = record;
              });

            setSelectedRow({
              componentId: targetId,
              selectedRow: newSelectedRow,
            });

            if (config.FEEDBACK.CONFIRM.RECORD_CHANGED && formHasChanged) {
              setFormStateFlag({
                componentId: targetId,
                formHasChanged: false,
              });
            }
            config.FEEDBACK.REVERT.SHOW &&
              formHasChanged &&
              this.openNotification();
            if (drawerOptions)
              drawerOptions.setDrawerVisibility({
                dashboardId: drawerOptions.dashboardId,
                visible: drawerOptions.visible,
              });
          }
          this.handleReloadCombo();
        } else this.handleGroupMandatory();
      });
    if (this.props.options.askConfirm) {
      Modal.confirm({
        title: intl.formatMessage({ id: 'modal.confirm.edit.title' }),
        content: intl.formatMessage({ id: 'modal.confirm.edit.warn' }),
        okText: intl.formatMessage({ id: 'pop.accept' }),
        cancelText: intl.formatMessage({ id: 'pop.cancel' }),
        async onOk() {
          await performUpdateRecordOperation()
        },
        onCancel(){}
      })
    } else {
      performUpdateRecordOperation()
    }

  };

  handleCreateRecord = (
    e?: React.MouseEvent,
    customButton?: { [key: string]: boolean },
    select?: boolean,
    drawerOptions?: {
      setDrawerVisibility: Function;
      dashboardId: string;
      visible: boolean;
    },
  ) => {
    if (customButton === undefined) e!.preventDefault();
    const {
      targetId,
      params,
      selectedRow,
      form,
      createEditData,
      data,
      formHasChanged,
      setFormStateFlag,
      setSelectedRow,
      getTableData,
      queryParams,
      prettierKey,
      setChildSelectedRow,
      updateTableQueryParams,
    } = this.props;
    let { values } = this.props;
    const { componentId, primaryKey } = params;
    let qParams = {};

    form.validateFields(async (err, formValues) => {
      if (!err && this.customValidations(values, componentId)) {
        values = this.transformMomentToUTC(values);
        if (!isEmpty(selectedRow)) delete values[primaryKey];
        feedback({
          type: 'message',
          method: 'loading',
          message: 'generic.loading',
        });

        const mutatedValues = getMutatedValues(componentId, values);

        const result = await createEditData({
          dataPath: appComponents[componentId].path,
          componentId,
          values: mutatedValues,
        });
        const selectRow = select !== undefined ? true : false;
        if (
          'status' in result &&
          result['status'] === 200 &&
          isTableTarget(targetId)
        ) {
          if (selectRow || (customButton && customButton.createAndSelect)) {
            const requestData: any = await getTableData({
              dataPath: `${appComponents[targetId].path}/Page/${result['data'][primaryKey]}`,
              componentId: targetId,
              queryParams,
            });

            updateTableQueryParams({
              componentId: targetId,
              newQueryParams: {
                page: requestData.data.number,
              },
            });

            'data' in requestData &&
              requestData.data.content.forEach((element: IRecord) => {
                if (element[primaryKey] === result['data'][primaryKey]) {
                  setSelectedRow({
                    componentId: targetId,
                    selectedRow: element,
                  });
                  const targetsId = appComponents[targetId].targetsId;
                  setChildSelectedRow({
                    targetsId,
                    record: element,
                    prettierKey,
                    rowKey: primaryKey,
                  });
                }
              });
          } else {
            const page =
              data.totalElements % data.size === 0
                ? data.totalPages
                : data.totalPages - 1;
            qParams = {
              page: page,
              sort: null,
              field: null,
            };
            await getTableData({
              dataPath: appComponents[targetId].path,
              componentId: targetId,
              queryParams: qParams,
            });
            this.handleResetForm();
          }

          if (config.FEEDBACK.CONFIRM.RECORD_CHANGED && formHasChanged) {
            setFormStateFlag({
              componentId: targetId,
              formHasChanged: false,
            });
          }
          if (drawerOptions !== undefined)
            drawerOptions.setDrawerVisibility({
              dashboardId: drawerOptions.dashboardId,
              visible: drawerOptions.visible,
            });
          this.handleReloadCombo();
        } else form.resetFields();
      } else this.handleGroupMandatory();
    });
  };

  /**This function set active tab key in redux (selectedTab)
   * @param activeKey - Next tab key to set
   */
  handleChangeTab = (activeKey: string) => {
    const { setSelectedTabEdit, params } = this.props;
    const { componentId } = params;
    setSelectedTabEdit({
      componentId,
      selectedTab: activeKey,
    });
  };

  handleGroupMandatory = () => {
    const { groups, fields, intl, selectedTab, values } = this.props;
    if (groups.length > 1) {
      const dataError: JSX.Element[] = [];

      fields.forEach((field) => {
        let fieldTitle: string = '';
        let groupTitle: string = '';
        if (field.mandatory && values[field.key] === undefined) {
          groups.forEach((groupsRow) => {
            groupsRow.subgroups.forEach((subgroup) => {
              subgroup.fields.forEach((f) => {
                if (
                  f.key === field.key &&
                  groupsRow.index.toString() !== selectedTab.toString()
                ) {
                  groupTitle = groupsRow.title;
                  fieldTitle = field.title;
                }
              });
            });
          });
          if (groupTitle && fieldTitle)
            dataError.push(<div>{groupTitle.concat(' - ' + fieldTitle)}</div>);
        }
      });

      if (!isEmpty(dataError))
        error({
          message: intl.formatMessage({
            id: 'alert.title.required',
          }),
          duration: 0,
          description: (
            <List
              size="small"
              renderItem={(item: JSX.Element) => <List.Item>{item}</List.Item>}
              dataSource={dataError}
            />
          ),
        });
    }
  };

  handleAddData = async () => {
    const {
      targetId,
      formHasChanged,
      setSelectedRow,
      setFormStateFlag,
      resetEditForm,
      fields,
      params,
      fieldsConfig,
      intl,
      userPermissions,
      queryParams,
    } = this.props;
    const {
      changeFieldsConfig,
      setBehaviourInitialState,
      setFormInitialValues,
    } = this;
    const { componentId } = params;
    const { q } = queryParams;
    if (isTableTarget(targetId)) {
      if (config.FEEDBACK.CONFIRM.RECORD_CHANGED && formHasChanged) {
        Modal.confirm({
          title: intl.formatMessage({ id: 'pop.title.select' }),
          content: intl.formatMessage({ id: 'pop.title.select.warn' }),
          okText: intl.formatMessage({ id: 'pop.accept' }),
          cancelText: intl.formatMessage({ id: 'pop.cancel' }),
          // icon: 'warning',
          maskClosable: true,
          async onOk() {
            setSelectedRow({
              componentId: targetId,
              selectedRow: {},
            });
            setFormStateFlag({
              componentId: targetId,
              formHasChanged: false,
            });
            const fieldsConfigBehaviourSelector = (field: ICompleteField) =>
              setBehaviourInitialState(field);

            const newFieldsConfig = changeFieldsConfig(
              fieldsConfig,
              fieldsConfigBehaviourSelector,
            );

            let vals = await setFormInitialValues(fields, userPermissions!);

            if (
              !isEmpty(q) &&
              config.COMPONENT.EDIT.DISABLE_QUERY_PARAMS_FIELD
            ) {
              q!.split(config.QUERY.AND).forEach((element) => {
                const key = element.split(config.QUERY.ID_OPERATOR)[0];
                const value = element.split(config.QUERY.ID_OPERATOR)[1];
                fields.forEach((field) => {
                  if (field.key === key && value)
                    vals = { ...vals, [key]: value.toString() };
                });
              });
            }
            await resetEditForm({
              componentId,
              fieldsConfig: newFieldsConfig,
              values: vals,
            });
          },
          onCancel() {}, //TODO Define cancel
        });
      } else {
        setSelectedRow({
          componentId: targetId,
          selectedRow: {},
        });

        const fieldsConfigBehaviourSelector = (field: ICompleteField) =>
          this.setBehaviourInitialState(field);

        const newFieldsConfig = this.changeFieldsConfig(
          fieldsConfig,
          fieldsConfigBehaviourSelector,
        );

        let vals = await this.setFormInitialValues(fields, userPermissions!);
        if (!isEmpty(q) && config.COMPONENT.EDIT.DISABLE_QUERY_PARAMS_FIELD) {
          q!.split(config.QUERY.AND).forEach((element) => {
            const key = element.split(config.QUERY.ID_OPERATOR)[0];
            const value = element.split(config.QUERY.ID_OPERATOR)[1];
            fields.forEach((field) => {
              if (field.key === key && value)
                vals = { ...vals, [key]: value.toString() };
            });
          });
        }
        await resetEditForm({
          componentId,
          fieldsConfig: newFieldsConfig,
          values: vals,
        });
      }
    }
  };

  handleDeleteData = async () => {
    const {
      targetId,
      params,
      selectedRow,
      dashboardId,
      deleteTableData,
      setDrawerVisibility,
      setSelectedRow,
    } = this.props;
    const { primaryKey } = params;
    if (isTableTarget(targetId)) {
      feedback({
        type: 'message',
        method: 'loading',
        message: 'generic.loading',
      });
      const result = await deleteTableData({
        dataPath: appComponents[targetId].path,
        componentId: targetId,
        selectedRowKeys: [selectedRow[primaryKey]],
        primaryKey,
        primaryKeyValue: selectedRow[primaryKey],
      });
      if ('status' in result && result['status'] === 200) {
        setDrawerVisibility({ dashboardId, visible: false });
        setSelectedRow({
          componentId: targetId,
          selectedRow: {},
        });
      }
      this.handleReloadCombo();
    }
  };

  handleResetForm = async () => {
    const {
      params,
      targetId,
      selectedRow,
      formHasChanged,
      setFormStateFlag,
      fields,
      fieldsConfig,
      resetEditForm,
      queryParams,
      userPermissions,
    } = this.props;
    const { componentId } = params;
    const { q } = queryParams;

    let values = isEmpty(selectedRow)
      ? await this.setFormInitialValues(fields, userPermissions!)
      : flatten({ ...selectedRow }, { safe: true });

    //PLEASE DON'T TOUCH THIS OR BEHAVIOURS WILL BREAK !
    setFormStateFlag({
      componentId: targetId,
      formHasChanged: false,
    });

    const fieldsConfigBehaviourSelector = (field: ICompleteField) => {
      let control = true;
      if (field.behaviours !== undefined) {
        field.behaviours.forEach((behaviourGroup) => {
          behaviourGroup.forEach((behaviour) => {
            fields.forEach((f) => {
              if (
                f.key === behaviour.key &&
                !f.hasOwnProperty('initialValue')
              ) {
                control = false;
              }
            });
          });
        });
        if (!control) {
          return this.setBehaviourInitialState(field);
        } else {
          if (field.hasOwnProperty('initialVisibility')) {
            field.visible = !field.initialVisibility;
          }
          if (field.hasOwnProperty('initialDisabled')) {
            field.disabled = !field.initialDisabled;
          }
          if (field.hasOwnProperty('initialMandatory')) {
            field.mandatory = !field.initialMandatory;
          }
        }
      } else {
        return this.setBehaviourInitialState(field);
      }
      return field;
    };

    const newFieldsConfig = this.changeFieldsConfig(
      fieldsConfig,
      fieldsConfigBehaviourSelector,
    );

    if (!isEmpty(q) && config.COMPONENT.EDIT.DISABLE_QUERY_PARAMS_FIELD) {
      q!.split(config.QUERY.AND).forEach((element) => {
        const key = element.split(config.QUERY.ID_OPERATOR)[0];
        const value = element.split(config.QUERY.ID_OPERATOR)[1];
        fields.forEach((field) => {
          if (field.key === key && value)
            values = { ...values, [key]: value.toString() };
        });
      });
    }

    if (formHasChanged) {
      await resetEditForm({
        componentId,
        fieldsConfig: newFieldsConfig,
        values,
      });
    }
  };

  handleCloseForm = async () => {
    const {
      params,
      targetId,
      dashboardId,
      formHasChanged,
      setDrawerVisibility,
      setFormStateFlag,
      setSelectedRow,
      selectedRow,
      setSelectedTabEdit,
    } = this.props;
    const { componentId } = params;
    if (config.FEEDBACK.CONFIRM.RECORD_CHANGED && formHasChanged) {
      Modal.confirm({
        title: this.props.intl.formatMessage({ id: 'pop.title.select' }),
        content: this.props.intl.formatMessage({ id: 'pop.title.select.warn' }),
        okText: this.props.intl.formatMessage({ id: 'pop.accept' }),
        cancelText: this.props.intl.formatMessage({ id: 'pop.cancel' }),
        // icon: 'warning',
        maskClosable: true,
        onOk() {
          setSelectedRow({
            componentId: targetId,
            selectedRow: selectedRow,
          });
          setDrawerVisibility({ dashboardId, visible: false });
          setFormStateFlag({
            componentId: targetId,
            formHasChanged: false,
          });
          setSelectedTabEdit({
            componentId,
            selectedTab: '0',
          });
        },
        onCancel() {}, //TODO Define cancel
      });
    } else {
      setSelectedRow({
        componentId: targetId,
        selectedRow: selectedRow,
      });
      setDrawerVisibility({ dashboardId, visible: false });
      setFormStateFlag({
        componentId: targetId,
        formHasChanged: false,
      });
      setSelectedTabEdit({
        componentId,
        selectedTab: '0',
      });
    }
  };

  handleRevertRecord = async (e: React.MouseEvent) => {
    e.preventDefault();
    const {
      targetId,
      params,
      selectedRow,
      form,
      updateEditData,
      getTableData,
      setFormData,
      formHasChanged,
      setFormStateFlag,
    } = this.props;
    const { componentId } = params;

    feedback({
      type: 'message',
      method: 'loading',
      message: 'generic.loading',
    });

    try {
      await updateEditData({
        dataPath: appComponents[componentId].path,
        componentId,
        values: flatten({ ...selectedRow }, { safe: true }),
      });

      if (isTableTarget(targetId)) {
        getTableData({
          dataPath: appComponents[targetId].path,
          componentId: targetId,
        });
        setFormData({
          componentId,
          values: flatten({ ...selectedRow }, { safe: true }),
        });
        form.resetFields();

        if (config.FEEDBACK.CONFIRM.RECORD_CHANGED && formHasChanged) {
          setFormStateFlag({
            componentId: targetId,
            formHasChanged: false,
          });
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  openNotification = () => {
    const { intl } = this.props;
    const key = 'updatable';
    const btn = (
      <Button
        type="primary"
        size="small"
        onClick={(e: React.MouseEvent) => {
          this.handleRevertRecord(e);
          notification.destroy();
        }}>
        {intl.formatMessage({ id: 'checkbox.true' })}
      </Button>
    );

    notification.open({
      key,
      placement: 'bottomLeft',
      duration: config.FEEDBACK.REVERT.DURATION,
      message: intl.formatMessage({ id: 'revert.confirmation' }),
      btn,
    });
  };

  /**
   * This function is used to set new parents value on a combo child.
   * @param {String} key Field key from the combo child.
   * @param {String} value New combo parent value
   */
  setParentValue = (key: string, value: any) => {
    const { setFieldsConfig, params, fieldsConfig } = this.props;
    const { componentId } = params;

    const fieldsConfigBehaviourSelector = (field: ICompleteField) => {
      if (field.key === key) field.parentValue = value;
      return field;
    };

    const newFieldsConfig = this.changeFieldsConfig(
      fieldsConfig,
      fieldsConfigBehaviourSelector,
    );
    setFieldsConfig(componentId, newFieldsConfig);
  };

  handleChildBehaviours = (
    targetKey: string,
    behavioursDisplayed: any, //TODO con behaviours...
    forceVisible?: boolean,
    forceDisabled?: boolean,
  ) => {
    const { setFieldsConfig, params, fieldsConfig } = this.props;
    const { componentId } = params;

    const fieldsConfigBehaviourSelector = (field: ICompleteField) => {
      if (field.key === targetKey) {
        if (forceVisible !== null && forceVisible !== undefined)
          field.visible = forceVisible;
        else if (behavioursDisplayed.hasOwnProperty('visible'))
          field.visible = behavioursDisplayed['visible'];

        if (field.type === 'combo' && field.hasOwnProperty('parentValue')) {
          if (behavioursDisplayed.hasOwnProperty('disabled')) {
            field.disabled = behavioursDisplayed['disabled'];
          }
        } else {
          if (forceDisabled !== null && forceDisabled !== undefined)
            field.disabled = forceDisabled;
          else if (behavioursDisplayed.hasOwnProperty('disabled'))
            field.disabled = behavioursDisplayed['disabled'];
          field.behaviourDisabled = behavioursDisplayed['disabled'];
        }

        if (behavioursDisplayed.hasOwnProperty('mandatory'))
          field.mandatory = behavioursDisplayed['mandatory'];
      }
      return field;
    };

    const newFieldsConfig = this.changeFieldsConfig(
      fieldsConfig,
      fieldsConfigBehaviourSelector,
    );

    setFieldsConfig(componentId, newFieldsConfig);
  };

  handleResourceError = () =>
    feedback({
      type: 'notification',
      method: 'error',
      message: 'image.notfound',
    });

  /**
   * Updates the options of a child combo depending on parent selected item.
   * @param comboId {Object} - object with the information of the child.
   * @param id {int} - the identifier of the selected item in parent combo.
   */
  updateChildCombo = (comboId: string, id: string | number, key: string) => {
    const { getComboData, params, form } = this.props;
    const { componentId } = params;
    let queryParams = { id: comboId } as IRow;
    if (id) queryParams = { ...queryParams, param: id };
    getComboData({
      dataPath:
        comboId === 'type-child' ? apiPaths.WEBEAT.MENU_CHILD : apiPaths.COMBO,
      comboId,
      fieldKey: key,
      componentId,
      queryParams,
    });
    form.resetFields();
  };

  changeFieldsConfig = (
    fieldsConfig: IGroupField[],
    fieldsConfigBehaviourSelector: (field: ICompleteField) => ICompleteField,
  ): IGroupField[] => {
    fieldsConfig.forEach((field) => {
      field.subgroupRows.forEach((subgroupRow) => {
        subgroupRow.forEach((subgroup) => {
          subgroup.fieldRow.forEach((fieldRow) => {
            fieldRow.forEach((field) => {
              if (fieldsConfigBehaviourSelector(field))
                field = fieldsConfigBehaviourSelector(field);
            });
          });
        });
      });
    });
    return fieldsConfig;
  };

  //CUSTOM ISDIN VALIDATIONS
  customValidations = (values: IRow, componentId: string): boolean => {
    let control = true;
    let message = '';
    switch (componentId) {
      case 'marketingMaterialsEdit':
        switch (parseInt(values.type)) {
          case PRODUCT_IMAGE:
          case VIDEO_YOUTUBE:
          case VIDEO_VIMEO:
          case VIDEO_UPLOAD:
            if (!values.content) {
              control = false;
              message = 'You have to upload a File before saving changes.';
            }
            break;
          default:
            break;
        }
        break;
      case 'qrProductEdit':
        if (
          (values.qrStartDate && !values.qrEndDate) ||
          (!values.qrStartDate && values.qrEndDate) ||
          (values.qrStartDate &&
            values.qrEndDate &&
            values.qrStartDate >= values.qrEndDate)
        ) {
          control = false;
          message = 'Start date should be before end date.';
        }
        break;
    }

    if (!control) notification.warning({ message });
    return control;
  };

  checkCustomBehaviours = ({
    values,
    componentId,
    id,
  }: {
    values: IRow;
    componentId: string;
    id: string;
  }): IRow => {
    if (componentId === 'marketingMaterialsEdit' && id === 'type')
      return { ...values, image: '', content: '' };

    return values;
  };

  getMultiSelectValues = ({
    id,
    multiSelectId,
    values,
    value,
  }: {
    id: string;
    multiSelectId: string;
    values: IRow;
    value: any;
  }) => {
    const newValues = { ...values };
    const currentArray = newValues[id];

    switch (true) {
      case !value:
        newValues[id] = [];
        break;
      case currentArray && !Array.isArray(currentArray):
        break;
      default:
        let newArray: IRow[] = [];

        // Check current Values
        currentArray?.forEach((val: IRow) => {
          value.forEach((multiSelectVal: string) => {
            if (multiSelectVal.toString() === val[multiSelectId].toString())
              newArray.push(val);
          });
        });

        // Check rest of elements in multiSelect
        value.forEach((multiSelectVal: string) => {
          let isElementIncluded = false;
          newArray.forEach((element: IRow) => {
            if (multiSelectVal.toString() === element[multiSelectId].toString())
              isElementIncluded = true;
          });
          if (!isElementIncluded)
            newArray = [...newArray, { [multiSelectId]: multiSelectVal }];
        });

        newValues[id] = newArray;
        break;
    }

    return newValues;
  };

  render() {
    const { fieldsConfig } = this.props;
    if (!isEmpty(fieldsConfig)) {
      return <EditFormRender {...this} />;
    }
    return null;
  }
}

const mapStateToProps = (state: ReducersState, ownProps: OwnProps) => {
  return {
    targetId: state.edits[ownProps.params.componentId].targetId,
    fieldsConfig: state.edits[ownProps.params.componentId].fieldsConfig,
    hasNavigated: state.query.editFormNavigation,
    resetFormTrigger: state.edits[ownProps.params.componentId].resetFormTrigger,
    selectedRow:
      state.tables[appComponents[ownProps.params.componentId].targetId]
        .selectedRow,
    values: state.edits[ownProps.params.componentId].values,
    parentData: state.edits[ownProps.params.componentId].parentData,
    combos: state.combos,
    formHasChanged:
      state.tables[appComponents[ownProps.params.componentId].targetId]
        .formHasChanged,
    isLoading: state.edits[ownProps.params.componentId].isLoading,
    data: state.tables[appComponents[ownProps.params.componentId].targetId]
      .data,
    prettierKey:
      state.tables[appComponents[ownProps.params.componentId].targetId]
        .prettierKey,
    selectedTab: state.edits[ownProps.params.componentId].selectedTab,
    queryParams: state.query.params,
    accessToken: state.auth.accessToken,
    router: state.router.location.state,
    currentPath: state.router.location.pathname,
    userPermissions: state.app.permissions,
    appParams: state.app.appParams,
    userParams: state.app.user,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    {
      setInitialState,
      deleteTableData,
      getTableData,
      getComboData,
      setFormData,
      updateEditData,
      createEditData,
      fetchForeignData,
      setFieldsConfig,
      setFormStateFlag,
      setSelectedTabEdit,
      logout,
      editNavigationState,
      resetEditForm,
      setResetFormTrigger,
      navigateAfterCreate,
      setDrawerVisibility,
      setChildSelectedRow,
      fetchParentData,
      updateTableQueryParams,
    },
    dispatch,
  );

const InjectedEditForm = injectIntl(EditForm);
const WrappedEditForm = Form.create()(InjectedEditForm);

export default connect(mapStateToProps, mapDispatchToProps)(WrappedEditForm);
