import {
  Button,
  Col,
  Form,
  Input,
  Modal,
  notification,
  Row,
  Select,
} from 'antd';
import { FormComponentProps } from 'antd/lib/form/Form';
import { ColumnProps } from 'antd/lib/table';
import Paragraph from 'antd/lib/typography/Paragraph';
import Title from 'antd/lib/typography/Title';
import { isEmpty, xor } from 'lodash';
import React, { FC, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import * as api from '../api';
import apiPaths from '../apiPaths';
import { IRecord, IRow } from '../app/AppInterfaces';
import config from '../config';
import {
  getUserSpecialty,
  getUserType,
  PinUserStatusEnum,
  UserCenterEnum,
} from './Shared';

interface IExtraFields extends ColumnProps<IRecord> {
  // ! Currently only supports 'select' and 'multiSelect' as type
  type?: string;
}

interface IUpdateUsersRole {
  idsManagers: number[];
  idsEmployees: number[];
  idCenter: number;
}

interface IChangeUsersCenter {
  idUser: number;
  uuidCenter: string;
}

interface Props {
  columns: ColumnProps<IRecord>[];
  extraFields: IExtraFields[];
  modalVisible: boolean;
  selectedRow: IRecord;
  setModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
  title: string;
  shouldCreateCenterLIWW?: boolean;
  reloadData: () => Promise<void>;
}

export function ComponentModal({
  columns,
  extraFields,
  modalVisible,
  selectedRow,
  setModalVisible,
  title,
  shouldCreateCenterLIWW,
  reloadData,
}: Props) {
  const [component, setComponent] = useState<IRow>(selectedRow);
  const [loading, setLoading] = useState(false);
  const [userError, setUserError] = useState(true);
  const [customOkText, setCustomOkText] = useState('');
  const [managers, setManagers] = useState<string[]>([]);
  const [users, setUsers] = useState<any[]>([]);
  const [newCenter, setNewCenter] = useState<string>('');
  const { formatMessage } = useIntl();
  const modalItems: IExtraFields[] = [...columns, ...extraFields];

  const canEditManagers =
    component?.content?.centerStatus === UserCenterEnum.GRANT;

  const getCurrentManagers = (component: IRow): string[] =>
    component?.content?.usersList
      ?.filter(({ idSalesforce, userStatus, uuidUser, usersCenter }: any) => {
        const isManager =
          idSalesforce &&
          uuidUser &&
          (userStatus === UserCenterEnum.GRANT ||
            userStatus === PinUserStatusEnum.GRANT_FIRST_LOGIN) &&
          usersCenter[0].idRole === 'Manager';
        return isManager;
      })
      .map(({ uuidUser }: any) => uuidUser) || [];

  const getDataApi = async (removedUser: boolean = false) => {
    let apiData: {
      path: string;
      paramId: string;
    };

    switch (true) {
      case selectedRow?.userID !== undefined:
        apiData = {
          path: apiPaths.CALL.USER_INFO_UUID,
          paramId: selectedRow.userID,
        };
        break;
      case selectedRow?.centerID !== undefined:
        apiData = {
          path: apiPaths.CALL.CENTER_USERS,
          paramId: selectedRow.centerID,
        };
        setCustomOkText('Save');
        break;
      case shouldCreateCenterLIWW:
        apiData = {
          path: apiPaths.CALL.VALIDATED_USERS,
          paramId: '',
        };
        setCustomOkText('Save');
        break;
      default:
        return null;
    }

    if (!apiData) return;

    let user: any = { ...selectedRow };
    try {
      setLoading(true);
      const shouldLoadUsers = users.length <= 0;
      let response: any = {
        status: 200,
        data: {
          content: users,
        },
      };
      if (shouldLoadUsers) {
        const _response = await api.getDataCallById({
          dataPath: apiData.path,
          registerId: apiData.paramId,
          callConfig: {},
        });

        response = {
          status: _response.status,
          data: {
            content: _response.data,
          },
        };

        if (shouldCreateCenterLIWW) setUsers(_response.data);
      }

      if (response?.status === 200 && response?.data) {
        user = { ...response.data, ...selectedRow };

        setNewCenter(user.content?.idCurrentCenter ?? user.idCurrentCenter);
        setUserError(false);
        setComponent(user);
        if (removedUser) {
          setManagers(
            user.content?.usersList
              ?.filter((user: any) => managers.includes(user.uuidUser))
              .map((user: any) => user.uuidUser) || [],
          );
        }
      } else {
        notification.error({
          message: response.data.message,
          duration: 3,
        });
      }
    } catch (err) {
      setCustomOkText('');
      setComponent(user);
      console.group(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (modalVisible) (async () => await getDataApi())();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalVisible]);

  useEffect(() => {
    if (component && modalVisible) {
      if (!canEditManagers) setManagers([]);

      setManagers(getCurrentManagers(component));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [component, modalVisible]);

  const confirmModal = async () => {
    if (customOkText !== 'Save') {
      handleCloseModal();
      return;
    }
    if (isEmpty(managers) && !component?.content?.idCurrentCenter)
      Modal.confirm({
        title: formatMessage({ id: 'component-modal.confirm-save-managers' }),
        okText: formatMessage({ id: 'form.edit.continue' }),
        cancelText: formatMessage({ id: 'form.edit.cancel' }),
        maskClosable: true,
        async onOk() {
          await handleSaveModal();
        },
        onCancel() {},
      });
    else if (component?.content?.idCurrentCenter)
      Modal.confirm({
        title: formatMessage({ id: 'component-modal.confirm-save-new-center' }),
        okText: formatMessage({ id: 'form.edit.continue' }),
        cancelText: formatMessage({ id: 'form.edit.cancel' }),
        maskClosable: true,
        async onOk() {
          await handleSaveModal();
        },
        onCancel() {},
      });
    else await handleSaveModal();
  };

  const handleAssigManagerToCenter = async () => {
    if (!shouldCreateCenterLIWW) return;

    if (managers.length <= 0) return;

    const [uuid] = managers;

    const data = {
      center: {
        idSalesforce: component?.saleforceID,
        centerName: component?.centerName,
        centerType: config.REGISTER.PRACTICE_TYPE,
      },
      manager: {
        uuid,
      },
    };
    try {
      setLoading(true);
      await api.postDataCall({
        dataPath: apiPaths.AUTH.REGISTER_MANAGER,
        data,
        callConfig: {},
      });

      // TODO: Reload grid
      await reloadData();
    } catch (err) {
      console.error(err);
    }
  };

  const handlerReassignUserToCenter = async () => {
    if (shouldCreateCenterLIWW) return;

    if (selectedRow?.centerID) return;

    const usersData: IChangeUsersCenter = {
      idUser: component?.content?.idUser,
      uuidCenter: newCenter,
    };

    try {
      setLoading(true);
      const response = await api.putDataCall({
        dataPath: apiPaths.CALL.USER_REASSIGN_CENTER,
        data: usersData,
        callConfig: {},
      });
      if (response?.status === 200 && response.data?.errors?.length) {
        const errorEmails = response.data.errors.map((errId: number) => {
          const user = component?.content?.usersList?.find(
            ({ idUser }: any) => errId === idUser,
          );
          return user?.email || errId;
        });

        notification.warning({
          message: formatMessage({
            id: 'component-modal.notification-warning-title',
          }),
          description: formatMessage(
            { id: 'component-modal.notification-warning-description' },
            { errors: errorEmails.join(', ') },
          ),
        });
      } else if (response?.status === 200) {
        notification.success({
          message: formatMessage({
            id: 'component-modal.notification-success-title',
          }),
          description: formatMessage({
            id: 'component-modal.notification-success-description',
          }),
        });
      }
    } catch (err) {
      notification.error({
        message: formatMessage({
          id: 'component-modal.notification-error-title',
        }),
        description: formatMessage({
          id: 'component-modal.notification-error-description',
        }),
      });
    } finally {
      setLoading(false);
    }
  };

  const hadlerUpdateRoles = async () => {
    const shouldAvoidAction = !selectedRow?.centerID;

    if (shouldAvoidAction) return;

    const currentManagers = getCurrentManagers(component);
    const changes = xor(managers, currentManagers);
    const idsEmployees: number[] = [];
    const idsManagers: number[] = [];

    changes.forEach((change) => {
      const user = component?.content?.usersList.find(
        ({ uuidUser }: any) => change === uuidUser,
      );
      if (user?.usersCenter[0]?.idRole === 'Manager')
        idsEmployees.push(user.content?.idUser ?? user.idUser);
      if (user?.usersCenter[0]?.idRole === 'Employee')
        idsManagers.push(user.content?.idUser ?? user.idUser);
    });

    let usersData: IUpdateUsersRole = {
      idCenter: component?.content?.idCenter,
      idsManagers,
      idsEmployees,
    };

    try {
      setLoading(true);
      const response = await api.putDataCall({
        dataPath: apiPaths.CALL.USERSCENTER_UPDATE_ROLE,
        data: usersData,
        callConfig: {},
      });

      if (response?.status === 200 && response.data?.errors?.length) {
        const errorEmails = response.data.errors.map((errId: number) => {
          const user = component?.content?.usersList?.find(
            ({ idUser }: any) => errId === idUser,
          );
          return user?.email || errId;
        });

        notification.warning({
          message: formatMessage({
            id: 'component-modal.notification-warning-title',
          }),
          description: formatMessage(
            { id: 'component-modal.notification-warning-description' },
            { errors: errorEmails.join(', ') },
          ),
        });
      } else if (response?.status === 200) {
        notification.success({
          message: formatMessage({
            id: 'component-modal.notification-success-title',
          }),
          description: formatMessage({
            id: 'component-modal.notification-success-description',
          }),
        });
      }
    } catch (err) {
      notification.error({
        message: formatMessage({
          id: 'component-modal.notification-error-title',
        }),
        description: formatMessage({
          id: 'component-modal.notification-error-description',
        }),
      });
    } finally {
      setLoading(false);
    }
  };

  const handleSaveModal = async () => {
    if (loading) return;

    setLoading(true);

    await handleAssigManagerToCenter();

    await handlerReassignUserToCenter();

    await hadlerUpdateRoles();

    handleCloseModal();
  };

  const handleCloseModal = () => {
    setComponent({});
    setManagers([]);
    setModalVisible(false);
    setCustomOkText('');
    setNewCenter('');
    setLoading(false);
  };

  const getFieldValue = (field: ColumnProps<IRecord>) => {
    const { dataIndex, key } = field;
    if (!dataIndex) return '';

    let value = component[dataIndex];

    switch (key) {
      case 'specialty':
        return getUserSpecialty(value, formatMessage);
      case 'userType':
        return getUserType(value);
      case 'customerId':
        return component?.content?.customerId;
      default:
        return value;
    }
  };

  const renderOptions = () => {
    if (!canEditManagers && !shouldCreateCenterLIWW) return [];

    if (shouldCreateCenterLIWW)
      return component?.content?.map(({ uuidUser, email }: any) => (
        <Select.Option key={uuidUser}>{email}</Select.Option>
      ));
    else
      return component?.content?.usersList
        .filter(
          ({ idSalesforce, uuidUser, userStatus }: any) =>
            idSalesforce &&
            uuidUser &&
            (userStatus === UserCenterEnum.GRANT ||
              userStatus === PinUserStatusEnum.GRANT_FIRST_LOGIN),
        )
        .map(({ uuidUser, email }: any) => (
          <Select.Option key={uuidUser}>{email}</Select.Option>
        ));
  };

  const renderCenters = () => {
    const sortByName = (a: any, b: any) => {
      if (a.name < b.name) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }
      return 0;
    };

    const centers: any[] =
      component?.activeCenters ?? component?.content?.activeCenters ?? [];

    return centers
      ?.sort(sortByName)
      ?.map(({ uuidCenter, name }: any) => (
        <Select.Option key={uuidCenter}>{name}</Select.Option>
      ));
  };

  const handleUnlinkUser = async (user: any) => {
    try {
      setLoading(true);
      const response = await api.deleteCall({
        dataPath: apiPaths.CALL.CENTERS_USER_DE_ASSIGN,
        callConfig: {
          data: {
            idUser: user.content?.idUser ?? user.idUser,
            idCenter: component?.content?.idCenter,
          },
        },
      });
      if (response?.status === 200) {
        notification.success({
          message: formatMessage({ id: 'users.deleteUserCenter.success' }),
          duration: 3,
        });

        await getDataApi(true);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const openUnlinkUserModal = (user: any) => {
    Modal.confirm({
      title: formatMessage({ id: 'pop.title.deleteUserCenter' }),
      okText: formatMessage({ id: 'pop.accept' }),
      cancelText: formatMessage({ id: 'pop.cancel' }),
      maskClosable: true,
      onOk() {
        handleUnlinkUser(user);
      },
      onCancel() {},
    });
  };
  const FormCustom: FC<FormComponentProps> = ({ form }) => {
    const handleSelectChange = (value: any, options: any) => {
      const singleManager = [value];
      const multipleManagers = options.length
        ? options.map(({ key }: any) => key)
        : [];

      const newManagers = shouldCreateCenterLIWW
        ? singleManager
        : multipleManagers;

      setManagers(newManagers);
    };

    const handleSelectCenter = (value: any, options: any) => {
      if (options.key !== component?.content?.idCurrentCenter) {
        setCustomOkText('Save');
      } else {
        setCustomOkText('');
      }

      setNewCenter(options.key);
    };

    const { getFieldDecorator } = form;
    return (
      <Row type="flex" gutter={[24, 0]}>
        {modalItems?.map((field, i: number) =>
          !field.type ? (
            <Col xs={24} md={12} key={i}>
              <Form.Item label={field.title}>
                <Input disabled value={getFieldValue(field)} />
              </Form.Item>
            </Col>
          ) : field.type === 'multiSelect' ? (
            <Col xs={24} md={12} key={i}>
              <Form.Item label={field.title}>
                {getFieldDecorator('multi-field', {
                  initialValue: managers,
                })(
                  <Select
                    mode={shouldCreateCenterLIWW ? 'default' : 'multiple'}
                    allowClear
                    disabled={!canEditManagers && !shouldCreateCenterLIWW}
                    loading={loading}
                    placeholder="No Managers"
                    onChange={(value: any, options: any) => {
                      handleSelectChange(value, options);
                    }}
                    showSearch
                    notFoundContent={formatMessage({
                      id: 'combo.data.notfound',
                    })}
                    filterOption={(input: any, option: any) =>
                      option?.props?.children
                        ?.toLowerCase()
                        ?.indexOf(input.toLowerCase()) >= 0
                    }>
                    {renderOptions()}
                  </Select>,
                )}
              </Form.Item>
            </Col>
          ) : field.type === 'select' ? (
            <Col xs={24} md={12} key={i}>
              <Form.Item label={field.title}>
                <Select
                  value={newCenter}
                  loading={loading}
                  disabled={userError}
                  showSearch
                  optionFilterProp="children"
                  dropdownMatchSelectWidth={false}
                  onChange={(value: any, options: any) => {
                    handleSelectCenter(value, options);
                    handleSelectChange(value, options);
                  }}
                  placeholder={formatMessage({
                    id: 'users.deleteUserCenter.noUsersTitle',
                  })}
                  notFoundContent={formatMessage({
                    id: 'combo.data.notfound',
                  })}
                  filterOption={(input: any, option: any) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }>
                  {renderCenters()}
                </Select>
              </Form.Item>
            </Col>
          ) : null,
        )}
      </Row>
    );
  };

  const FormWithProps = Form.create<FormComponentProps>()(FormCustom);

  return (
    <Modal
      className="isdin-custom-modal"
      visible={modalVisible}
      okButtonProps={{
        children: 'Custom OK',
      }}
      okText={customOkText}
      onCancel={handleCloseModal}
      onOk={confirmModal}>
      <Row className="isdin-custom-modal__container">
        <Row className="isdin-custom-modal__title">{title}</Row>
        <FormWithProps />
        {component?.content?.usersList?.length > 0 && (
          <>
            <Col style={{ marginBottom: '18px' }}>
              <Title level={3}>
                {formatMessage({ id: 'users.center-users' })}
              </Title>
            </Col>
            <Col className="isdin-custom-modal__users-list">
              {component?.content?.usersList?.map((user: any) => (
                <Row key={user?.content?.idUser ?? user.idUser}>
                  <Paragraph>{user?.content?.email ?? user.email}</Paragraph>
                  <Button
                    className="users-list-remove-button"
                    loading={loading}
                    onClick={() => openUnlinkUserModal(user)}>
                    {formatMessage({ id: 'users.deleteUserCenter.button' })}
                  </Button>
                </Row>
              ))}
            </Col>
          </>
        )}
      </Row>
    </Modal>
  );
}
