import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { SubscriptionModal } from '../interfaces/form-common.interfaces';
import { Button, Checkbox, Col, Form, Input, Row } from 'antd';
import { useSelector } from 'react-redux';
import { feedback, loadItem, PLATFORM_CODE_KEY } from '../../../utils';
import { ThankYouPageTypes } from '../types/subscription-modal-form-common.types';
import {
  MaxLengthFields,
  validateThankYouPageFields,
} from '../subscription-modal-utils/validate-fields';

const ThankyouForm = ({
  subscriptionModalData,
  setSubscriptionModalData,
}: {
  subscriptionModalData: SubscriptionModal;
  setSubscriptionModalData: any;
}) => {
  const loadedPlatform = loadItem(PLATFORM_CODE_KEY);
  const { auth, app } = useSelector((state: any) => state);
  const [errors, setErrors] = useState({} as any);
  const [load, setLoad] = useState(false);

  const [thankYouData, setThankYouData] = useState<Partial<SubscriptionModal>>({
    thankYouPageCta: subscriptionModalData.thankYouPageCta || '',
    thankYouPageSubtitle: subscriptionModalData.thankYouPageSubtitle || '',
    thankYouPageTitle: subscriptionModalData.thankYouPageTitle || '',
    thankYouPageType: subscriptionModalData.thankYouPageType || '',
    thankYouPagePromoCode: subscriptionModalData.thankYouPagePromoCode || '',
  });

  const handleSubscriptionModalSubmit = async (e: any) => {
    setLoad(true);
    e.preventDefault();

    setErrors({});

    const fieldErrors = validateThankYouPageFields({
      ...subscriptionModalData,
      thankYouPageType: thankYouData.thankYouPageType,
      thankYouPageTitle: thankYouData.thankYouPageTitle,
      thankYouPageSubtitle: thankYouData.thankYouPageSubtitle,
      thankYouPagePromoCode: thankYouData.thankYouPagePromoCode,
      thankYouPageCta: thankYouData.thankYouPageCta,
    });

    if (Object.keys(fieldErrors).length > 0) {
      setErrors(fieldErrors);
      setLoad(false);
      return;
    }

    switch (thankYouData.thankYouPageType) {
      case ThankYouPageTypes.BASIC:
        thankYouData.thankYouPagePromoCode = '';
        thankYouData.thankYouPageSubtitle = '';
        thankYouData.thankYouPageCta = '';
        break;
      case ThankYouPageTypes.PROMO:
        thankYouData.thankYouPageSubtitle = '';
        thankYouData.thankYouPageCta = '';
        break;
      case ThankYouPageTypes.CONCERN:
        thankYouData.thankYouPagePromoCode = '';
        break;
      default:
        break;
    }

    const res = await fetch(`${app.currentPlatform}subscription-modal`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: auth.accessToken,
        'x-isdin-platform': loadedPlatform ?? app.currentPlatformCode,
      },
      body: JSON.stringify({
        ...subscriptionModalData,
        delayTime: +subscriptionModalData.delayTime,
        ...thankYouData,
      }),
    });
    const data = await res.json();

    if (!res.ok) {
      setLoad(false);
      return feedback({
        type: 'notification',
        method: 'warning',
        message: data.message,
        duration: 5,
      });
    }
    feedback({
      type: 'notification',
      method: 'success',
      message:
        'The subscription modal has been saved correctly. It will be available in a few seconds.',
      duration: 5,
    });

    setSubscriptionModalData({
      ...subscriptionModalData,
      ...thankYouData,
    });

    setLoad(false);
  };

  const resetThankYouDataChangeType = useCallback(() => {
    setErrors({});
    if (
      subscriptionModalData.thankYouPageType !== thankYouData.thankYouPageType
    ) {
      setThankYouData({
        thankYouPageCta: '',
        thankYouPageSubtitle: '',
        thankYouPageTitle: '',
        thankYouPageType: thankYouData.thankYouPageType,
        thankYouPagePromoCode: '',
      });
    } else {
      setThankYouData({
        thankYouPageCta: subscriptionModalData.thankYouPageCta || '',
        thankYouPageSubtitle: subscriptionModalData.thankYouPageSubtitle || '',
        thankYouPageTitle: subscriptionModalData.thankYouPageTitle || '',
        thankYouPageType: subscriptionModalData.thankYouPageType || '',
        thankYouPagePromoCode:
          subscriptionModalData.thankYouPagePromoCode || '',
      });
    }
  }, [thankYouData.thankYouPageType, subscriptionModalData]);

  const handleChangeInput = ({
    key,
    value,
  }: {
    key: string;
    value: string | ArrayBuffer | null;
  }) => {
    setThankYouData((prev: any) => {
      if (prev) {
        return {
          ...prev,
          [key]: value,
        };
      }
      return { [key]: value };
    });

    setErrors((prev: any) => ({ ...prev, [key]: '' }));
  };

  useEffect(() => {
    resetThankYouDataChangeType();
  }, [resetThankYouDataChangeType, thankYouData.thankYouPageType]);

  return (
    <Form onSubmit={handleSubscriptionModalSubmit}>
      <Row style={{ marginTop: 50 }}>
        <Col span={9}>
          <h2>{'*Which thank you screen do you want?'}</h2>
          <p style={{ marginTop: -10 }}>Select your thank you page option.</p>
          <Row>
            <Form.Item
              colon={false}
              validateStatus={errors.thankYouPageType ? 'error' : ''}
              help={errors.thankYouPageType}>
              <Row>
                <Col span={20} style={{ display: 'flex', gap: 10 }}>
                  <Checkbox
                    key={thankYouData.thankYouPageType}
                    style={{ borderRadius: 20 }}
                    onChange={() =>
                      handleChangeInput({
                        key: 'thankYouPageType',
                        value: ThankYouPageTypes.BASIC,
                      })
                    }
                    checked={
                      thankYouData.thankYouPageType === ThankYouPageTypes.BASIC
                    }
                  />
                  <p>
                    <b>Basic (only thank you message)</b>
                  </p>
                </Col>
              </Row>
            </Form.Item>
          </Row>

          <Row>
            <Form.Item
              colon={false}
              validateStatus={errors.thankYouPageType ? 'error' : ''}
              help={errors.thankYouPageType}>
              <Row>
                <Col span={20} style={{ display: 'flex', gap: 10 }}>
                  <Checkbox
                    key={thankYouData.thankYouPageType}
                    style={{ borderRadius: 20 }}
                    onChange={() =>
                      handleChangeInput({
                        key: 'thankYouPageType',
                        value: ThankYouPageTypes.PROMO,
                      })
                    }
                    checked={
                      thankYouData.thankYouPageType === ThankYouPageTypes.PROMO
                    }
                  />
                  <p>
                    <b>With promo code</b>
                  </p>
                </Col>
              </Row>
            </Form.Item>
          </Row>

          <Row>
            <Form.Item
              colon={false}
              validateStatus={errors.thankYouPageType ? 'error' : ''}
              help={errors.thankYouPageType}>
              <Row>
                <Col span={20} style={{ display: 'flex', gap: 10 }}>
                  <Checkbox
                    key={thankYouData.thankYouPageType}
                    style={{ borderRadius: 20 }}
                    onChange={() =>
                      handleChangeInput({
                        key: 'thankYouPageType',
                        value: ThankYouPageTypes.PROMO_CONCERN,
                      })
                    }
                    checked={
                      thankYouData.thankYouPageType ===
                      ThankYouPageTypes.PROMO_CONCERN
                    }
                  />
                  <p>
                    <b>With promo code with concern</b>
                  </p>
                </Col>
              </Row>
            </Form.Item>
          </Row>

          <Row>
            <Form.Item
              colon={false}
              validateStatus={errors.thankYouPageType ? 'error' : ''}
              help={errors.thankYouPageType}>
              <Row>
                <Col span={20} style={{ display: 'flex', gap: 10 }}>
                  <Checkbox
                    key={thankYouData.thankYouPageType}
                    style={{ borderRadius: 20 }}
                    onChange={() =>
                      handleChangeInput({
                        key: 'thankYouPageType',
                        value: ThankYouPageTypes.CONCERN,
                      })
                    }
                    checked={
                      thankYouData.thankYouPageType ===
                      ThankYouPageTypes.CONCERN
                    }
                  />

                  <p>
                    <b>With concern</b>
                  </p>
                </Col>
              </Row>
            </Form.Item>
          </Row>
        </Col>
        <Col span={12}>
          <img
            src="./thank-you-page-examples.png"
            alt="thank-you-page-examples.png"
            style={{ height: '400px', objectFit: 'contain', marginTop: 10 }}
          />
        </Col>
      </Row>

      {/* BASIC */}

      {thankYouData.thankYouPageType === ThankYouPageTypes.BASIC && (
        <Fragment>
          <Row style={{ marginTop: 50 }}>
            <Col span={6}>
              <h2>{'*Title'}</h2>
              <p style={{ marginTop: -10 }}>
                It should contain a maximum characters of:{' '}
                {MaxLengthFields.thankYouPageTitle}
              </p>
              <Form.Item
                colon={false}
                validateStatus={errors.thankYouPageTitle ? 'error' : ''}
                help={errors.thankYouPageTitle}>
                <Input
                  placeholder={
                    thankYouData.thankYouPageTitle || 'Enter text here'
                  }
                  value={thankYouData?.thankYouPageTitle || ''}
                  onChange={(e) =>
                    handleChangeInput({
                      key: 'thankYouPageTitle',
                      value: e.target.value,
                    })
                  }
                />
              </Form.Item>
            </Col>
          </Row>
        </Fragment>
      )}

      {/* PROMO */}
      {thankYouData.thankYouPageType === ThankYouPageTypes.PROMO && (
        <Fragment>
          <Row style={{ marginTop: 50 }}>
            <Col span={6}>
              <h2>{'*Title'}</h2>
              <p style={{ marginTop: -10 }}>
                It should contain a maximum characters of:{' '}
                {MaxLengthFields.thankYouPageTitle}
              </p>
              <Form.Item
                colon={false}
                validateStatus={errors.thankYouPageTitle ? 'error' : ''}
                help={errors.thankYouPageTitle}
                style={{ transition: 'none' }}>
                <Input
                  placeholder={
                    thankYouData.thankYouPageTitle || 'Enter text here'
                  }
                  value={thankYouData?.thankYouPageTitle || ''}
                  onChange={(e) =>
                    handleChangeInput({
                      key: 'thankYouPageTitle',
                      value: e.target.value,
                    })
                  }
                />
              </Form.Item>
            </Col>
          </Row>

          <Row style={{ marginTop: 20 }}>
            <Col span={6}>
              <h2>{'*Promo code discount'}</h2>
              <p style={{ marginTop: -10 }}>
                It should contain a maximum characters of:{' '}
                {MaxLengthFields.thankYouPagePromoCode}
              </p>
              <Form.Item
                colon={false}
                validateStatus={errors.thankYouPagePromoCode ? 'error' : ''}
                help={errors.thankYouPagePromoCode}
                style={{ transition: 'none' }}>
                <Input
                  placeholder={
                    thankYouData.thankYouPagePromoCode || 'Enter text here'
                  }
                  value={thankYouData?.thankYouPagePromoCode || ''}
                  onChange={(e) =>
                    handleChangeInput({
                      key: 'thankYouPagePromoCode',
                      value: e.target.value,
                    })
                  }
                />
              </Form.Item>
            </Col>
          </Row>
        </Fragment>
      )}

      {/* PROMO_CONCERN */}
      {thankYouData.thankYouPageType === ThankYouPageTypes.PROMO_CONCERN && (
        <Fragment>
          <Row style={{ marginTop: 50 }}>
            <Col span={6}>
              <h2>{'*Title'}</h2>
              <p style={{ marginTop: -10 }}>
                It should contain a maximum characters of:{' '}
                {MaxLengthFields.thankYouPageTitle}
              </p>
              <Form.Item
                colon={false}
                validateStatus={errors.thankYouPageTitle ? 'error' : ''}
                style={{ transition: 'none' }}
                help={errors.thankYouPageTitle}>
                <Input
                  placeholder={
                    thankYouData.thankYouPageTitle || 'Enter text here'
                  }
                  value={thankYouData?.thankYouPageTitle || ''}
                  onChange={(e) =>
                    handleChangeInput({
                      key: 'thankYouPageTitle',
                      value: e.target.value,
                    })
                  }
                />
              </Form.Item>
            </Col>
          </Row>
          <Row style={{ marginTop: 20 }}>
            <Col span={6}>
              <h2>{'*SubTitle'}</h2>
              <p style={{ marginTop: -10 }}>
                It should contain a maximum characters of:{' '}
                {MaxLengthFields.thankYouPageSubtitle}
              </p>
              <Form.Item
                colon={false}
                validateStatus={errors.thankYouPageSubtitle ? 'error' : ''}
                style={{ transition: 'none' }}
                help={errors.thankYouPageSubtitle}>
                <Input
                  placeholder={
                    thankYouData.thankYouPageSubtitle || 'Enter text here'
                  }
                  value={thankYouData?.thankYouPageSubtitle || ''}
                  onChange={(e) =>
                    handleChangeInput({
                      key: 'thankYouPageSubtitle',
                      value: e.target.value,
                    })
                  }
                />
              </Form.Item>
            </Col>
          </Row>

          <Row style={{ marginTop: 20 }}>
            <Col span={6}>
              <h2>{'*Promo code discount'}</h2>
              <p style={{ marginTop: -10 }}>
                It should contain a maximum characters of:{' '}
                {MaxLengthFields.thankYouPagePromoCode}
              </p>
              <Form.Item
                colon={false}
                style={{ transition: 'none' }}
                validateStatus={errors.thankYouPagePromoCode ? 'error' : ''}
                help={errors.thankYouPagePromoCode}>
                <Input
                  placeholder={
                    thankYouData.thankYouPagePromoCode || 'Enter text here'
                  }
                  value={thankYouData?.thankYouPagePromoCode || ''}
                  onChange={(e) =>
                    handleChangeInput({
                      key: 'thankYouPagePromoCode',
                      value: e.target.value,
                    })
                  }
                />
              </Form.Item>
            </Col>
          </Row>

          <Row style={{ marginTop: 20 }}>
            <Col span={6}>
              <h2>{'*CTA'}</h2>
              <p style={{ marginTop: -10 }}>
                It should contain a maximum characters of:{' '}
                {MaxLengthFields.thankYouPageCta}
              </p>
              <Form.Item
                colon={false}
                style={{ transition: 'none' }}
                validateStatus={errors.thankYouPageCta ? 'error' : ''}
                help={errors.thankYouPageCta}>
                <Input
                  placeholder={
                    thankYouData.thankYouPageCta || 'Enter CTA text here'
                  }
                  value={thankYouData?.thankYouPageCta || ''}
                  onChange={(e) =>
                    handleChangeInput({
                      key: 'thankYouPageCta',
                      value: e.target.value,
                    })
                  }
                />
              </Form.Item>
            </Col>
          </Row>
        </Fragment>
      )}

      {/* CONCERN */}
      {thankYouData.thankYouPageType === ThankYouPageTypes.CONCERN && (
        <Fragment>
          <Row style={{ marginTop: 50 }}>
            <Col span={6}>
              <h2>{'*Title'}</h2>
              <p style={{ marginTop: -10 }}>
                It should contain a maximum characters of:{' '}
                {MaxLengthFields.thankYouPageTitle}
              </p>
              <Form.Item
                colon={false}
                style={{ transition: 'none' }}
                validateStatus={errors.thankYouPageTitle ? 'error' : ''}
                help={errors.thankYouPageTitle}>
                <Input
                  placeholder={
                    thankYouData.thankYouPageTitle || 'Enter text here'
                  }
                  value={thankYouData?.thankYouPageTitle || ''}
                  onChange={(e) =>
                    handleChangeInput({
                      key: 'thankYouPageTitle',
                      value: e.target.value,
                    })
                  }
                />
              </Form.Item>
            </Col>
          </Row>

          <Row style={{ marginTop: 20 }}>
            <Col span={6}>
              <h2>{'*CTA'}</h2>
              <p style={{ marginTop: -10 }}>
                It should contain a maximum characters of:{' '}
                {MaxLengthFields.thankYouPageCta}
              </p>
              <Form.Item
                colon={false}
                validateStatus={errors.thankYouPageCta ? 'error' : ''}
                help={errors.thankYouPageCta}>
                <Input
                  placeholder={
                    thankYouData.thankYouPageCta || 'Enter CTA text here'
                  }
                  value={thankYouData?.thankYouPageCta || ''}
                  onChange={(e) =>
                    handleChangeInput({
                      key: 'thankYouPageCta',
                      value: e.target.value,
                    })
                  }
                />
              </Form.Item>
            </Col>
          </Row>
        </Fragment>
      )}

      {thankYouData.thankYouPageType && (
        <Button
          loading={load}
          disabled={load}
          type="primary"
          htmlType="submit"
          className="login-form-button"
          style={{ marginTop: 50 }}>
          Save Thank you page
        </Button>
      )}
    </Form>
  );
};

export default ThankyouForm;
