import { ComboDataPlatform } from '../combos/ComboInterfaces';
import config from '../config';
import { CON_PlatformID_Enum, PIN_PlatformID_Enum } from '../shared';
import { loadItem, PLATFORM_CODE_KEY, PLATFORM_KEY } from '../utils';

export const getCurrencyValue = (value: number) =>
  `${config.APP.CURRENCY}${value}`;

export const getFrontUrl = (
  currentPlatform: string,
  platforms: ComboDataPlatform[] | null,
) => {
  let frontUrl = config.APP.LIWW_URL;

  if (!currentPlatform || !platforms?.length) return frontUrl;

  const platform = platforms.find(
    ({ value, extra }) => extra && value === currentPlatform,
  );
  if (!platform) return frontUrl;

  return platform.extra;
};

// TODO this function is temporal, until platform table has "platform_type" column
export const isConPlatform = () => {
  const platformCode = loadItem(PLATFORM_CODE_KEY);
  return Object.values(CON_PlatformID_Enum).includes(platformCode);
};

export const isConsumablesDefaultPlatform = () => {
  const platformCode = loadItem(PLATFORM_CODE_KEY);
  return CON_PlatformID_Enum.WEBEAT_ES === platformCode;
};

export const isWebeatPlatform = () => {
  const platformCode = loadItem(PLATFORM_CODE_KEY);
  return platformCode?.includes('webeat');
};

export const isPINPlatform = (): boolean => {
  const platformCode = loadItem(PLATFORM_CODE_KEY);
  return Object.values(PIN_PlatformID_Enum).includes(platformCode);
};

export const getPlatformBaseUrl = () => {
  return loadItem(PLATFORM_KEY) || config.API.ROOT_URL;
};
