import { isPINPlatform, isWebeatPlatform } from '../../utils';

export const booleanFields = [
  { key: 'active' },
  ...(isWebeatPlatform() ? [{ key: 'isTest', helper: 'challenge.tooltip.isTest' }] : []),
  { key: 'featured' },
  { key: 'showList' },
  { key: 'showPoints' },
  { key: 'showHeader' },
  { key: 'showImageHeader' },
  ...(isPINPlatform() ? [{ key: 'showDuration' }] : []),
];

export const challengeDates = [
  { key: 'startVisDate' },
  { key: 'startDate' },
  { key: 'endDate' },
  { key: 'endVisDate' },
];
