import React from 'react';
import { injectIntl, InjectedIntlProps } from 'react-intl';
import { connect } from 'react-redux';
import { Dispatch, AnyAction, bindActionCreators } from 'redux';
import { Row, Col, Tabs, Drawer, PageHeader, Modal, Button } from 'antd';
import { ReducersState } from '../reducers';
import { isEmpty } from 'lodash';
import { History } from 'history';
import { feedbackController } from '../utils/feedback';
import { appComponents } from '../components';
import apiPaths from '../apiPaths';
import config from '../config';

import { editNavigationState } from '../app/queryActions';
import { resetSearchComponent } from '../forms/search/searchActions';
import { setSelectedM2MDashboard } from '../m2m/m2mActions';
import { combosCustom, combosCustomType } from '../combosCustom';
import {
  setInitialState,
  setSelectedTab,
  setDrawerVisibility,
  resetDashboardSelectedTab,
} from './dashboardActions';
import {
  resetTableComponent,
  getTableData,
  setFormStateFlag,
  setSelectedRow,
  setChildSelectedRow,
  target,
} from '../tables/tableActions';
import {
  resetEditComponent,
  setSelectedTabEdit,
} from '../forms/edit/editActions';
import {
  getComboData,
  addCustomCombos,
  initializeComponentCombos,
} from '../combos/comboActions';

import EditForm from '../forms/edit/EditForm';
import SearchForm from '../forms/search/SearchForm';
import SmartTable from '../tables/SmartTable';
import './DashboardComposer.css';
import customActions from '../extensions/customActions';

import { BasicField } from '../fields/FieldsInterfaces';
import {
  ITableComponent,
  Column,
  QueryParams,
  TableData,
} from '../tables/tableInterfaces';
import {
  IFormComponent,
  IEditComponent,
  ISearchComponent,
} from '../forms/formInterfaces';
import { ComponentParams } from './DashboardInterfaces';
import { IComponentsInitializerListProps } from './ComponentsInitializer';
import { IBreadcrumb } from '../breadcrumb/SmartBreadcrumbInterfaces';
import { M2MDashboardListProps } from '../m2m/M2MDashboard';
import { IRow } from '../app/AppInterfaces';

import Challenge from '../challenge/Challenge';
import { setChallengeInitialState } from '../challenge/challengeActions';
import ContentPage from '../contentPage/ContentPage';
import WebeatChallenge from '../challenge/WebeatChallenge';
import WebeatContentPage from '../contentPage/WebeatContentPage';
import ScanLanding from '../scanLanding/ScanLanding';
import { WebeatSubscriptionModalEditForm } from '../forms/webeat-subscription-modal/subscription-modal-edit-form';

//------- GUARDS SECTION ---------//

export function isColumn(column: BasicField | Column): column is Column {
  if ((column as Column).position !== undefined) {
    return true;
  }
  return false;
}

export function isTable(
  table: IFormComponent | ITableComponent,
): table is ITableComponent {
  if ((table as ITableComponent).params.type === 'table') {
    return true;
  }
  return false;
}

export function isEdit(
  edit: IEditComponent | ISearchComponent | ITableComponent,
): edit is IEditComponent {
  if ((edit as IEditComponent).params.type === 'edit') {
    return true;
  }
  return false;
}

export function isSearch(
  search: IEditComponent | ITableComponent | ISearchComponent,
): search is ISearchComponent {
  if ((search as ISearchComponent).params.type === 'search') {
    return true;
  }
  return false;
}

//------- END GUARDS --------//

interface OwnProps {
  components: (IFormComponent | ITableComponent)[];
  customDashboard?: string;
  history: History;
  intl: typeof InjectedIntlProps;
}

export type TDashboardComposerListProps = ReturnType<
  typeof mapDispatchToProps
> &
  ReturnType<typeof mapStateToProps> &
  OwnProps &
  IComponentsInitializerListProps &
  M2MDashboardListProps;

class DashboardComposer extends React.PureComponent<
  TDashboardComposerListProps,
  {}
> {
  constructor(props: TDashboardComposerListProps) {
    super(props);
    const {
      components,
      dashboardId,
      combos,
      setInitialState,
      addCustomCombos,
      history,
      initializeComponentCombos,
      selectedTab,
      selectedComponent,
      setSelectedM2MDashboard,
    } = props;

    const layoutType = this.getDashboardLayoutType(components);

    let initialDashboardProps: {
      dashboardId: string;
      layoutType: number;
      drawerVisible: boolean;
      selectedTab?: string;
      selectedComponent?: string;
    };

    initialDashboardProps =
      history.location.search !== ''
        ? {
            dashboardId,
            layoutType,
            drawerVisible: this.props.drawerVisible,
          }
        : {
            dashboardId,
            layoutType,
            drawerVisible: false,
          };
    if (selectedTab && selectedComponent) {
      initialDashboardProps.selectedTab = selectedTab;
      initialDashboardProps.selectedComponent = selectedComponent;
    }
    setInitialState({ ...initialDashboardProps });

    components.forEach((component: ITableComponent | IFormComponent) => {
      if (
        component.params.type === 'table' &&
        component.params.componentId === selectedComponent
      ) {
        if (component.hasOwnProperty('m2m')) {
          setSelectedM2MDashboard({
            activeDashboard: component.m2m.dashboardId,
          });
        }
      }
    });

    this.loadCombos(
      components,
      combos,
      combosCustom,
      addCustomCombos,
      initializeComponentCombos,
    );
  }

  componentWillUnmount() {
    const {
      resetEditComponent,
      resetSearchComponent,
      resetTableComponent,
      components,
      resetDashboardSelectedTab,
      dashboardId,
    } = this.props;
    if (config.COMPONENT.FORM.RESET_ON_UNMOUNT) {
      components.forEach((component: ITableComponent | IFormComponent) => {
        const { type, componentId } = component.params;
        if (isEdit(component)) {
          resetEditComponent({ componentId });
        }
        if (type === 'search') resetSearchComponent({ componentId });
        if (
          isTable(component) &&
          component.params.hasOwnProperty('isMainTable') &&
          component.params.isMainTable !== false
        )
          resetTableComponent({ componentId });
      });
      resetDashboardSelectedTab({ dashboardId });
    }
  }

  getDashboardLayoutType = (
    components: (ITableComponent | IFormComponent)[],
  ) => {
    let layoutType = undefined;
    let searchExist = false;
    let editExist = false;
    components.forEach((component) => {
      if (isSearch(component)) searchExist = true;
      if (isEdit(component)) editExist = true;
    });

    if (!searchExist) layoutType = 1;
    else if (!editExist) layoutType = 2;
    else if (!searchExist && !editExist) layoutType = 3;
    else layoutType = 0;

    return layoutType;
  };

  formatCombosCustom = (combos: combosCustomType) => {
    let formattedCombos = {};
    for (let comboId in combos) {
      formattedCombos = {
        ...formattedCombos,
        [comboId]: {
          isLoading: false,
          data: combos[comboId],
        },
      };
    }
    return formattedCombos;
  };

  loadCombos(
    components: (ITableComponent | IFormComponent)[],
    combos: ReducersState['combos'],
    combosCustom: combosCustomType,
    addCustomCombos: Function,
    initializeComponentCombos: Function,
  ) {
    if (!isEmpty(combosCustom))
      addCustomCombos(this.formatCombosCustom(combosCustom));
    components.forEach((c) => {
      const componentId = c.params.componentId;
      if (c.params.mustRender === undefined || c.params.mustRender) {
        if (!combos.combos[componentId]) {
          initializeComponentCombos({ componentId });
        }
        c.fields.forEach((f: BasicField | Column) => {
          if (
            (isColumn(f) && f.render === 'selector') ||
            (!isColumn(f) && f.type === 'selector') ||
            (isColumn(f) && f.render === 'SVGSelector') ||
            (!isColumn(f) && f.type === 'SVGSelector')
          ) {
            this.getSelector(f.key, f.comboId!, componentId, f.selectorPath!);
          }
          if (
            (f.mustRender === undefined || f.mustRender) &&
            !this.isExistingStaticCombo(combos, c.params, f) &&
            !f.hasOwnProperty('parentValue')
          ) {
            if (
              (isColumn(f) && f.render === 'combo') ||
              (!isColumn(f) &&
                (f.type === 'combo' ||
                  f.type === 'radio' ||
                  f.type === 'advancedSearch'))
            ) {
              this.getCombo(f.key, f.comboId!, componentId);
            }
          }
          const objectArrayFieldsField = f as any;
          if (objectArrayFieldsField?.objectArrayFields?.length) {
            objectArrayFieldsField.objectArrayFields.forEach((element: any) => {
              element.fields.forEach((e: any) => {
                if (e.mustRender === undefined || e.mustRender) {
                  if (
                    (isColumn(f) && e.render === 'combo') ||
                    (!isColumn(f) &&
                      (e.type === 'combo' ||
                        e.type === 'radio' ||
                        e.type === 'advancedSearch'))
                  ) {
                    this.getCombo(e.key, e.comboId!, componentId);
                  } else if (
                    (isColumn(f) && e.render === 'selector') ||
                    (!isColumn(f) && e.type === 'selector') ||
                    (isColumn(f) && e.render === 'SVGSelector') ||
                    (!isColumn(f) && e.type === 'SVGSelector')
                  ) {
                    let key = e.key;
                    if (e.key.includes('.')) {
                      const splitKey = e.key.split('.');
                      key = splitKey[splitKey.length - 1];
                    }
                    this.getSelector(
                      key,
                      e.comboId!,
                      componentId,
                      e.selectorPath!,
                    );
                  }
                }
              });
            });
          }
        });
      }
    });
  }

  checkIfIsColumn(column: BasicField | Column): column is Column {
    if ((column as Column).position !== undefined) {
      return true;
    }
    return false;
  }

  getCombo = (key: string, comboId: string, componentId: string) => {
    const { combos, getComboData } = this.props;

    if (
      !(
        combos.combos[componentId] &&
        combos.combos[componentId][key] &&
        combos.combos[componentId][key][comboId].data
      )
    ) {
      getComboData({
        dataPath: apiPaths.COMBO,
        componentId,
        fieldKey: key,
        comboId: comboId,
        queryParams: {
          id: comboId,
        },
      });
    }
  };

  getSelector = (
    key: string,
    comboId: string,
    componentId: string,
    path: string,
  ) => {
    const { combos, getComboData } = this.props;
    if (
      !(
        combos.combos[componentId] &&
        combos.combos[componentId][key] &&
        combos.combos[componentId][key][comboId].data
      )
    ) {
      getComboData({
        dataPath: path,
        componentId,
        fieldKey: key,
        comboId: comboId,
        queryParams: {},
      });
    }
  };

  isExistingStaticCombo = (
    combos: ReducersState['combos'],
    params: ComponentParams,
    f: BasicField | Column,
  ) => {
    let key: string = f.key;

    return (
      combos.combos[params.componentId] &&
      combos.combos[params.componentId][key] &&
      combos.combos[params.componentId][key][f.comboId!] &&
      'key' in f &&
      !f.key.includes('.')
    );
  };

  isTabDisabled = (table: ITableComponent) =>
    !(
      this.props.tables[table.params.componentId] &&
      this.props.tables[table.params.componentId].parentSelectedRow &&
      !isEmpty(this.props.tables[table.params.componentId].parentSelectedRow)
    ) || table.params.disabled;

  pageHeaderTitle = (breadcrumbs: IBreadcrumb[]) =>
    isEmpty(breadcrumbs[breadcrumbs.length - 1].child) ? (
      <span className="pageHeader__title--italic">
        {this.props.intl.formatMessage({ id: 'breadcrumb.newRecord' })}
      </span>
    ) : (
      <span className="pageHeader__title">
        {breadcrumbs[breadcrumbs.length - 1].child.name}
      </span>
    );

  pageHeaderSubTitle = (breadcrumbs: IBreadcrumb[]) => (
    <span className="pageHeader__subTitle">
      {this.props.breadcrumbs[breadcrumbs.length - 1].name}
    </span>
  );

  handleChangeDrawerVisibility = (
    tableComponentId: string,
    editComponentId: string,
  ) => {
    const {
      dashboardId,
      setDrawerVisibility,
      setFormStateFlag,
      setSelectedRow,
      tables,
      setSelectedTabEdit,
      challengeHasChanged,
    } = this.props;
    const formHasChanged = this.props.tables[tableComponentId].formHasChanged;
    if (
      config.FEEDBACK.CONFIRM.RECORD_CHANGED &&
      (formHasChanged || challengeHasChanged)
    ) {
      Modal.confirm({
        title: this.props.intl.formatMessage({ id: 'pop.title.select' }),
        content: this.props.intl.formatMessage({ id: 'pop.title.select.warn' }),
        okText: this.props.intl.formatMessage({ id: 'pop.accept' }),
        cancelText: this.props.intl.formatMessage({ id: 'pop.cancel' }),
        // icon: 'warning',
        maskClosable: true,
        onOk() {
          setSelectedRow({
            componentId: tableComponentId,
            selectedRow: tables[tableComponentId].selectedRow,
          });
          setDrawerVisibility({ dashboardId, visible: false });
          setFormStateFlag({
            componentId: tableComponentId,
            formHasChanged: false,
          });
          setSelectedTabEdit({
            componentId: editComponentId,
            selectedTab: '0',
          });
        },
        onCancel() {}, //TODO Define cancel
      });
    } else {
      setSelectedRow({
        componentId: tableComponentId,
        selectedRow: tables[tableComponentId].selectedRow,
      });
      setDrawerVisibility({ dashboardId, visible: false });
      setFormStateFlag({
        componentId: tableComponentId,
        formHasChanged: false,
      });
      setSelectedTabEdit({
        componentId: editComponentId,
        selectedTab: '0',
      });
    }
  };

  dashboardLayout = <
    T extends ISearchComponent | IEditComponent | ITableComponent,
  >(
    dashboardComponents: T[],
  ) => {
    const { selectedTab, layoutType, history, selectedComponent } = this.props;
    // comprobar la propiedad forceVisible y forceDisable
    let searchComponent: ISearchComponent;
    let editComponent: IEditComponent;
    let primaryTableComponent: ITableComponent;
    let secondaryTableComponents: ITableComponent[] = [];
    dashboardComponents.forEach(
      (component: ISearchComponent | IEditComponent | ITableComponent) => {
        if (isEdit(component)) editComponent = component;
        if (isSearch(component)) searchComponent = component;
        if (isTable(component)) {
          if (component.params.isMainTable === true)
            primaryTableComponent = component;
          else secondaryTableComponents.push(component);
          // TODO evaluar mustReder y tabindex
          // .map((element, i) => {
          //   element.params.tabIndex = i;
          //   return element;
          // });
        }
        if ('type' in component && component['type'] === 'edit')
          editComponent = component as IEditComponent;
      },
    );

    let activeK;
    if (layoutType === 0 || layoutType === 1) {
      activeK = selectedTab
        ? selectedTab
        : editComponent! !== undefined
        ? editComponent!.params.tabIndex.toString()
        : '0';
    }
    const parentFormHasChanged =
      primaryTableComponent! !== undefined
        ? this.props.tables[primaryTableComponent!.params.componentId] &&
          this.props.tables[primaryTableComponent!.params.componentId]
            .formHasChanged
        : undefined;

    const tableProps =
      this.props.customDashboard &&
      customActions[this.props.customDashboard] &&
      customActions[this.props.customDashboard].table !== undefined
        ? { ...customActions[this.props.customDashboard].table!(this.props) }
        : { ...this.props };

    const editProps =
      this.props.customDashboard &&
      customActions[this.props.customDashboard] &&
      customActions[this.props.customDashboard].edit
        ? { ...customActions[this.props.customDashboard].edit!(this.props) }
        : { ...this.props };

    const handleSelectRow = async ({
      data,
      next,
      tableId,
      actualRowPosition,
      size,
      number,
      rowKey,
    }: {
      data: TableData;
      next: boolean;
      tableId: string;
      actualRowPosition: number;
      size: number;
      number: number;
      rowKey: string;
    }) => {
      const { challengeHasChanged, setChallengeInitialState } = this.props;
      const formHasChanged = this.props.tables[tableId].formHasChanged;
      const targetsId: { id: string; path: string }[] =
        appComponents[tableId].targetsId;
      const prettierKey = this.props.tables[tableId].prettierKey;
      if (
        config.FEEDBACK.CONFIRM.RECORD_CHANGED &&
        (formHasChanged || challengeHasChanged)
      ) {
        Modal.confirm({
          title: this.props.intl.formatMessage({ id: 'pop.title.select' }),
          content: this.props.intl.formatMessage({
            id: 'pop.title.select.warn',
          }),
          okText: this.props.intl.formatMessage({ id: 'pop.accept' }),
          cancelText: this.props.intl.formatMessage({ id: 'pop.cancel' }),
          // icon: 'warning',
          maskClosable: true,
          async onOk() {
            setChallengeInitialState();
            getInitialTableData(
              data,
              next,
              actualRowPosition,
              number,
              tableId,
              targetsId,
              rowKey,
              size,
              prettierKey,
            );
          },
          onCancel() {},
        });
      } else {
        getInitialTableData(
          data,
          next,
          actualRowPosition,
          number,
          tableId,
          targetsId,
          rowKey,
          size,
          prettierKey,
        );
      }
    };

    const getInitialTableData = async (
      data: TableData,
      next: boolean,
      actualRowPosition: number,
      number: number,
      tableId: string,
      targetsId: { id: string; path: string }[],
      rowKey: string,
      size: number,
      prettierKey: string,
    ) => {
      const {
        setSelectedRow,
        getTableData,
        setFormStateFlag,
        setChildSelectedRow,
      } = tableProps;
      let newRow: IRow;

      if (data && data.content) {
        if (!next && actualRowPosition === 0 && number > 0) {
          const response: any = await getTableData({
            dataPath: appComponents[tableId].path,
            componentId: tableId,
            queryParams: { page: number - 1 },
          });
          newRow = response.data.content[response.data.content.length - 1];
        } else if (next && actualRowPosition === size - 1) {
          const response: any = await getTableData({
            dataPath: appComponents[tableId].path,
            componentId: tableId,
            queryParams: { page: number + 1 },
          });
          newRow = response.data.content[0];
        } else {
          newRow = next
            ? data.content[actualRowPosition + 1]
            : data.content[actualRowPosition - 1];
        }
        setSelectedRow({ componentId: tableId, selectedRow: newRow });
        setChildSelectedRow({
          targetsId,
          record: newRow,
          prettierKey,
          rowKey,
        });

        targetsId.forEach(async (target: target) => {
          if (target.id === selectedComponent) {
            let queryParams: QueryParams = { q: '' };
            let currentComponent: ITableComponent;
            dashboardComponents.forEach((comp) => {
              if (
                comp.params.componentId === selectedComponent &&
                isTable(comp)
              )
                currentComponent = comp;
            });

            currentComponent!.fields.forEach((col: Column) => {
              if (
                col.hasOwnProperty('defaultSortOrder') &&
                !isEmpty(col.defaultSortOrder)
              ) {
                queryParams = {
                  ...queryParams,
                  sort: col.defaultSortOrder === 'descend' ? 'DESC' : 'ASC',
                  field: col.key,
                };
              }
            });

            if (currentComponent!.params.navigationExtensions) {
              currentComponent!.params.navigationExtensions.forEach(
                (element) => {
                  if (element.hasOwnProperty('value'))
                    queryParams.q += `${element.key}${config.QUERY.ID_OPERATOR}${element.value}${config.QUERY.AND}`;
                  else
                    queryParams.q += `${element.key}${
                      config.QUERY.ID_OPERATOR
                    }${newRow[element.key]}${config.QUERY.AND}`;
                },
              );
            }

            queryParams.q += `${target.path}${config.QUERY.ID_OPERATOR}${newRow[rowKey]}`;

            await getTableData({
              dataPath:
                appComponents[currentComponent!.params.componentId].path,
              componentId: currentComponent!.params.componentId,
              queryParams,
              foreignFilters: `status${config.QUERY.ID_OPERATOR}true`,
            });
          }
        });
      }
      setFormStateFlag({
        componentId: tableId,
        formHasChanged: false,
      });
    };

    const getRowPosition = ({
      data,
      rowKey,
      selectedRow,
    }: {
      data: TableData;
      rowKey: string;
      selectedRow: IRow;
    }) => {
      let rowPosition = 0;
      if (data && data.content)
        data.content.forEach((record, i) => {
          if (record[rowKey] === selectedRow[rowKey]) rowPosition = i;
        });
      return rowPosition;
    };

    const rowNavigationButtons = ({
      tableId,
      rowKey,
    }: {
      tableId: string;
      rowKey: string;
    }) => {
      const { selectedRow, data } = this.props.tables[tableId];

      if (data) {
        let isLoading = false;
        if (selectedComponent && this.props.tables[selectedComponent])
          isLoading = this.props.tables[selectedComponent].isLoading;

        const { totalElements, number, size } = data;
        const actualRowPosition = getRowPosition({
          data,
          rowKey,
          selectedRow,
        });

        const buttonDisabled =
          !selectedComponent || appComponents[selectedComponent].type === 'edit'
            ? false
            : isLoading;
        if (!isEmpty(selectedRow))
          return (
            <div>
              <span className="counter">
                {size * number + actualRowPosition + 1} / {totalElements}
              </span>
              <Button.Group>
                <Button
                  icon="left"
                  size="large"
                  disabled={
                    (actualRowPosition === 0 && number === 0) || buttonDisabled
                  }
                  onClick={() =>
                    handleSelectRow({
                      data,
                      next: false,
                      tableId,
                      actualRowPosition,
                      size,
                      number,
                      rowKey,
                    })
                  }
                />
                <Button
                  icon="right"
                  size="large"
                  disabled={
                    size * number + actualRowPosition + 1 === totalElements ||
                    isLoading
                  }
                  onClick={() =>
                    handleSelectRow({
                      next: true,
                      data,
                      tableId,
                      actualRowPosition,
                      number,
                      size,
                      rowKey,
                    })
                  }
                />
              </Button.Group>
            </div>
          );
      }
    };

    return (
      <div>
        <Row
          style={{ height: '100%' }}
          type="flex"
          justify="space-around"
          align="middle">
          <Col span={24}>
            <Row key={'search'}>
              <SearchForm
                {...{
                  ...searchComponent!,
                  ...this.props,
                  dashboardId: this.props.dashboardId,
                }}
                options={searchComponent!.settings}
              />
            </Row>
            <Row key={'table'}>
              <SmartTable
                {...{
                  ...primaryTableComponent!,
                  ...tableProps,
                  dashboardId: this.props.dashboardId,
                }}
              />
              {(layoutType === 0 || layoutType === 1) && (
                <Drawer
                  width={
                    window.innerWidth < config.BREAKPOINTS.XL ? '100%' : '90%'
                  }
                  onClose={() =>
                    this.handleChangeDrawerVisibility(
                      primaryTableComponent.params.componentId,
                      editComponent?.params.componentId,
                    )
                  }
                  destroyOnClose
                  visible={this.props.drawerVisible}
                  closable={false} // poner a true cuando se solucione el problema de la "X" del close
                >
                  <PageHeader
                    className="pageHeader"
                    onBack={() =>
                      this.handleChangeDrawerVisibility(
                        primaryTableComponent.params.componentId,
                        editComponent?.params.componentId,
                      )
                    }
                    subTitle={this.pageHeaderTitle(this.props.breadcrumbs)}
                    title={this.pageHeaderSubTitle(this.props.breadcrumbs)}
                    extra={rowNavigationButtons({
                      tableId: primaryTableComponent!.params.componentId,
                      rowKey: primaryTableComponent!.params.rowKey,
                    })}></PageHeader>
                  {editComponent!.params.isChallenge ? (
                    <Challenge
                      {...{
                        params: editComponent!.params,
                        fields: editComponent!.fields,
                        intl: this.props.intl,
                      }}
                    />
                  ) : editComponent!.params.isWebeatChallenge ? (
                    <WebeatChallenge
                      {...{
                        params: editComponent!.params,
                        fields: editComponent!.fields,
                        intl: this.props.intl,
                      }}
                    />
                  ) : editComponent!.params.isPageContent ? (
                    <ContentPage
                      {...{
                        params: editComponent!.params,
                        fields: editComponent!.fields,
                        intl: this.props.intl,
                        dashboardId: this.props.dashboardId,
                      }}
                    />
                  ) : editComponent!.params.isWebeatContentPage ? (
                    <WebeatContentPage
                      {...{
                        params: editComponent!.params,
                        fields: editComponent!.fields,
                        intl: this.props.intl,
                        dashboardId: this.props.dashboardId,
                      }}
                    />
                  ) : editComponent!.params.isScanLanding ? (
                    <ScanLanding
                      {...{
                        params: editComponent!.params,
                        fields: editComponent!.fields,
                        intl: this.props.intl,
                        dashboardId: this.props.dashboardId,
                      }}
                    />
                  ) : editComponent!.params.isWebeatSubscriptionModalPage ? (
                    <WebeatSubscriptionModalEditForm />
                  ) : isEmpty(secondaryTableComponents) ? (
                    <EditForm
                      {...{
                        ...editProps,
                        customDashboard: this.props.customDashboard,
                        dashboardId: this.props.dashboardId,
                        params: editComponent!.params,
                        groups: editComponent!.groups,
                        fields: editComponent!.fields,
                        options: editComponent!.settings,
                        history: history,
                      }}

                      // drawerVisible={this.props.drawerVisible}
                    />
                  ) : (
                    <Tabs
                      type="card"
                      activeKey={activeK}
                      onChange={(activeKey: string) =>
                        this.handleChangeTab(
                          activeKey,
                          [editComponent, ...secondaryTableComponents],
                          primaryTableComponent.params.componentId,
                        )
                      }>
                      <Tabs.TabPane
                        tab={editComponent!.params.panelHeader}
                        key={editComponent!.params.tabIndex.toString()}
                        className="tabContainer">
                        <EditForm
                          {...{
                            ...editProps,
                            customDashboard: this.props.customDashboard,
                            dashboardId: this.props.dashboardId,
                            params: editComponent!.params,
                            groups: editComponent!.groups,
                            fields: editComponent!.fields,
                            options: editComponent!.settings,
                            history: history,
                          }}

                          // drawerVisible={this.props.drawerVisible}
                        />
                      </Tabs.TabPane>
                      {secondaryTableComponents.map((table) => (
                        <Tabs.TabPane
                          tab={table.params.panelHeader}
                          key={table.params.tabIndex!.toString()}
                          disabled={this.isTabDisabled(table)}
                          className="tabContainer">
                          <SmartTable
                            {...{
                              ...table,
                              ...this.props,
                              dashboardId: this.props.dashboardId,
                              parentFormHasChanged: parentFormHasChanged,
                            }}
                          />
                        </Tabs.TabPane>
                      ))}
                    </Tabs>
                  )}
                </Drawer>
              )}
            </Row>
          </Col>
        </Row>
      </div>
    );
  };

  checkComponentPermissions = (
    components: (ITableComponent | IFormComponent)[],
  ) => {
    let componentsArray = components.slice();
    const { userPermissions } = this.props;
    componentsArray.forEach((component) => {
      const { permissions } = component.params;
      if (permissions) {
        permissions.forEach((permission) => {
          if (userPermissions!.includes(permission.name)) {
            for (let key in permission) {
              switch (key) {
                case 'disabled':
                  component.params.forceDisabled = permission['disabled'];
                  break;
                case 'visible':
                  component.params.forceVisible = permission['visible'];
                  break;
                case 'mandatory':
                  component.params.forceMandatory = permission['mandatory'];
                  break;
              }
            }
          }
        });
      }
    });
    return componentsArray;
  };

  handleChangeTab = async <T extends IEditComponent | ITableComponent>(
    activeKey: string,
    tabs: T[],
    mainTableId: string,
  ) => {
    const {
      setSelectedM2MDashboard,
      setSelectedTab,
      dashboardId,
      tables,
      getTableData,
    } = this.props;

    const selectedTab = tabs[parseInt(activeKey)];
    if (selectedTab.hasOwnProperty('m2m'))
      setSelectedM2MDashboard({
        activeDashboard: selectedTab.m2m.dashboardId,
      });

    if (isTable(selectedTab)) {
      const selectedId: string = selectedTab.params.componentId;
      const parentPkRecord =
        tables[selectedId].parentSelectedRow![tables[selectedId].parentRowKey!];
      // los query params no deberian coger el navigationId... deberia haber parametro en el dashboard que indique
      // que params ha de coger, ya que estos pueden ser diferentes (como la navegación de Clientes -> Direcciones - Contactos)
      const componentId = selectedTab.params.componentId;
      let path = '';
      if (mainTableId in appComponents && selectedId in appComponents) {
        appComponents[mainTableId].targetsId.forEach((id: target) => {
          if (id.id === componentId) {
            path = id.path;
          }
        });

        let queryParams: QueryParams = { q: '' };

        selectedTab.fields.forEach((col: Column) => {
          if (
            col.hasOwnProperty('defaultSortOrder') &&
            !isEmpty(col.defaultSortOrder)
          ) {
            queryParams = {
              ...queryParams,
              sort: col.defaultSortOrder === 'descend' ? 'DESC' : 'ASC',
              field: col.key,
            };
          }
        });

        if (selectedTab.params.navigationExtensions) {
          selectedTab.params.navigationExtensions.forEach((element: IRow) => {
            if (element.hasOwnProperty('value'))
              queryParams.q += `${element.key}${config.QUERY.ID_OPERATOR}${element.value}${config.QUERY.AND}`;
            else
              queryParams.q += `${element.key}${config.QUERY.ID_OPERATOR}${
                tables[mainTableId].selectedRow[element.key]
              }${config.QUERY.AND}`;
          });
        }

        queryParams.q += `${path}${config.QUERY.ID_OPERATOR}${parentPkRecord}`;

        await getTableData({
          dataPath: appComponents[selectedId].path,
          componentId: selectedId,
          queryParams,
        });
      }
    }

    setSelectedTab({
      dashboardId,
      tabIndex: selectedTab.params.tabIndex!.toString(),
      componentId: selectedTab.params.componentId,
    });
  };

  render() {
    const { components } = this.props;
    const checkedComponents = this.checkComponentPermissions(components);
    return this.dashboardLayout(checkedComponents);
  }
}

const mapStateToProps = (
  state: ReducersState,
  ownProps: IComponentsInitializerListProps,
) => {
  return {
    editFormNavigation: state.query.editFormNavigation,
    searchFormNavigation: state.query.searchFormNavigation,
    layoutType: state.dashboards[ownProps.dashboardId].layoutType,
    drawerVisible: state.dashboards[ownProps.dashboardId].drawerVisible,
    selectedTab: state.dashboards[ownProps.dashboardId].selectedTab,
    selectedComponent: state.dashboards[ownProps.dashboardId].selectedComponent,
    combos: state.combos,
    tables: state.tables,
    breadcrumbs: state.query.breadcrumbs,
    userPermissions: state.app.permissions,
    challengeHasChanged:
      state.challenge.editingChallenge ||
      state.challenge.editingResource ||
      state.challenge.sortingResources,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    {
      addCustomCombos,
      editNavigationState,
      feedback: feedbackController,
      getComboData,
      getTableData,
      initializeComponentCombos,
      setChildSelectedRow,
      setDrawerVisibility,
      setChallengeInitialState,
      setFormStateFlag,
      setInitialState,
      setSelectedM2MDashboard,
      setSelectedRow,
      setSelectedTab,
      setSelectedTabEdit,
      resetSearchComponent,
      resetEditComponent,
      resetTableComponent,
      resetDashboardSelectedTab,
    },
    dispatch,
  );

export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(DashboardComposer),
);
