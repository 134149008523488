import { Checkbox, Input } from "antd";
import React, { FC } from "react";
import { HomeSectionList, HomeSectionListKey } from "./WebeatHomeSectionEnum";
import { WebeatHomeSectionsConfig } from "./WebeatHomeSectionsConfig";

const RenderField: FC<{
  handleChange: (key: string, value: any) => void;
  fieldConfig: {
    key: string;
    label: string;
    type: string;
    placeholder: string;
    required?: boolean;
  };
  value: any;
}> = ({ handleChange, fieldConfig, value }) => {
  if (fieldConfig.type === 'text') {
    return (
      <Input
        value={value}
        onChange={(e) => handleChange(fieldConfig.key, e.target.value)}
        placeholder={fieldConfig.placeholder}
      />
    );
  }

  if (fieldConfig.type === 'checkbox') {
    return (
      <Checkbox
        checked={value}
        onChange={(e) => handleChange(fieldConfig.key, e.target.checked)}
      >
        {fieldConfig.label}
      </Checkbox>
    );
  }

  return null;
}


export const WebeatHomeSectionFields: FC<{
  sectionKey: HomeSectionListKey;
  handleChange: (sectionKey: HomeSectionListKey, active: boolean, content: string) => void;
  active: boolean;
  content: string;
}> = ({ sectionKey, handleChange, active, content }) => {
  const homeSectionListItem = HomeSectionList[sectionKey]
  const [activeState, setActive] = React.useState(!!active);
  const [contentState, setContent] = React.useState(JSON.parse(content));

  const handleChangeActive = (value: boolean) => {
    setActive(value);
    handleChange(sectionKey, value, JSON.stringify(contentState));
  }

  const handleChangeContent = (key: string, value: any) => {
    setContent({
      ...contentState,
      [key]: value
    });
    handleChange(sectionKey, activeState, JSON.stringify({
      ...contentState,
      [key]: value
    }));
  }

  return (
    <div className="webeat-home-sections__panel--content">
      <Checkbox
        checked={activeState}
        onChange={(e) => handleChangeActive(e.target.checked)}
        disabled={homeSectionListItem.required}
      >
        Active
      </Checkbox>
      {
        WebeatHomeSectionsConfig[homeSectionListItem.type].fields.map((fieldConfig) => {
          const fieldValue = contentState[fieldConfig.key];
          return (
            <div key={fieldConfig.key} className="webeat-home-sections__field">
              <label className="webeat-home-sections__label">{fieldConfig.label}</label>
              <RenderField
                fieldConfig={fieldConfig}
                handleChange={handleChangeContent}
                value={fieldValue}
              />
            </div>
          );
        })
      }
    </div>
  );
};
