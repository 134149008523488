export const combosCustom: combosCustomType = {
  status: [
    {
      description: 'Active',
      value: 'true',
    },
    {
      description: 'Deleted',
      value: 'false',
    },
  ],
  optional: [
    {
      description: 'Yes',
      value: 'true',
    },
    {
      description: 'No',
      value: 'false',
    },
  ],
  position: [
    {
      description: 'Left',
      value: 'left',
    },
    {
      description: 'Right',
      value: 'right',
    },
  ],
  imagePosition: [
    {
      description: 'Top',
      value: 'top',
    },
    {
      description: 'Left',
      value: 'left',
    },
    {
      description: 'Right',
      value: 'right',
    },
  ],
  productStatus: [
    {
      description: 'Active',
      value: 'Active',
    },
    {
      description: 'Inactive',
      value: 'Inactive',
    },
  ],
  consumableNature: [
    {
      description: 'Promo',
      value: '3',
    },
    {
      description: 'Post',
      value: '4',
    },
    {
      description: 'Product',
      value: '8',
    },
    {
      description: 'Product SAP',
      value: '9',
    },
    {
      description: 'DI',
      value: '10',
    },
    {
      description: 'DI SAP',
      value: '11',
    },
  ],
  ConsumablePointsOperator: [
    {
      description: 'EQUAL', // '=',
      value: '0',
    },
    {
      description: 'ADD', // '+',
      value: '1',
    },
    {
      description: 'MULTIPLY', // 'x'
      value: '2',
    },
    {
      description: 'DIVIDE', // '/'
      value: '3',
    },
    {
      description: 'PERCENT', // '%'
      value: '4',
    },
  ],
  ConsumableNatureTypes: [
    {
      description: 'QR Dermoconsejeras',
      value: 'QR Dermoconsejeras',
    },
    {
      description: 'QR Evento online',
      value: 'QR Evento online',
    },
    {
      description: 'QR Evento offline',
      value: 'QR Evento offline',
    },
    {
      description: 'QR Punto de venta',
      value: 'QR Punto de venta',
    },
    {
      description: 'QR Branded content',
      value: 'QR Branded content',
    },
    {
      description: 'QR Flagship',
      value: 'QR Flagship',
    },
    {
      description: 'QR Promo Juego / Activación',
      value: 'QR Promo Juego / Activación',
    },
  ],
  webeatLanguage: [
    {
      description: 'ES Spanish',
      value: 'es-ES',
    },
    {
      description: 'ES Mexico',
      value: 'es-MX',
    },
    {
      description: 'ES Chile',
      value: 'es-CL',
    },
    {
      description: 'ES Colombia',
      value: 'es-CO',
    },
    {
      description: 'US English',
      value: 'en-US',
    },
    {
      description: 'ES Mexico',
      value: 'es-MX',
    },
    {
      description: 'ES Chile',
      value: 'es-CL',
    },
    {
      description: 'ES Colombia',
      value: 'es-CO',
    },
  ],
  campaignType: [
    {
      description: 'General',
      value: '0',
    },
    {
      description: 'Black Friday',
      value: '1',
    },
    {
      description: 'General AI',
      value: '2',
    },
    {
      description: 'Black Friday AI',
      value: '3',
    },
  ],
  challengeSearchCriteria: [
    {
      description: 'Name',
      value: 'name',
    },
    {
      description: 'Challenge Id',
      value: 'idChallenge',
    },
    {
      description: 'Slug',
      value: 'slug',
    },
  ],
  usersSearchCriteria: [
    {
      description: 'Email',
      value: 'email',
    },
    {
      description: 'ID Salesforce',
      value: 'idSalesforce',
    },
    {
      description: 'UUID',
      value: 'uuidUser',
    },
  ],
  gridBannersFormatTypes: [
    { description: 'Media', value: 'MEDIA' },
    { description: 'Format 1', value: 'FORMAT_1' },
    { description: 'Format 2', value: 'FORMAT_2' },
  ],
};

export interface combosCustomType {
  [key: string]: { value: string; description: string }[];
}

export const behaviorCombo: behaviorComboType[] = [
  { value: '1', description: 'Disabled' },
  { value: '2', description: 'Not Visible' },
];

export interface behaviorComboType {
  value: string;
  description: string;
}
