import { IHomeSection, WebeatHomeSectionsConfig } from "./WebeatHomeSectionsConfig";

export enum WebeatHomeSectionType {
  Hero = 'Hero',
  Categories = 'Categories',
  Products = 'Products',
  Community = 'Community',
  Experience = 'Experience',
  Testimonials = 'Testimonials',
  EnderHero = 'EnderHero',
}

export const HeroSectionKey = 'Hero';
export const CategoriesSectionKey = 'Categories';
export const HeroBlockSectionKey = 'HeroBlock';
export const ProductsSectionKey = 'Products';
export const CommunitySectionKey = 'Community';
export const ExperienceSectionKey = 'Experience';
export const TestimonialsSectionKey = 'Testimonials';
export const HeroEndBlockSectionKey = 'HeroEndBlock';

export type HomeSectionListKey =
  | typeof HeroSectionKey
  | typeof CategoriesSectionKey
  | typeof HeroBlockSectionKey
  | typeof ProductsSectionKey
  | typeof CommunitySectionKey
  | typeof ExperienceSectionKey
  | typeof TestimonialsSectionKey
  | typeof HeroEndBlockSectionKey;

export const HomeSectionList: Record<HomeSectionListKey, { type: WebeatHomeSectionType; required?: boolean }> = {
  [HeroSectionKey]: {
    type: WebeatHomeSectionType.Hero,
    required: true
  },
  [CategoriesSectionKey]: {
    type: WebeatHomeSectionType.Categories,
  },
  [HeroBlockSectionKey]: {
    type: WebeatHomeSectionType.Hero,
  },
  [ProductsSectionKey]: {
    type: WebeatHomeSectionType.Products,
  },
  [CommunitySectionKey]: {
    type: WebeatHomeSectionType.Community,
  },
  [ExperienceSectionKey]: {
    type: WebeatHomeSectionType.Experience,
  },
  [TestimonialsSectionKey]: {
    type: WebeatHomeSectionType.Testimonials,
  },
  [HeroEndBlockSectionKey]: {
    type: WebeatHomeSectionType.EnderHero,
  },
};

export const HomeSectionListValues: IHomeSection[] = Object.keys(HomeSectionList).reduce((acc: IHomeSection[], key) => {
  const sectionKey = key as HomeSectionListKey;
  acc.push({
    key: sectionKey,
    active: !!HomeSectionList[sectionKey].required,
    content: JSON.stringify({}),
    ...HomeSectionList[sectionKey],
    type: HomeSectionList[sectionKey].type as keyof typeof WebeatHomeSectionsConfig,
  });

  return acc;
}, []);
