import { HomeSectionListKey, WebeatHomeSectionType } from "./WebeatHomeSectionEnum";

export const WebeatHomeSectionsConfig: {
  [key in keyof typeof WebeatHomeSectionType]: {
    fields: {
      key: string;
      label: string;
      type: string;
      placeholder: string;
      required?: boolean;
    }[];
  };
} = {
  [WebeatHomeSectionType.Hero]: {
    fields: [
      {
        key: 'example-field',
        label: 'Example Field Hero',
        type: 'text',
        placeholder: 'Enter hero content',
        required: false,
      },
    ],
  },
  [WebeatHomeSectionType.Categories]: {
    fields: [
      {
        key: 'example-field',
        label: 'Example Field Categories',
        type: 'text',
        placeholder: 'Enter hero content',
        required: false,
      },
    ],
  },
  [WebeatHomeSectionType.Products]: {
    fields: [
      {
        key: 'example-field',
        label: 'Example Field Products',
        type: 'text',
        placeholder: 'Enter hero content',
        required: false,
      },
    ],
  },
  [WebeatHomeSectionType.Community]: {
    fields: [
      {
        key: 'example-field',
        label: 'Example Field Community',
        type: 'text',
        placeholder: 'Enter hero content',
        required: false,
      },
    ],
  },
  [WebeatHomeSectionType.Experience]: {
    fields: [
      {
        key: 'example-field',
        label: 'Example Field Experience',
        type: 'text',
        placeholder: 'Enter hero content',
        required: false,
      },
    ],
  },
  [WebeatHomeSectionType.Testimonials]: {
    fields: []
  },
  [WebeatHomeSectionType.EnderHero]: {
    fields: []
  }
};

export interface IHomeSection {
  active: boolean;
  key: HomeSectionListKey;
  content?: string;
  type: keyof typeof WebeatHomeSectionsConfig;
  required?: boolean;
}