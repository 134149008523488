import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { SubscriptionModal } from '../interfaces/form-common.interfaces';
import { feedback, loadItem, PLATFORM_CODE_KEY } from '../../../utils';
import { Button, Checkbox, Col, Form, Icon, Input, Row } from 'antd';
import FormItem from 'antd/lib/form/FormItem';
import { FirstPageTypes } from '../types/subscription-modal-form-common.types';
import UploadComponent from '../../../challenge/Components/UploadComponent';
import { UPLOAD_ACTION_IMAGE } from '../../../shared';
import Text from 'antd/lib/typography/Text';
import {
  MaxLengthFields,
  validateFields,
} from '../subscription-modal-utils/validate-fields';

const DUMMY_IMAGE_UUID = '00000000-0000-0000-0000-000000000000';

const TextStyles: React.CSSProperties = {
  fontWeight: 500,
  fontSize: '16px',
  color: '#000000D',
};

const FirstPage = ({
  subscriptionModalData,
  setSubscriptionModalData,
}: {
  subscriptionModalData: SubscriptionModal;
  setSubscriptionModalData: any;
}) => {
  const loadedPlatform = loadItem(PLATFORM_CODE_KEY);
  const { auth, app } = useSelector((state: any) => state);
  const [, forceUpdate] = useState<number>(0);
  const [errors, setErrors] = useState<any>({});
  const [load, setLoad] = useState(false);

  const [firstPageData, setFirstPageData] = useState<
    Partial<SubscriptionModal>
  >({
    firstPageMediaDesktopType: subscriptionModalData.firstPageMediaDesktopType,
    firstPageMediaMobileType: subscriptionModalData.firstPageMediaMobileType,
    firstPageMediaDesktop: subscriptionModalData.firstPageMediaDesktop,
    firstPageMediaMobile: subscriptionModalData.firstPageMediaMobile,
    firstPageTitle: subscriptionModalData.firstPageTitle,
    firstPageCta: subscriptionModalData.firstPageCta,
  });

  const handleSubscriptionModalSubmit = async (e: any) => {
    setLoad(true);
    e.preventDefault();
    setErrors({});

    const fieldErrors = validateFields({
      ...subscriptionModalData,
      firstPageMediaDesktop: firstPageData.firstPageMediaDesktop,
      firstPageMediaMobile: firstPageData.firstPageMediaMobile,
      firstPageTitle: firstPageData.firstPageTitle,
      firstPageCta: firstPageData.firstPageCta,
      firstPageMediaDesktopType: firstPageData.firstPageMediaDesktopType,
      firstPageMediaMobileType: firstPageData.firstPageMediaMobileType,
    });

    if (Object.keys(fieldErrors).length > 0) {
      setLoad(false);
      setErrors(fieldErrors);
      return;
    }

    if (
      !firstPageData.firstPageMediaDesktop ||
      !firstPageData.firstPageMediaMobile
    ) {
      setLoad(false);
      return feedback({
        type: 'notification',
        method: 'warning',
        message: 'Desktop and Mobile Image are required.',
        duration: 5,
      });
    }

    if (
      firstPageData.firstPageMediaDesktopType !==
      firstPageData.firstPageMediaMobileType
    ) {
      setLoad(false);
      return feedback({
        type: 'notification',
        method: 'warning',
        message: 'Please select equal types for desktop and mobile',
        duration: 5,
      });
    }

    const res = await fetch(`${app.currentPlatform}subscription-modal`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: auth.accessToken,
        'x-isdin-platform': loadedPlatform ?? app.currentPlatformCode,
      },
      body: JSON.stringify({
        ...subscriptionModalData,
        delayTime: +subscriptionModalData.delayTime,
        ...firstPageData,
      }),
    });
    const data = await res.json();

    if (!res.ok) {
      setLoad(false);
      return feedback({
        type: 'notification',
        method: 'warning',
        message: data.message,
        duration: 5,
      });
    }
    feedback({
      type: 'notification',
      method: 'success',
      message:
        'The subscription modal has been saved correctly. It will be available in a few seconds.',
      duration: 5,
    });
    setSubscriptionModalData({
      ...subscriptionModalData,
      ...firstPageData,
    });

    setLoad(false);
  };

  const handleChangeInput = ({
    key,
    value,
  }: {
    key: string;
    value: string | ArrayBuffer | null;
  }) => {
    setFirstPageData((prev: any) => ({
      ...prev,
      [key]: value,
    }));
  };

  const handleCheckboxChange = (type: string) => {
    setFirstPageData((prev: any) => ({
      ...prev,
      firstPageMediaDesktopType: type,
      firstPageMediaMobileType: type,
      firstPageMediaDesktop: '',
      firstPageMediaMobile: '',
    }));
    setErrors({});
  };

  return (
    <Form onSubmit={handleSubscriptionModalSubmit}>
      <Row style={{ marginTop: 50 }}>
        <h2>{'*Image'}</h2>
        <p style={{ marginTop: -10 }}>Introduce an URL or upload a file.</p>
        <Row style={{ marginBottom: 25 }}>
          <FormItem colon={false}>
            <Row>
              <Col span={3} style={{ display: 'flex', gap: 10 }}>
                <Checkbox
                  key={FirstPageTypes.MEDIA}
                  style={{ borderRadius: 20 }}
                  onChange={() => handleCheckboxChange(FirstPageTypes.MEDIA)}
                  checked={
                    firstPageData.firstPageMediaDesktopType ===
                    FirstPageTypes.MEDIA
                  }
                />
                <p>
                  <b>Upload file</b>
                </p>
              </Col>
            </Row>

            {firstPageData.firstPageMediaDesktopType ===
              FirstPageTypes.MEDIA && (
              <>
                <Row>
                  <Col span={7}>
                    <Text style={TextStyles}> Add Desktop File</Text>
                    <UploadComponent
                      format={'image'}
                      token={auth.accessToken}
                      uploadActions={[UPLOAD_ACTION_IMAGE]}
                      materialType={3}
                      value={
                        firstPageData.firstPageMediaDesktop
                          ? firstPageData.firstPageMediaDesktop
                          : ''
                      }
                      module="subscription-modal"
                      primaryEntityId={
                        subscriptionModalData.id || DUMMY_IMAGE_UUID
                      }
                      handleChangeField={(value: any) => {
                        handleChangeInput({
                          key: 'firstPageMediaDesktop',
                          value: value,
                        });
                        forceUpdate(Math.random());
                      }}
                      setValue={function (
                        value?: string | null | undefined,
                      ): void {
                        handleChangeInput({
                          key: 'firstPageMediaDesktop',
                          value: value || '',
                        });
                        forceUpdate(Math.random());
                      }}>
                      <Button
                        className="uploadButton uploadButton__margin-top"
                        disabled={false}>
                        <Icon type="upload" />
                        <span>Upload</span>
                      </Button>
                    </UploadComponent>
                  </Col>
                </Row>
                <Text>
                  The image must have a size of 376x470 (aspect ratio 4:5)
                </Text>

                <Row style={{ marginTop: 20 }}>
                  <Col span={7}>
                    <Text style={TextStyles}> Add Mobile File</Text>
                    <UploadComponent
                      format={'image'}
                      token={auth.accessToken}
                      uploadActions={[UPLOAD_ACTION_IMAGE]}
                      materialType={3}
                      value={
                        firstPageData.firstPageMediaMobile
                          ? firstPageData.firstPageMediaMobile
                          : ''
                      }
                      module="subscription-modal"
                      primaryEntityId={
                        subscriptionModalData.id || DUMMY_IMAGE_UUID
                      }
                      handleChangeField={(data: any) => {
                        handleChangeInput({
                          key: 'firstPageMediaMobile',
                          value: data,
                        });
                        forceUpdate(Math.random());
                      }}
                      setValue={function (
                        value?: string | null | undefined,
                      ): void {
                        handleChangeInput({
                          key: 'firstPageMediaMobile',
                          value: value || '',
                        });
                        forceUpdate(Math.random());
                      }}>
                      <Button
                        className="uploadButton uploadButton__margin-top"
                        disabled={false}>
                        <Icon type="upload" />
                        <span>Upload</span>
                      </Button>
                    </UploadComponent>
                  </Col>
                </Row>
                <Text>
                  The image must have a size of 375x283 (aspect ratio 4:3)
                </Text>
              </>
            )}
          </FormItem>
        </Row>
        <Row>
          <Row style={{ marginTop: 20 }}>
            <Col span={3} style={{ display: 'flex', gap: 10, marginTop: -10 }}>
              <Checkbox
                key={FirstPageTypes.URL}
                style={{ borderRadius: 20 }}
                onChange={() => handleCheckboxChange(FirstPageTypes.URL)}
                checked={
                  firstPageData.firstPageMediaDesktopType === FirstPageTypes.URL
                }
              />
              <p>
                <b>Enter an url</b>
              </p>
            </Col>
          </Row>
          {firstPageData.firstPageMediaDesktopType === FirstPageTypes.URL && (
            <>
              <FormItem
                colon={false}
                validateStatus={errors.firstPageMediaDesktop ? 'error' : ''}
                help={errors.firstPageMediaDesktop}>
                <Row>
                  <Col span={6}>
                    <Input
                      placeholder="Enter an url desktop"
                      value={firstPageData.firstPageMediaDesktop}
                      onChange={(e) =>
                        handleChangeInput({
                          key: 'firstPageMediaDesktop',
                          value: e.target.value,
                        })
                      }
                    />
                  </Col>
                </Row>
              </FormItem>

              <FormItem
                colon={false}
                validateStatus={errors.firstPageMediaMobile ? 'error' : ''}
                help={errors.firstPageMediaMobile}>
                <Row style={{ marginTop: 15 }}>
                  <Col span={6}>
                    <Input
                      placeholder="Enter an url mobile"
                      value={firstPageData.firstPageMediaMobile}
                      onChange={(e) =>
                        handleChangeInput({
                          key: 'firstPageMediaMobile',
                          value: e.target.value,
                        })
                      }
                    />
                  </Col>
                </Row>
              </FormItem>
            </>
          )}
        </Row>
      </Row>
      <Row style={{ marginTop: 30 }}>
        <h2>{'*Title'}</h2>
        <Col span={6}>
          <FormItem
            colon={false}
            validateStatus={errors.firstPageTitle ? 'error' : ''}
            help={errors.firstPageTitle}>
            <p style={{ marginTop: -10 }}>
              It should contain a maximum characters of:{' '}
              {MaxLengthFields.firstPageTitle}
            </p>
            <Input
              placeholder={
                firstPageData.firstPageTitle
                  ? firstPageData.firstPageTitle.toString()
                  : 'Enter a Title text here'
              }
              value={firstPageData.firstPageTitle || ''}
              onChange={(e) =>
                handleChangeInput({
                  key: 'firstPageTitle',
                  value: e.target.value,
                })
              }
            />
          </FormItem>
        </Col>
      </Row>

      <Row style={{ marginTop: 30 }}>
        <h2>{'*CTA'}</h2>

        <Col span={6}>
          <FormItem
            colon={false}
            validateStatus={errors.firstPageCta ? 'error' : ''}
            help={errors.firstPageCta}>
            <p style={{ marginTop: -10 }}>
              It should contain a maximum characters of:{' '}
              {MaxLengthFields.firstPageCta}
            </p>
            <Input
              placeholder={
                firstPageData.firstPageCta
                  ? firstPageData.firstPageCta.toString()
                  : 'Enter a CTA text here'
              }
              value={firstPageData.firstPageCta || ''}
              onChange={(e) =>
                handleChangeInput({
                  key: 'firstPageCta',
                  value: e.target.value,
                })
              }
            />
          </FormItem>
        </Col>
      </Row>
      <Button
        loading={load}
        disabled={load}
        type="primary"
        htmlType="submit"
        className="login-form-button"
        style={{ marginTop: 50 }}>
        Save FirstPage
      </Button>
    </Form>
  );
};

export default FirstPage;
