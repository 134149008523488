/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';

import { Button, Col, Form, Icon, Input, Row, Select } from 'antd';
import FormItem from 'antd/lib/form/FormItem';

import { useSelector } from 'react-redux';
import { feedback, loadItem, PLATFORM_CODE_KEY } from '../../../utils';
import { BlackListTypes } from '../types/subscription-modal-form-common.types';
import {
  SubscriptionModal,
  SubscriptionModalBlackList,
} from '../interfaces/form-common.interfaces';
import Text from 'antd/lib/typography/Text';
import { validateBlackListFields } from '../subscription-modal-utils/validate-fields';

export const BlackListForm = ({
  subscriptionModalData,
  setSubscriptionModalData,
  type,
}: {
  subscriptionModalData: SubscriptionModal;
  setSubscriptionModalData: any;
  type: string;
}) => {
  let filterBlackListType =
    subscriptionModalData.subscriptionModalBlackList.filter(
      (item) => item.type === type,
    );
  const loadedPlatform = loadItem(PLATFORM_CODE_KEY);
  const { auth, app } = useSelector((state: any) => state);
  const [addNewBlackList, setAddNewBlackList] = useState(true);
  const [load, setLoad] = useState(false);
  const [errors, setErrors] = useState<any>({});

  const [blackListData, setBlackListData] =
    useState<SubscriptionModalBlackList>({
      id: '',
      subscriptionModalId: subscriptionModalData.id,
      type,
      value: '',
      matchType: '',
    });

  const handleChangeInput = ({
    key,
    value,
  }: {
    key: string;
    value: string;
  }) => {
    setBlackListData((prev: any) => {
      if (prev) {
        return {
          ...prev,
          [key]: value,
        };
      }
      return { [key]: value };
    });
  };

  const handleSubscriptionModalBlackListSubmit = async (e: any) => {
    setLoad(true);
    e.preventDefault();
    e.stopPropagation();
    setErrors({});

    const fieldErrors = validateBlackListFields(blackListData);
    if (Object.keys(fieldErrors).length > 0) {
      setLoad(false);
      setErrors(fieldErrors);
      return;
    }

    const { id, ...blackListDataRest } = blackListData;

    const res = await fetch(
      `${app.currentPlatform}subscription-modal-blackList`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: auth.accessToken,
          'x-isdin-platform': loadedPlatform ?? app.currentPlatformCode,
        },
        body: JSON.stringify({
          ...blackListDataRest,
          type,
          subscriptionModalId: subscriptionModalData.id,
        }),
      },
    );
    const data = await res.json();

    if (!res.ok) {
      setLoad(false);
      return feedback({
        type: 'notification',
        method: 'warning',
        message: data.message,
        duration: 5,
      });
    }

    feedback({
      type: 'notification',
      method: 'success',
      message:
        'The subscription modal Blacklist has been saved correctly. It will be available in a few seconds.',
      duration: 5,
    });

    setBlackListData({
      id: '',
      subscriptionModalId: data.subscriptionModalId,
      type,
      value: '',
      matchType: '',
    });

    filterBlackListType.push(data);

    setSubscriptionModalData((prev: any) => {
      if (prev) {
        return {
          ...prev,
          subscriptionModalBlackList:
            subscriptionModalData.subscriptionModalBlackList.concat(data),
        };
      }
      return {
        subscriptionModalBlackList:
          subscriptionModalData.subscriptionModalBlackList.concat(data),
      };
    });

    setAddNewBlackList(true);
    setLoad(false);
  };

  const handleDeleteBlackList = async (e: any, id: string) => {
    e.preventDefault();
    e.stopPropagation();

    const res = await fetch(
      `${app.currentPlatform}subscription-modal-blackList/${id}`,
      {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: auth.accessToken,
          'x-isdin-platform': app.currentPlatformCode,
        },
      },
    );

    if (!res.ok) {
      setLoad(false);
      return feedback({
        type: 'notification',
        method: 'warning',
        message: 'Error deleting the blacklist',
        duration: 5,
      });
    }

    feedback({
      type: 'notification',
      method: 'success',
      message:
        'The subscription modal Blacklist has been deleted correctly. It will be available in a few seconds.',
      duration: 5,
    });

    setBlackListData({
      id: '',
      subscriptionModalId: subscriptionModalData.id,
      type,
      value: '',
      matchType: '',
    });

    setSubscriptionModalData((prev: any) => {
      if (prev) {
        return {
          ...prev,
          subscriptionModalBlackList:
            subscriptionModalData.subscriptionModalBlackList.filter(
              (item: any) => item.id !== id,
            ),
        };
      }
    });
  };

  return (
    <Form onSubmit={handleSubscriptionModalBlackListSubmit}>
      <h3>{type.toLocaleUpperCase()}</h3>
      <div>
        {filterBlackListType.map((item) => (
          <Row key={item.id}>
            <Col
              span={4}
              style={{
                border: '1px solid #d9d9d9',
                borderRadius: 5,
                padding: 5,
                display: 'flex',
                justifyContent: 'space-between',
                marginTop: 5,
              }}>
              <Text>
                {item.matchType === 'contains' && `Contains: ${item.value}`}
                {item.matchType === 'is_exactly' && `Is exactly: ${item.value}`}
              </Text>
              <Icon
                onClick={(e) => handleDeleteBlackList(e, item.id)}
                style={{ fontSize: 16, color: 'red', marginLeft: 'auto' }}
                type="delete"
              />
            </Col>
          </Row>
        ))}
      </div>

      {!addNewBlackList && (
        <>
          <Row>
            <Col span={3}>
              <FormItem
                colon={false}
                validateStatus={errors.matchType ? 'error' : ''}
                help={errors.matchType}>
                <Select
                  placeholder="Contains"
                  onChange={(e) =>
                    handleChangeInput({
                      key: 'matchType',
                      value: e as string,
                    })
                  }>
                  <Select.Option value="contains">Contains</Select.Option>
                  <Select.Option value="is_exactly">Is exactly</Select.Option>
                </Select>
              </FormItem>
            </Col>
            <Col span={5} style={{ marginLeft: 10 }}>
              <FormItem
                colon={false}
                validateStatus={errors.value ? 'error' : ''}
                help={errors.value}>
                <Input
                  placeholder={blackListData.value ? blackListData.value : type}
                  value={blackListData?.value || ''}
                  onChange={(e) =>
                    handleChangeInput({
                      key: 'value',
                      value: e.target.value,
                    })
                  }
                />
              </FormItem>
              {type === BlackListTypes.URL && (
                <p
                  style={{
                    fontSize: 10,
                    color: 'grey',
                    textAlign: 'end',
                  }}>
                  <Icon type="info-circle" style={{ margin: 3 }} />
                  Format example of url: /shopping-bag
                </p>
              )}

              {type === BlackListTypes.UTM && (
                <p
                  style={{
                    fontSize: 10,
                    color: 'grey',
                    textAlign: 'end',
                  }}>
                  <Icon type="info-circle" style={{ margin: 3 }} />
                  <Text> Format example: utm_medium=newsletter</Text>
                </p>
              )}
            </Col>

            <Col span={5}>
              <Button
                loading={load}
                disabled={load}
                style={{
                  marginLeft: '5px',
                  height: 30,
                  marginTop: 4,
                }}
                type="primary"
                onClick={() => setAddNewBlackList(true)}>
                <Icon type="close-circle" />
              </Button>
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <Button
                loading={load}
                disabled={load}
                type="primary"
                size="small"
                onClick={(e) => {
                  handleSubscriptionModalBlackListSubmit(e);
                }}
                className="login-form-button">
                Save {type}
              </Button>
            </Col>
          </Row>
        </>
      )}

      {addNewBlackList && (
        <Row>
          <Col span={2} style={{ marginTop: 15 }}>
            <Button
              loading={load}
              disabled={load}
              type="primary"
              size="small"
              onClick={() => setAddNewBlackList(false)}
              className="login-form-button">
              Add new {type}
            </Button>
          </Col>
        </Row>
      )}
    </Form>
  );
};
